import React from 'react'
import './../../styles/Shared.scss'

const Input = React.memo(({ type, label, value, onChange }) => {
  return (
    <div className="group">
      <input
        className="input"
        type={type}
        value={value}
        onChange={event => onChange(event.target.value)}
      />
      <span className="highlight"></span>
      <span className="bar"></span>
      <label>{label}</label>
    </div>
  )
})

export default Input
