import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useCookies } from 'react-cookie'
import { setOrders, addOrder, updateOrder, replaceOrder } from './../store/actions/orders'
import CountrResources from '../utils/CountrResources'
import CountrListeners from '../utils/CountrListeners'
import StatusSectionList from '../components/Orders/StatusSectionList'
import { TOKENS, LISTENERS_EVENTS, API } from '../utils/CONSTS'
import './../styles/Main.scss'

const STATUS = ['printed', 'preparing', 'ready']

const mapStateToProps = (state) => {
  return {
    storeId: state.company.storeId,
    orders: state.orders
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrders: (orders) => dispatch(setOrders(orders)),
    addOrder: (order) => dispatch(addOrder(order)),
    updateOrder: (order) => dispatch(updateOrder(order)),
    replaceOrder: (order) => dispatch(replaceOrder(order))
  }
}

const MainPage = (props) => {
  const appHeaderHeight = document.getElementById('app-header')?.clientHeight || 150

  const listeners = useRef(new CountrListeners(props.storeId))

  const [cookies] = useCookies([TOKENS.ACCESS])

  const callbacks = {
    [LISTENERS_EVENTS.CART_UPDATED]: (data) => props.updateOrder(data),
    [LISTENERS_EVENTS.TRANSACTION_CREATED]: (data) => props.replaceOrder(data),
    [LISTENERS_EVENTS.TRANSACTION_UPDATED]: (data) => props.updateOrder(data)
  }

  useEffect(() => {
    const ws = listeners.current

    ws._initListeners(callbacks)
    getOrders()

    return () => {
      ws._removeListeners()
    }
  }, [])

  const getOrders = async () => {
    const { access_token } = cookies
    const ordersSince =
      new Date().getTime() - 1000 * 60 * (process.env.ORDER_RETRIEVE_MINUTES || 120)

    const promises = [
      CountrResources.getDailyOrders(access_token, new Date(ordersSince), API.CARTS, props.storeId),
      CountrResources.getDailyOrders(
        access_token,
        new Date(ordersSince),
        API.TRANSACTIONS,
        props.storeId
      )
    ]
    Promise.all(promises)
      .then((result) => getOrdersSuccess(result))
      .catch((error) => getOrdersError(error))
  }

  const getOrdersSuccess = (result) => {
    let orders = []
    for (const row of result) {
      orders = [...orders, ...row]
    }
    props.setOrders(orders)
  }

  const getOrdersError = (error) => {
    console.log(error)
  }

  return (
    <div className="main-page">
      {STATUS.map((status) => (
        <StatusSectionList key={status} status={status} appHeaderHeight={appHeaderHeight} />
      ))}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage)
