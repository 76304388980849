export const STATUS = {
  NEW: 'new',
  PENDING: 'pending',
  // IN_PROGRESS: 'in_progress',
  PRINTED: 'printed',
  PREPARING: 'preparing',
  READY: 'ready',
  // PICKED_UP: 'picked_up',
  PAID: 'paid',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled'
}

export const TRACKING_STATUS = {
  PENDING: 'pending',
  PRINTED: 'printed',
  PREPARING: 'preparing',
  READY: 'ready',
  COMPLETED: 'completed',
  NEW: 'new',
  CANCELLED: 'cancelled'
}

export const LISTENERS_EVENTS = {
  // CART_CREATED: 'cart.created',
  CART_UPDATED: 'cart.updated',
  TRANSACTION_CREATED: 'transaction.created',
  TRANSACTION_UPDATED: 'transaction.updated'
}

export const TOKENS = {
  ACCESS: 'access_token',
  REFRESH: 'refresh_token'
}

export const API = {
  CARTS: 'carts',
  TRANSACTIONS: 'transactions'
}

export const LOCAL_STORAGE = {
  UUID: 'CountrOrder:UUID',
  THEME: 'CountrOrder:Theme'
}

export const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
  APP_HEADER: '.app-header',
  LOGO: '.logo',
  CLOCK: '.clock span',
  PRINTED: '.order-wrapper__printed',
  PREPARING: '.order-wrapper__preparing',
  READY: '.order-wrapper__ready'
}
