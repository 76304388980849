import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Order from './Order'
import ReadyOrderAlert from './ReadyOrderAlert'
import { STATUS } from '../../utils/CONSTS'
import OrdersUtils from '../../utils/OrdersUtils'
// import NOTIFICATION from './../../assets/sounds/notification.mp3'
import './../../styles/Main.scss'

const mapStateToProps = (state) => {
  return {
    orders: state.orders
  }
}

const TIMEOUT = 10000

const StatusSectionList = React.memo(({ orders, status, appHeaderHeight }) => {
  // const audio = useRef(null)
  const [count, setCount] = useState(0)
  const [showAlert, setShowAlert] = useState(false)
  const [showOrder, setShowOrder] = useState({})

  useEffect(() => {
    if (shouldShowReadyAlert()) {
      addOrderAlert()
    }

    setCount(orders[STATUS.READY].length)
  }, [orders[STATUS.READY]])

  const shouldShowReadyAlert = () => {
    return (
      status === STATUS.READY &&
      orders[STATUS.READY].length > 0 &&
      orders[STATUS.READY].length >= count
    )
  }

  const addOrderAlert = () => {
    setShowOrder(orders[STATUS.READY][0])
    setShowAlert(true)
    setTimeout(removeOrderAlert, TIMEOUT)

    // TODO: It is having a erro that DOM was not interact before play the sound
    // audio.current.currentTime = 0
    // audio.current.play()
  }

  const removeOrderAlert = () => {
    setShowAlert(false)
    setShowOrder({})
  }

  return (
    <div
      className="status-section"
      key={status}
      style={{ height: `calc(100vh - 2em - ${appHeaderHeight}px)` }}>
      <div className="status-title">{OrdersUtils.renderStatus(status)}</div>
      {orders[status].map((order) => (
        <Order key={order._id} order={order} status={status} />
      ))}
      {showAlert && <ReadyOrderAlert order={showOrder} closeAlert={removeOrderAlert} />}
      {/* <audio ref={audio} src={NOTIFICATION}></audio> */}
    </div>
  )
})

export default connect(mapStateToProps, null)(StatusSectionList)
