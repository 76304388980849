import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import merchantReducer from './reducers/merchantReducer'
import companyReducer from './reducers/companyReducer'
import ordersReducer from './reducers/ordersReducer'
import { Logger } from './middlewares/Logger'

const rootReducer = combineReducers({
  merchant: merchantReducer,
  company: companyReducer,
  orders: ordersReducer
})

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const enhancer = composeEnhancers(applyMiddleware(Logger))

const configureStore = () => {
  return createStore(rootReducer, enhancer)
}

export default configureStore
