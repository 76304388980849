import { Countr } from './../CountrSDK'

export default class StoreUtils {
  static getStores = async () => {
    const countr = await Countr.getCountrSdk()
    return countr.stores.read({ sort: '-updated_at' }).then(
      stores => {
        return Promise.resolve(stores)
      },
      error => {
        return Promise.reject(error)
      }
    )
  }
}
