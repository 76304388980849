import React from 'react'
import './../../styles/Shared.scss'

const Button = React.memo(({ label, onClick, isLoading }) => {
  return (
    <div className="group">
      <button className="ripple" onClick={onClick}>
        {isLoading ? <div className="loader"></div> : <span>{label}</span>}
      </button>
    </div>
  )
})

export default Button
