import React from 'react'
import { motion } from 'framer-motion'
import './../../styles/SelectionList.scss'

const SelectionList = React.memo(
  ({ label, options, value, onChange, pmKey = '_id', displayLabel = 'name' }) => {
    const isChecked = option =>
      value === option[pmKey] ? (
        <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.3 }}>
          ✓
        </motion.span>
      ) : (
        <span></span>
      )

    return (
      <div className="group">
        <div className="selection-list">
          <span className="label">{label}</span>
        </div>
        <ul className="selection-list">
          {options.map(option => (
            <li key={option[pmKey]} className="item" onClick={() => onChange(option[pmKey])}>
              {option[displayLabel]}
              {isChecked(option)}
            </li>
          ))}
        </ul>
      </div>
    )
  }
)

export default SelectionList
