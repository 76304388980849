import { Countr } from './../CountrSDK'
import { LISTENERS_EVENTS } from './CONSTS'

export default class CountrListeners {
  constructor(storeId) {
    this.storeId = storeId
    Countr.getCountrSdk().then((countr) => {
      this.countr = countr
    })
  }

  _initListeners = (callbacks) => {
    Countr.getCountrSdk().then((countr) => {
      this.countr = countr
      Object.keys(LISTENERS_EVENTS).forEach((eventKey) => {
        this.countr.ws.on(`s${this.storeId}:${LISTENERS_EVENTS[eventKey]}`, async (data) => {
          const resource = LISTENERS_EVENTS[eventKey].split('.')[0]
          const result = await this.countr[resource + 's']
            .readOne(data[resource] || data)
            .catch((error) => {
              console.log(error)
            })

          if (!!result) {
            if (resource === 'cart' && !result.items.length) {
              return
            }

            callbacks[LISTENERS_EVENTS[eventKey]](result)
          }
        })
      })
    })
  }

  _removeListeners = () => {
    Object.keys(LISTENERS_EVENTS).forEach((eventKey) => {
      this.countr.ws.on(`s${this.storeId}:${LISTENERS_EVENTS[eventKey]}`)
    })
  }
}
