import React from 'react'
import LOGO_ASSET_WHITE from './../../assets/images/countr_white.png'
import LOGO_ASSET_BLACK from './../../assets/images/countr_black.png'

const Logo = React.memo(({ isBlack, selectedStore }) => {

  const showStoreLogo = () => {
    if (!!selectedStore?.store_logo?.length) {
      return selectedStore.store_logo
    }

    return isBlack ? LOGO_ASSET_BLACK : LOGO_ASSET_WHITE
  }
  
  return (
    <img 
    height={!!selectedStore ? "80%" : "100%"} 
    src={showStoreLogo()} 
    alt="Logo" 
    className="logo" />
  )
})

export default Logo
