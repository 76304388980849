import CountrClient from '@countr/sdk'

/**
 * Creating a instance for Countr SDK
 */
export const Countr = (function(window) {
  let instance

  async function createCountrSdk() {
    const {
      REACT_APP_STAGING,
      REACT_APP_PROD,
      REACT_APP_CLIENT_ID,
      REACT_APP_CLIENT_SECRET
    } = process.env
    const countrClient = new CountrClient({
      opts: {
        staging: REACT_APP_PROD !== 'true' && REACT_APP_STAGING === 'true',
        local: REACT_APP_PROD !== 'true' && REACT_APP_STAGING !== 'true',
        enableSocket: true
      }
    })

    countrClient.setClient({
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET
    })

    return countrClient
  }

  return {
    getCountrSdk: function() {
      if (!instance) {
        instance = createCountrSdk()
      }

      // Checking if is not connected, than force it to connect again
      instance.then(countr => {
        if (!countr.ws.socket.connected) {
          countr.ws.socket.connect()
        }
      })

      return instance
    }

    /**
     * Log error with Countr
     */
    // logError: async errorObj => {
    //   const countr = await instance

    //   errorObj.date = new Date().toISOString()
    //   errorObj.source = process.env.REACT_APP_ERROR_SOURCE

    //   if (errorObj.user instanceof Object && !!errorObj.user._id) {
    //     errorObj.user = errorObj.user._id
    //   }

    //   try {
    //     countr.e.create(errorObj).catch(error => {
    //       console.log(error)
    //     })
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
  }
})(window)
