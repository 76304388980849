import { SET_MERCHANT } from './../actions/actionTypes'

const initialState = {
  merchant: {},
  isAuth: false
}

const merchantReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MERCHANT:
      return {
        ...state,
        merchant: action.payload,
        isAuth: true
      }
    default:
      return state
  }
}

export default merchantReducer
