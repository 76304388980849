import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import { Countr } from '../CountrSDK'
import { setMerchant } from '../store/actions/merchant'
import { setDeviceUUID, setStoreDevice } from '../store/actions/company'
import { motion } from 'framer-motion'
import DeviceUtils from './../utils/DeviceUtils'
import Logo from '../components/Shared/Logo'
import Input from '../components/Shared/Input'
import Button from '../components/Shared/Button'
import { TOKENS } from '../utils/CONSTS'
import './../styles/Login.scss'

const mapDispatchToProps = (dispatch) => {
  return {
    setMerchant: (merchant) => dispatch(setMerchant(merchant)),
    setDeviceUUID: (uuid) => dispatch(setDeviceUUID(uuid)),
    setStoreDevice: (device) => dispatch(setStoreDevice(device))
  }
}

const LoginPage = ({ setMerchant, setDeviceUUID, setStoreDevice }) => {
  const navigate = useNavigate()
  const [cookies, setCookie] = useCookies([TOKENS.ACCESS, TOKENS.REFRESH])
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const loginSuccess = async (merchant) => {
    setMerchant(merchant)

    const uuid = await DeviceUtils.generateUUID()
    setDeviceUUID(uuid)
    const device = await DeviceUtils.getDevice(uuid).catch((error) => {
      setIsLoading(false)
      navigate('/registration')
    })

    if (!!device) {
      setStoreDevice(device)
      setIsLoading(false)
      navigate('/main')
    }
  }

  const loginError = (error) => {
    setIsLoading(false)
  }

  const calculateTokenMaxAge = (token) =>
    (new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 1 - new Date().getTime()) / 1000

  const login = async () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    const countr = await Countr.getCountrSdk()
    countr.once('registered', (token) => {
      countr.setToken({
        access_token: token.access_token,
        refresh_token: token.refresh_token
      })
      // Setting tokens with 1 week of maxAge
      setCookie(TOKENS.ACCESS, token.access_token, {
        path: '/',
        maxAge: calculateTokenMaxAge(token)
      })
      setCookie(TOKENS.REFRESH, token.refresh_token, {
        path: '/',
        maxAge: calculateTokenMaxAge(token)
      })
    })

    countr.on('refreshed', async (token) => {
      // SET TOKENS COOKIES
      setCookie(TOKENS.ACCESS, token.access_token, {
        path: '/',
        maxAge: calculateTokenMaxAge(token)
      })
      setCookie(TOKENS.REFRESH, token.refresh_token, {
        path: '/',
        maxAge: calculateTokenMaxAge(token)
      })
    })

    countr.register(username, password).then(
      (merchant) => loginSuccess(merchant),
      (error) => loginError(error)
    )
  }

  const autoLogin = async () => {
    if (isLoading) {
      return
    }

    const { access_token, refresh_token } = cookies

    if (!!access_token && !!refresh_token) {
      setIsLoading(true)
      const countr = await Countr.getCountrSdk()

      countr.on('refreshed', async (token) => {
        //SET TOKENS COOKIES
        setCookie(TOKENS.ACCESS, token.access_token, {
          path: '/',
          maxAge: calculateTokenMaxAge(token)
        })
        setCookie(TOKENS.REFRESH, token.refresh_token, {
          path: '/',
          maxAge: calculateTokenMaxAge(token)
        })
      })

      countr.setToken({
        access_token: access_token,
        refresh_token: refresh_token
      })
      countr.me.read().then(
        (merchant) => loginSuccess(merchant),
        (error) => loginError(error)
      )
    }
  }

  useEffect(() => {
    autoLogin()
  }, [])

  return (
    <div className="login-page">
      <motion.div
        className="card"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}>
        <Logo />
        <Input type="text" label="Username" value={username} onChange={setUsername} />
        <Input type="password" label="Password" value={password} onChange={setPassword} />
        <Button label="Login" onClick={login} isLoading={isLoading} />
      </motion.div>
    </div>
  )
}

export default connect(null, mapDispatchToProps)(LoginPage)
