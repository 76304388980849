/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
const noLang = {
  cart: 'Kasse',
  item: 'Vare',
  items: 'Varer',
  subtotal: 'Delsum',
  discount: 'Rabatt',
  total: 'Total',
  pay: 'Betal',
  card: 'Kort',
  cash: 'Kontant',
  invoice: 'Faktura',
  giftcard: 'Gavekort',
  seqr: 'SEQR',
  bitpay: 'Bitpay',
  payment_method: 'Betalingsmåte',
  cash_received: 'Mottatt beløp',
  or_enter_amount: 'Eller angi beløp',
  payment_succeeded: 'Betaling mottatt',
  change_due: 'Veksel',
  total_paid: 'Totalt betalt',
  send_receipt: 'Send kvittering',
  send_invoice: 'Send faktura',
  send: 'Send',
  delete: 'Slett',
  new_sale: 'Nytt salg',
  custom_price: 'Eget beløp',
  item_description: '(Valgfri) produktbeskrivelse / navn',
  products: 'Produkter',
  transactions: 'Transaksjoner',
  reports: 'Rapporter',
  report: 'Rapport',
  settings: 'Innstillinger',
  sign_out: 'Logg ut',
  receipt: 'Kvittering',
  refund: 'Tilbakebetaling',
  home: 'Hjem',
  clear: 'Slett',
  add: 'Legg til',
  sign_up: 'Meld på',
  sign_up_email: 'Meld på med e-post',
  sign_in: 'Logg inn',
  company_name: 'Firmanavn',
  merchant_name: 'Kontaktperson',
  email_address: 'E-postadresse',
  phone_number: 'Gyldig telefonnummer',
  password: 'Passord',
  confirm_password: 'Bekreft passord',
  forgot_password_question: 'Glemt passord?',
  next: 'Neste',
  demo: 'Demo',
  skip: 'Hopp over',
  skip_registration: 'Hopp over registrering',
  take_a_tour: 'Ta en guidet tur',
  items_returned: 'Varer tilbakelevert',
  wrong_billing: 'Feil betaling',
  order_cancelled: 'Ordre kansellert',
  other: 'Annet',
  enter_valid_amount: 'Skriv et gyldig beløp',
  invalid_merchant_name: 'Ugyldig kontaktperson',
  invalid_business_name: 'Ugyldig firmanavn',
  invalid_email_address: 'Ugyldig e-postadresse',
  invalid_phone_number: 'Ugyldig telefonnummer',
  invalid_password: 'Ugyldig passord - må være minst 8 tegn',
  password_mismatch: 'Passordet er ikke likt skrevet',
  coming_soon: 'Kommer snart...',
  to_use_signup: 'Registrer deg for å få tilgang til denne funksjonen',
  login_error: 'Feil ved innlogging - sjekk internettilgang og brukerdetaljer',
  signup_error: 'Feil ved registrering',
  transaction_history_empty: 'Transaksjonshistorikken er tom',
  categories: 'Kategorier',
  all_products: 'Alle produkter',
  search_product: 'Søk produkt',
  reissue_receipt: 'Skriv ut kvittering på nytt',
  print_receipt: 'Skriv ut kvittering',
  or_send_receipt: 'Eller send kvittering',
  save: 'Lagre',
  saved: 'Lagret',
  search: 'Søk',
  custom_item: 'Egendefinert vare',
  submit: 'Send',
  submitted: 'Sendt',
  note: 'Notat',
  message: 'Beskjed',
  amount: 'Beløp',
  number: 'Nummer',
  taxes: 'MVA',
  tax: 'MVA',
  enter_valid_number: 'Skriv et gyldig beløp',
  enter_valid_text: 'Skriv en gyldig tekst',
  enter_valid_phone: 'Skriv et gyldig telefonnummer',
  enter_valid_email: 'Skriv en gyldig e-postadresse',
  feature_not_in_demo: 'Denne funksjonen er ikke tilgjengelig i demonstrasjonsmodus',
  provide_refund_reason: 'Du må velge en gyldig årsak',
  provide_valid_refund_amount: 'Du må velge en gyldig årsak for tilbakebetaling',
  refund_exceed_refundable: 'Tilbakebetalingsbeløp er større en maksimalt beløp for denne kvittering',
  refund_saved: 'Tilbakebetaling lagret',
  or_customize_discount: 'Eller egendefinert rabatt',
  enter_percentage: 'Skriv prosent',
  manual_card_payment: 'Manuell kortbetaling',
  did_card_payment_succeed: 'Ble kortbetalingen gjennomført?',
  manual_card_refund: 'Manuell tilbakebetaling til kort',
  did_card_refund_succeed: 'Ble den manuelle tilbakebetalingen til kort gjennomført?',
  giftcard_refund: 'Tilbakebetaling gavekort',
  did_giftcard_refund_succeed: 'Nytt gavekort til kunde?',
  yes: 'Ja',
  no: 'Nei',
  general: 'Generelt',
  printer: 'Skriver',
  barcode_scanner: 'Strekkodeleser',
  payment: 'Betaling',
  profile: 'Profil',
  currency: 'Valuta',
  printing_receipt: 'Skriver ut kvittering...',
  enter_amount: 'Angi beløp',
  expected_change: 'Forventet veksel',
  still_remaining: 'Gjenstående',
  issue_invoice: 'Lag faktura',
  URL_pricing: 'http://www.countrhq.com/#prising',
  address: 'Adresse',
  address_continued: 'Adresse [valgfritt]',
  city: 'Sted',
  zip: 'Postnr',
  country: 'Land',
  country_iso: 'Land i ISO 3166-2-format (f.eks. "Nl", "de", "gb")',
  invalid_address: 'Ugyldig adresse',
  invalid_city: 'Ugyldig sted',
  invalid_country: 'Ugyldig land',
  invalid_ecommerce: 'Ingen e-handelsplattform valgt',
  signup_error_contact_support: 'Feil ved registrering, vennligst kontakt support@countrhq.com',
  open_orders: 'Åpne ordre',
  order: 'Ordre',
  enter_weight_g: 'Skriv vekt',
  search_scanner: 'Søk etter scanner Socket CHS',
  order_settings: 'Ordrevalg',
  order_amount: 'Antall åpne ordre tilgjengelig',
  receipts: 'Kvitteringer',
  print_receipts: 'Skriv ut kvittering automatisk',
  open_cash_drawer: 'Åpne kassen automatisk',
  backup: 'Backup',
  email_backup: 'Backup på e-post',
  server_sync: 'Synkronisere mot server',
  external_payment: 'Annet',
  search_printer: 'Søk etter skriver',
  sms_successfully_sent: 'SMS sendt',
  error_sending_sms: 'Feil ved sending av SMS, prøv igjen',
  email_successfully_sent: 'E-post sendt',
  error_sending_email: 'Feil ved sending av e-post, prøv igjen',
  transaction: 'Transaksjon',
  logging_in: 'Logger inn...',
  downloading_products: 'Laster produkter...',
  add_to_cart: 'Legg i handlekurv',
  invalid_price: 'Ugyldig pris',
  invalid_discount: 'Ugyldig rabatt',
  invalid_new_price: 'Ugyldig ny pris - må være lavere enn opprinnelig pris',
  invalid_amount: 'Ugyldig beløp',
  edit_price: 'Endre pris',
  discount_perc: 'Rabatt (%)',
  quantity: 'Antall',
  remove: 'Slett',
  sales_info: 'Salginformasjon',
  summary: 'Total',
  refunded_information: 'Informasjon om tilbakebetaling',
  total_sale: 'Totalt',
  total_amount_refunded: 'Totalt beløp tilbakebetalt',
  remaining: 'Gjenstående',
  sold_by: 'Solgt av',
  source_receipt_number: 'Kilde kvitteringsnr.',
  issued_date: 'Dato utferdet',
  issued_time: 'Tid utferdet',
  refunded_by: 'Tilbakebetalt av',
  reason: 'Årsak',
  receipt_number: 'Kvitteringsnummer',
  refunded_date: 'Dato tilbakebetalt',
  refunded_time: 'Tid tilbakebetalt',
  first_name: 'Fornavn',
  last_name: 'Etternavn',
  shop_category: 'Butikkategori',
  choose_country: 'Velg land…',
  you_will_receive_email: 'Gratulerer, din registrering er fullført',
  wait_email: 'Du kommer nå motta en e-post med videre informasjon om hvordan du kan koble din e-handelplattform til Countr POS.',
  open_dashboard: 'Du kan nu administrere ditt konto og legge til produkter i ditt personlige Countr-dashbord',
  by_signing_up: 'Ved å registrere meg aksepterer jeg',
  terms_and_conditions: 'vilkår',
  and: 'og',
  privacy_policy: 'personvernregler',
  refund_items: 'Tilbakebetal varer',
  forgot_password: 'Glemt passord?',
  register_device: 'Registrer enhet',
  device_not_registered: 'Denne enheten er ikke registrert',
  want_to_add_to_account: 'Registrer den nå?',
  if_ios_previously_registered: 'Hvis du nylig har installert Countr på nytt, må du registrere din iPad/tablet på nytt.',
  new_device_info: 'Du vil bli bedt om å lenke din iPad/tablet til en av dine butikker på neste side',
  not_now: 'Ikke nå',
  proceed: 'Fortsett',
  more_info_click: 'Mer info? Klikk her',
  your_detail: 'Dine opplysninger',
  vat_no: 'MVA-nummer',
  billing_details: 'Betalingsdetaljer',
  add_store: 'Legg til butikk',
  or_add_to_existing_store: 'Eller legg til eksisterende butikk',
  add_new_store: 'Legg til ny butikk',
  store_information: 'Butikksinformasjon',
  store_name: 'Butikksnavn',
  go_to_web_dashboard: 'Gå til dashbord',
  error_create_account: 'Feil ved kontoregistrering',
  error_creation_error: 'Et feil oppstå ved registrering',
  error_wrong_phone_number: 'Vennligt oppgi et gyldig telefonnummer',
  error_try_again: 'Tilkoblingsfeil. Vennligst prøv igjen.',
  error_from_the_server: 'Kunne ikke koble til server.',
  error_update_cc_info: 'Kan ikke oppdatere kortinformasjon',
  error_add_device: 'Kan ikke legge til enhet til nåværende butikk',
  error_add_store: 'Kan ikke legge til ny butikk',
  are_not_valid: 'er ikke gyldig',
  is_not_valid: 'er ikke gyldig',
  are_empty: 'er tom',
  is_empty: 'er tom',
  are_not_selected: 'er ikke valgt',
  is_not_selected: 'er ikke valgt',
  bill: 'Regning',
  URL_dashboard: 'https://dashbord.countrhq.com/',
  URL_support: 'mailto:support@countrhq.com',
  URL_forgot_password: 'https://dashbord.countrhq.com/glemt-passord',
  close: 'Lukk',
  no_product_found: 'Ingen produkter funnet',
  please_go_to_dashboard: 'For å legge til produkter, besøk https://dashbord.countrhq.com',
  select_interface: 'Velg grensesnitt',
  refund_all: 'Tilbakebetal alle',
  purchased_items: 'Kjøpte varer',
  refundable: 'Tilbakebetalbar',
  to_be_refunded: 'Å tilbakebetale',
  out_of: 'Tomt for',
  enter_a_printer_name: 'Angi skrivernavn',
  cart_is_empty: 'Tomt for papir',
  searching_printers: 'Søker skriver…',
  no_printers_found: 'Ingen skrivere funnet. Om du søker etter en bluetooth-skriver, vennligst se til bluetooth er aktivert',
  total_due: 'Totalt gjenstående',
  payment_not_successful: 'Betaling mislykket.',
  error_processing_payment: 'Feil ved betaling.',
  purchase: 'Kjøp',
  transaction_aborted: 'Transaksjon avbrutt',
  authorization_error: 'Feil ved autorisasjon',
  authorization_ok: 'Autorisert',
  transaction_cancelled: 'Transaksjon avbrutt',
  clearance_error: 'Klareringsfeil av bruker',
  clearance_ok: 'Klarering godkjent av bruker',
  transaction_declined: 'Transaksjon benektet',
  transaction_complete: 'Transaksjon vellykket',
  initiating: 'Initierer',
  application_selected: 'Venter på betalingsterminal',
  scheduled_for_clearance: 'Scheduled for off-line clearance by processor',
  terminal_error: 'Terminalfeil',
  unknown_status: 'Ukjent status',
  waiting_for_clearance: 'Waiting for off-line clearance confirmation by processor',
  amount_exceeded: 'Maksimalt beløp overskredet',
  authorization_timeout: 'Authorisation timeout',
  batch_processing_failed: 'Batch processing failed',
  cancelled: 'Kansellert',
  card_blocked: 'Kort blokkert',
  card_invalid: 'Ugyldig kort',
  card_declined: 'Kort benektet',
  transaction_failed: 'Transaksjonen feilet',
  insufficient_funds: 'Ikke nok penger',
  terminal_not_paired: 'Terminalen er ikke tilkoblet - prøv å starte den på nytt',
  pin_incorrect_last_retry: 'Feil PIN-kode - siste forsøk',
  pin_incorrect_retry: 'Feil PIN-kode - prøv igjen',
  printer_busy: 'Skriver opptatt',
  printer_error: 'Skriverfeil',
  printer_not_present: 'Skriver ikke tilkoplet',
  printer_out_of_paper: 'Skriveren er tom for papir',
  success: 'Success',
  system_error: 'Systemfeil',
  transaction_not_found: 'Transaksjon ikke funnet',
  unknown_device: 'Ukjent enhet',
  apply_discount: 'Legg til rabatt',
  seqr_id: 'SEQR Merchant ID',
  seqr_password: 'SEQR Merchant løsenord',
  bitpay_api_token: 'Bitpay API Token',
  registration_successful: 'Terminalen er registrert',
  unregistration_successful: 'Terminalen er avregistrert',
  payment_init_not_successful: 'Betaling ikke initiert - sjekk nettverkstilkobling',
  always_show_addons: 'Alltid vis tillegg',
  show_product_image: 'Vis produktbilde',
  settings_connected_printers: 'Skrivere tilkoblet',
  settings_add_new_printer: 'Legg til skriver',
  wifi_lan: 'WiFi eller LAN',
  bluetooth: 'Bluetooth',
  usb: 'USB',
  paired_barcode_scanners: 'Strekkodelesere parkoblet',
  account: 'Konto',
  refresh_products: 'Oppdater produkter',
  server_synchronisation: 'Serversynkronisering',
  sales: 'Salg',
  today: 'Idag',
  refunds: 'Tilbakebetalinger',
  product: 'Produkter',
  product_by_category: 'Produkter efter kategori',
  see_categories_details: 'Se kategoridetaljer',
  open_orders_finish_payment_or_empty: 'Det er åpne ordre. Gjennomfør betaling eller tøm kassen(e).',
  name: 'Navn',
  loading_products: 'Laster produkter',
  you_should_select_at_least_one_item: 'Du må velge minst en vare',
  select_card_provider: 'Velg en kortleverandør',
  user_cancelled: 'Betaling kansellert av kunde',
  card_payment: 'Betaling med kort',
  manage_products: 'Administrer produkter',
  edit_mode: 'Endringsmodus',
  lock: 'lock',
  loyalty: 'Lojalitet',
  account_card: 'Konto kort',
  account_lookup: 'Kontosjekk',
  Activate: 'Aktiver',
  add_anonymous_customer: 'Legg til anonym kunde',
  add_customer: 'Legg til kunde',
  add_customer_to_cart: 'Velg kunde',
  add_new_account: 'Legg til ny konto',
  add_new_customer: 'Legg til ny kunde',
  add_note_hint: 'Legg til notat',
  add_tax_to_price: 'Priser ekskl. MVA',
  add_tip: 'Legg til drikkepenger',
  add_to_store: 'Legg til butikk',
  addons: 'Tillegg',
  admin_pin: 'Admin PIN',
  admin_pin_saved: 'Ny admin PIN lagret',
  admin_pin_should_be: 'Admin PIN-kode bør være et nummer mellom 4 og 6 sifre.',
  aid: 'AID',
  aidCard: 'aidCard',
  allow_cart_discounts: 'Tillat handlekurvrabatt',
  allow_custom_cart_discounts: 'Tillat handlekurvrabatt',
  allow_custom_items: 'Tillat egendefinierte varer',
  allow_product_discounts: 'Tillat varerabatt',
  allow_custom_product_discounts: 'Tillat varerabatt',
  amount_hint: '999',
  app_version: 'Countr versjon',
  applicationLabel: 'applicationLabel',
  applicationName: 'applicationName',
  applicationPreferredName: 'applicationPreferredName',
  appName: 'appName',
  ask_tips: 'Aktiver drikkepenger',
  authorizationCode: 'authorizationCode',
  available_points: 'Tilgjengelige poeng',
  available_rewards: 'Tilgjengelige bonuser',
  back: 'Tilbake',
  barcode_scanner_disconnected: 'Din strekkodeleser er tilkoblet men kan være slått av. Prøv å slå den på igjen.',
  barcode_scanner_not_found: 'Ingen strekkodeleser funnet',
  barcode_scanner_not_found_desc: 'Koble til en ny strekkodeleser - slå på bluetooth i din enhets innstillinger og søk etter en strekkodeleser nedenfor',
  bunq: 'bunq',
  bunq_admin_approval: 'En Bunq-administrator vil se over og godkjenne din kasse før den kan brukes.',
  bunq_instructions: '1) Åpne Bunq-appen med og angi PIN\n2) Åpne venstre meny og velg \"Skann QR-kode\" / \"QR\"\n3) Skann koden på skjermen\n4) Legg til tips hvis ønsket\n5) Bekreft betaling - kryss av i øvre høyre hjørne',
  can_only_refund_entire_amount: 'Kan ikke tilbakebetale fullt beløp via {{payment_provider}}',
  cancel: 'Avbryt',
  cancel_payment: 'Kanseller betaling',
  cannot_pay_later_more_than_remaining_value: 'Du kan ikke betale mer en det gjenstående beløpet ({{amount}}) med metoden betal senere.',
  cannot_refund_transaction_with_partial_postponed_payments: 'Beklager, denne transaksjonen inneholder en eller flere utestående betalinger. Betal transaksjonen først eller tilbakebetal alt.',
  cant_find_products: 'Kan du ikke finne alle dine produkter i appen? Bruk søk-feltet eller en strekkodeleser for å finne dem.',
  card_scheme: 'Kortskjema',
  cardBrand: 'cardBrand',
  cardExpiryMonth: 'Utløpsdato Måned MM',
  cardExpiryYear: 'Utløpsdato År ÅÅ',
  cardType: 'cardType',
  cart_is_frozen_finish_payment: 'Handlekurven er låst. Gjennomfør betalingen først. ',
  cash_difference: 'Differanse',
  cash_drawer: 'Kontantkasse',
  cash_in: 'Kontanter inn',
  cash_in_out: 'Kontanter inn/ut',
  cash_out: 'Kontanter ut',
  change: 'Veksel',
  change_currency: 'Endre valuta',
  change_given: 'Veksel gitt',
  change_open_orders_amount: 'Endre åpne ordrebeløp',
  check_location_settings: 'Sjekk plassinnstillinger',
  check_network: 'Sjekk nettverkstilkobling',
  choose_category: 'Velg en kategori...',
  choose_mobile_provider: 'Flere tilbydere tilgjengelig - vennligst velg en',
  clear_settings: 'Nullstill innstillinger',
  clear_settings_successful: 'Nullstilling av innstillinger vellykket',
  clear_settings_warning: 'Nullstilling av innstillinger vil slette din tilkoblingsinformasjon. Klikk bekreft for å fortsette.',
  client_ticket: 'clientTicket',
  close_shift: 'Lukk kassen',
  company_address: 'Foretaksadresse',
  company_registration_no: 'Foretaksnummer',
  completed_payments: 'Gjennomførte betalinger',
  confirm: 'Bekreft',
  confirm_cash_payment: 'Bekreft betalingsbeløp',
  confirm_new_currency: 'Bekreft endring av valuta',
  confirm_open_orders_amount_change: 'Bekreft endring av åpne ordrebeløp',
  confirm_payment: 'Bekreft betaling',
  confirm_with_change: 'Bekreft med endring',
  confirm_with_tip: 'Bekreft med drikkepenger',
  connect_barcode: 'Koble til strekkodeleser (Socket CHS)',
  connect_to_webshop: 'Vil du koble til din nettbutikk?',
  connected_printer: 'Tilkoblede skrivere',
  contact_information: 'Kontaktinformasjon',
  contact_us: 'support@countrhq.com',
  create_product: 'Lag produkt',
  currently_trial_mode_transaction_wiped_visit_dashboard: 'POS er i testmodus. Dine transaksjoner vil bli slettet hvert fjerde time.',
  currently_trial_mode_visit_dashboard: 'POS er i testmodus. Du kan bytte til live-modus ved å besøke ditt dashbord.',
  customer: 'Kunde',
  customer_card: 'Kunde kort',
  customer_info: 'Kundeinfo',
  customer_instructions: 'Instruksjoner til kunde',
  customer_lookup: 'Kundesjekk',
  customer_lookup_error: 'Serverfeil ved kundehenting',
  customer_name: 'Kunde',
  customer_not_valid: 'Kunde ikke gyldig',
  customer_receipt: 'Kundekvittering',
  customer_since: 'Kunde siden',
  Date: 'Dato',
  deregister_terminal: 'Avregistrer enhet',
  description_mandatory: 'En beskrivelse er påkrevd for kontanter inn og ut transaksjoner',
  device_locked: 'Enhet låst',
  device_locked_enter_pin: 'Denne enheten er låst. Vennligst angi din PIN-kode for å låse opp.',
  did_cheque_refund_succeed: 'Sjekk levert tilbake til kunde?',
  did_mobile_payment_succeed: 'Ble mobilbetalingen gjennomført?',
  did_mobile_refund_succeed: 'Ble den manuelle tilbakebetalingen til mobilbetaling gjennomført?',
  direct_connection: 'Directly connected',
  disabled_product: 'Produkten er ikke tilgengelig',
  discount_currency: 'Rabatt ({{currency}})',
  do_you_want_remove_payment: 'Vil du slette betalingen?',
  download_adyen_app: 'Last ned Adyen App',
  download_izettle_app: 'Last ned iZettle App',
  download_payleven_app: 'Last ned Payleven App',
  download_sumup_app: 'Last ned Sumup App',
  download_yodo_app: 'Last ned Yodo Rocket-app',
  downloading_categories: 'Laster kategorier...',
  downloading_taxes: 'Laster avgifter',
  downloading_transactions: 'Laster transaksjoner…',
  ecommerce_none: 'Jeg lager produkter selv',
  ecrId: 'ecrid',
  eft_transaction_code: 'EFTTxnID',
  email_invoice: 'E-postfaktura',
  employee_name: 'Ansatt',
  employee_pin: 'Ansatt PIN',
  empty_cart: 'Tøm kassen',
  enable_staff_pin: 'Aktiver ansatt PIN-innlogging',
  enter_a_valid_search_value: 'Angi et gyldig søkord',
  enter_api_key: 'Angi din API-nøkkel',
  enter_base_url: 'Angi URL (inkl. "http://")',
  enter_merchant_auth_code_payconiq: 'Skriv din Payconiq Authorization header',
  enter_merchant_iban_payconiq: 'Skriv din Payconiq-registered IBAN',
  enter_merchant_id_payconiq: 'Skriv din Payconiq Merchant ID',
  enter_order_name: 'Angi ordrenavn',
  enter_reseller_id: 'Angi din forhandler-ID',
  enter_user_number: 'Angi ditt brukernummer',
  enter_user_password: 'Angi ditt passord',
  error_adding_device: 'Et feil oppstå ved enhetsregistreringen',
  error_refreshing_merchant: 'Feil ved henting av kontodetaljer.',
  error_retrieve_categories: 'Et feil oppstå ved henting av kategorier.\nVennligst sjekk din nettverkstilkobling og prøv igjen',
  error_retrieve_products: 'Et feil oppstå ved henting av produkter.\nVennligst sjekk din nettverkstilkobling og prøv igjen',
  error_retrieve_taxes: 'Et feil oppstå ved henting av avgifter.\nVennligst sjekk din nettverkstilkobling og prøv igjen',
  error_while_retrieving_results: 'Et feil oppstå ved henting av resultater',
  errorCode: 'errorCode',
  errorMessage: 'errorMessage',
  ersReference: 'ersReference',
  excess_amount: 'Overskytende beløp',
  expected_amount: 'Forventet beløp',
  expected_change_gratuity: 'Forventet endring eller drikkepenger',
  external_url: 'Ekstern URL',
  failed_cancel: 'Kansellering av transaksjon feilet',
  feature_only_android: 'Denne tjenesten er kun tilgjengelig for Android',
  find_terminal: 'Søk terminaler',
  for_security_enter_password: 'Av sikkerhetsgrunner, vennligen skriv ditt passord en gang til',
  for_this_device: 'Kun for denne enheten',
  for_this_store: 'Kun for denne butikken',
  four_inch: '10 cm',
  generate_keys: 'Generer nøkler',
  generating_keypair: 'Genererer nøkkelpar...\nDette kan ta flere minutter',
  generating_reports: 'Genererer rapporter...',
  generic_usb: 'Generisk USB',
  get_your_free_pos: 'Få din digitale kasse på www.countrhq.com',
  gift_code: 'Gavekode',
  gratuity_amount: 'Tipsbeløp',
  gratuity_percentage: 'Tipsprosent',
  group_items_in_cart: 'Grupper identiske produkter i handlekurven',
  shared_secret: 'SharedSecret',
  impossible_refresh_you_have_transactions: 'Kunne ikke oppdatere.\nDu har {{transaction_number}} transaksjon(er) som ikke er synket mot servern.\nSe til at du har en nettverkstilkobling, vent til synkronisering er gjennomført og prøv igjen om en liten stund.',
  impossible_signout_you_have_transactions: 'Kunne ikke logge ut.\nDu har {{transaction_number}} transaksjon(er) som ikke er synket mot servern.\nSe til at du har en nettverkstilkobling, vent til synkronisering er gjennomført og prøv igjen om en liten stund.',
  insert_printer_name: 'Vennligst angi et navn på din skriver (minst 3 tegn).',
  inserted_login_not_valid: 'Feil ved innlogging',
  inserted_value_not_valid: 'Ugyldig verdi',
  installmentAmount: 'installmentAmount',
  invalid_credit_card_number: 'Kortnummer må inneholde 16 sifre.',
  invoiceReference: 'invoiceReference',
  invoiceStatus: 'invoiceStatus',
  ip_address: 'IP-adresse',
  is_business: 'Firma?',
  is_correct_amount_tendered: 'Er {{amount}} korrekt beløp?',
  isDone: 'Ikke mer å laste...',
  isError: 'Trykk for å prøve igjen...',
  isTap: 'Trykk for å laste mer...',
  join_thousands_of_merchants: 'Tusentals butikker bruker Countr. Idag er det din tur. Registrer deg gratis.',
  language: 'Språk',
  last_visit: 'Siste besøk',
  leave_tour: 'Avslutt guidet tur',
  leaving_tour: 'Forlater guidet tur',
  loyal_customer: 'Lojal kunde',
  mac_address: 'MAC-adresse',
  mailing_list_permission: 'Legg til som mottaker av nyhetsbrev',
  manual_mobile_payment: 'Mobilbetaling',
  manual_mobile_refund: 'Tilbakebetaling av mobilbetaling',
  max_amount_exceeded: 'Beløp overstiger maksimalt beløp tillatt for denne betalingsmetoden',
  menu_item_12char: 'Navn på menypunkt kan ikke være lenger enn 12 tegn',
  menu_item_name: 'Navn på menypunkt (maks. 12 tegn)',
  merchant_receipt: 'Handlerkvittering',
  merchantDisplayName: 'merchantDisplayName',
  merchantId: 'merchantID',
  mid: 'MID',
  mobile_number: 'Mobilnummer',
  mobile_payment: 'Mobil',
  mobile_payments: 'Mobilbetalinger',
  new_price: 'Ny pris ({{currency}})',
  no_available_addons_for_product: 'Ingen tilgjengelige tillegg for valgt produkt',
  no_connected_printer: 'Ingen skriver(e) tilkoblet',
  no_connected_terminal: 'Ingen terminal(er) tilkoblet',
  no_customer_found: 'Ingen kunde funnet',
  no_customer_selected: 'Ingen valgt',
  no_printer_configured: 'Ingen skriver konfigurert',
  no_printer_found: 'Ingen skrivere funnet\nKlikk her om har en USB-tilkoblet Star-skriver',
  no_product_in_cart: 'Ingen vare i handlekurven',
  no_terminals_found: 'Ingen terminaler funnet',
  no_url_specified: 'Feil: ingen URL spesifisert',
  none: 'Ingen',
  note_mandatory_cash_difference: 'Et notat er påkrevd hvis det er en differanse mellom forventede og aktuelle beløper',
  note_optional: 'Notat (valgfritt)',
  numberOfInstallments: 'numberOfInstallments',
  numpad: 'Numpad',
  offline: 'Offline',
  ok: 'OK',
  one_or_more_carts_are_frozen_finish_payment: 'En eller flere handlekurver er låst. Gjennomfør betalingen først.',
  online: 'Online',
  open_market: 'Vil du åpne markedet?',
  open_register_before_transaction: 'Du må åpne kassen før du kan gjennomføre transaksjoner',
  open_shift: 'Åpne kassen',
  order_printer: 'Ordreskriver',
  orderId: 'orderID',
  orderRef: 'orderRef',
  other_text: 'Annen tekst',
  paid: 'Betalt',
  paid_so_far: 'Betalt hittil',
  paid_with_method: 'Betalt med {{method}}',
  pan: 'PAN',
  panLastDigits: 'Pan siste sifre',
  panMasked: 'Pan maskert',
  PANSeq: 'PANSeq',
  partial_payment: 'Delbetaling',
  partial_payment_not_allowed_for: 'Delbetaling er ikke tillatt for',
  partial_payment_paid_successfully: 'Delbetaling vellykket',
  pay_later: 'Betal senere',
  pay_later_allows_only_refund_all: 'Med betal senere kan du bare tilbakebetale alt. For deltilbakebetaling vennligst fullfør betalingen.',
  pay_later_refund: 'Avbryt betal senere',
  pay_on_account: 'Betal ved konto',
  payconiq: 'Payconiq',
  paying_now: 'Betaler nå',
  payment_cancelled: 'Betaling avbrutt',
  payment_error: 'Feil ved betaling',
  payment_info: 'Betalingsinfo',
  payment_insert_card: 'Sett inn kort',
  payment_method_not_configured: '{{payment_provider}} ikke korrekt konfigurert på denne enhet',
  payment_number: 'Betaling nummer',
  payment_processor_options: 'Payment processor options',
  payment_provider: 'Betalingsleverandør',
  payment_success: 'Betaling vellykket',
  payment_terms_in_net_days: 'Betalingsfrist (netto dager)',
  personal_information: 'Personopplysninger',
  pickup_rewards: 'Hente bonus',
  pin: 'PIN',
  please_insert_a_valid_amount: 'Vennligst angi et gyldig beløp',
  posEntryMode: 'posEntryMode',
  prefName: 'prefName',
  print: 'Skriv ut',
  print_also_product_details: 'Vil du skrive ut produktdetaljer også?',
  print_error: 'Skriverfeil',
  print_product_details: 'Skriv ut produktdetaljer',
  print_successful: 'Utskrift vellykket',
  printer_connect_desc: 'WiFi-skrivere må være tilkoblet til samme nettverk som denne enheten. \nBluetooth-skrivere må være parkoblet via innstillinger for å være tilgjengelig her.',
  printer_removed: 'Skriver fjernet',
  printer_test: 'Skrivertest',
  printing_report: 'Genererer og skriver ut rapport',
  proceed_with_next_payment: 'Fortsett med neste betaling.',
  processorName: 'processorName',
  product_is_disable_continue_anyway: 'Produkten du har valgt er ikke tilgjengelig (muligens tomt på lager). Vil du du legge den til handlekurven uansett?',
  product_sync: 'Synkroniser produkt',
  profile_average: 'Gjennomsnittskjøp',
  protected_sections: 'Lukkede sesjoner',
  receipt_printer: 'Kvitteringsskriver',
  redeem_reward: 'Løs inn bonus',
  referenceNumber: 'referenceNumber',
  refresh_customers: 'Oppdaterer kundeliste',
  refresh_merchant: 'Oppdater kontodetaljer',
  refresh_transactions: 'Oppdaterer transaksjoner',
  refreshing_merchant: 'Oppdaterer kontodetaljer',
  refund_number: 'Tilbakebetaling nummer',
  refunds_not_available_for_provider: 'Tilbakebetalinger ikke tilgjengelig for tilbyder: {{payment_provider}}',
  register_terminal: 'Registrer enhet',
  reissue: 'Utsted på nytt',
  remaining_payment: 'Gjenstår å betale',
  remove_customer_from_cart: 'Fjern fra handlekurv',
  remove_payment: 'Slett betaling',
  remove_printer: 'Fjern skriver',
  removing_products: 'Oppdaterer produkter',
  reopen_shift: 'Gjenåpne kassen',
  replace_customer: 'Erstatt kunde',
  report_by_shift: 'Aktiver daglig åpne/lukk',
  report_printer: 'Rapportskriver',
  requested_amount: 'Forespurt beløp',
  reward: 'Bonuser',
  round_cash_transactions: 'Avrund kontantbetalinger til nærmeste krone',
  save_printer: 'Lagre skriver',
  scan: 'Skann',
  scan_barcode_instruction_one: 'Skann strekkoden for å sette bluetooth-skanneren i parkoblingsmodus',
  scan_barcode_instruction_two: 'Forsikre deg om at bluetooth er aktivert på din enhet og parkoble din Socket CHS skanner',
  scan_code_not_exist: 'Skannet kode {{barcode}} er ikke gjenkjent',
  scan_qr_code: 'Skann QR-koden med betalingsapp',
  scanner: 'Skanner',
  scanner_connected: 'Strekkodsleser tilkoblet',
  scanner_disconnected: 'Strekkodsleser frakoblet',
  scanner_pair_completed: 'Strekkodsleser parkoblet',
  search_for_customer: 'Søk kunder',
  search_report: 'Søk rapporter',
  search_transaction: 'Søk transaksjoner',
  searching_terminals: 'Søker etter terminal(er)...',
  security_and_permissions: 'Sikkerhet og tillatelser',
  select_one_payment_method: 'Minst en betalingsmetode må være aktivert',
  select_payment_method: 'Please select card AID from the list below',
  select_terminal: 'Velg terminal',
  send_email: 'Send epost',
  send_to_another_customer: 'Send til en annen kunde',
  send_to_current_customer: 'Send til nåværende kunde',
  send_to_customer: 'Send til kunde',
  sending_receipt: 'Sender kvittering...',
  server_error: 'Serverfeil',
  session: 'Sesjon',
  set_new_price: 'Ny pris',
  setting_saved: 'Innstillinger lagret',
  settings_connected_terminals: 'Tilboblet terminal',
  settings_printer_available_printers: 'Tilgjengelige skrivere',
  settlementDate: 'settlementDate',
  setup_admin_pin_in_settings: 'Sett din admin PIN under kontofanen i innstillinger.',
  shift_closed: 'Kassen har blitt lukket',
  shift_opened: 'Kassen er åpnet',
  shifts: 'Skifter',
  shopper_reference: 'ShopperReference',
  show_customer: 'Vis kundedetaljer på kvittering',
  show_employee: 'Vis ansattes navn ved mottak',
  show_customer_payconiq: 'Vennligst velg en innlogget kunde eller skann kundens strekkode',
  show_new_product_tile: 'Vis "nytt produkt"-felt',
  show_password: 'Vis passord',
  sign_out_header: 'Er du sikker?',
  sign_out_warning: 'Utlogging vil nullstille alle innstillinger i appen, inkludert betalingsleverandører og skrivere. Vennligst velg avbryt, angi en admin PIN-kode og bruk lås-funksjonen hvis du ikke vil nullstille dine innstillinger. Velg bekreft for at fortsette med å logge ut.',
  sign_up_to_print: 'Registrer deg for et gratis testkonto for å skrive ut kvittering. Ingen kredittkort kreves, og du kan stoppe når som helst.',
  sign_up_to_send_email: 'Registrer deg for et gratis testkonto for å sende e-postkvittering. Ingen kredittkort kreves, og du kan stoppe når som helst.',
  signing_out: 'Logger ut...',
  signup_for_mailing_list: 'Spør kunder om å motta nyhetsbrev',
  sorry_report_not_available: 'Rapport ikke tilgjengelig',
  start_using_pos: 'Begynn å selge med Countr',
  state: '-',
  status: 'Status',
  store_powered_by: 'Denne butikken er støttet av',
  street: 'Gate',
  suggested_amount: 'Foreslått beløp',
  suggested_tip: 'Foreslått drikkepenger',
  support: 'Kundeservice',
  telephone: 'Telefonnummer',
  terminal_port: 'Port konfigurert for terminal',
  terminal_selected: 'Terminal lagret',
  terminalId: 'terminalId',
  test: 'Dette er en test',
  three_inch: '7,5 cm',
  tid: 'TID',
  Time: 'Time',
  tip: 'Tips',
  tip_added: 'Drikkepenger lagt til',
  to_pay: 'Å betale',
  total_amount: 'Totalbeløp',
  transaction_code: 'transactionCode',
  transaction_date: 'TransactionDate',
  transaction_finalized: 'Transaksjon gjennomført',
  transaction_pending: 'Transaksjon venter',
  transaction_started: 'Transaksjon initiert',
  transaction_succeeded: 'Transaksjon vellykket',
  transaction_time: 'Transaksjonstid',
  transaction_timeout: 'Transaksjon timeout',
  transactionSeq: 'transactionSeq',
  transactionType: 'transactionType',
  try_it_now: 'Prøv nå',
  tsi: 'TSI',
  tvr: 'TVR',
  two_inch: '5 cm',
  uncategorized: 'Ingen kategori',
  valid_phone_number: 'Gyldig telefonnummer',
  validating_fields: 'Verifierer felter...',
  validation_in_progress: 'Verifierer...',
  verification_code: 'Verifikasjonskode',
  verification_method: 'Verifikasjonsmetode',
  waiting_for_customer: 'Venter på kunde',
  want_to_sync_products: 'Vil du synkronisere dine produkter?',
  yodopay: 'YodoPay',
  you_must_select_a_store: 'Du må velge en butikk',
  add_name_device: 'Legg til et navn på nettbrettet / telefonen din for å enkelt identifisere det senere (obligatorisk)',
  card_number: 'Kortnummer',
  change_language: 'Skifte språk',
  choose_currency: 'Velg primær valuta',
  clear_cart_after_finish: 'Tilbakestill handlekurven / bordnavnet etter betaling',
  confirm_new_language: 'Bekrefte nytt språk? \nDu må lukke og starte appen på nytt for å bruke denne endringen',
  customer_signature: 'Kundens signatur',
  day: 'Dag',
  device_name_mandatory: 'Enhetsnavnet er obligatorisk',
  downloading_refunds: 'Laster ned refusjoner ...',
  email_report: 'E-postrapport',
  email_successfully_sent_test: 'Test-e-post sendt til kontoen din',
  enter_table_name: 'Skriv inn tabellnavnet',
  error: 'Feil',
  error_retrieve_refunds: 'Feil under henting av refusjon',
  error_retrieve_transactions: 'Feil under henting av transaksjoner',
  for_this_device_see_dashboard: 'Bare for denne enheten - se Dashboard for flere rapporter',
  hide_new_sale: 'Skjul \"Nytt salg\" -skjermbildet etter betaling',
  last_transaction_result: 'Siste transaksjonsresultat',
  merge_warning: 'Dette vil slå sammen innholdet i disse vognene / tabellene',
  month: 'Måned',
  post_code: 'Postnummer',
  print_order: 'Vis send til bestillingsskriver',
  products_added_removed_refresh: 'Produktene ble lagt til eller fjernet - oppdater listen?',
  quarter: 'Fjerdedel',
  refresh: 'Forfriske',
  refreshing_products: 'Forfriskende produkter ...',
  removing_categories: 'Fjerner gamle kategorier ...',
  removing_taxes: 'Fjerner gamle skatter ...',
  select_interface_mandatory: 'Det er obligatorisk å velge et grensesnitt',
  sms_successfully_sent_test: 'Test SMS sendt til kontoen din',
  splitOrderPrintByCategory: 'Delt trykk av ordrer etter kategori',
  stored_digitally: 'Lagret digitalt',
  stripe_id: 'stripeID',
  sync_support_info: 'Info om synkroniseringsstøtte',
  syncing_support_info: 'Synkroniserer støtteinformasjon ...',
  table: 'Bord',
  table_is_empty: 'Tabellen er tom',
  tables_or_carts: 'Vis vogner som tabeller',
  uncertain_card_payment_success: 'Usikker kortbetalingssuksess',
  unknown_category: 'Ukjent kategori',
  unsynced_transactions_report_incorrect: '{{transaction_number}} transaksjon (er) ikke synkronisert - rapporter er ikke oppdaterte',
  update_terminal_firmware: 'Oppdater fastvare for terminalen',
  week: 'Uke',
  year: 'År',
  email_queued: 'Email queued',
  order_received: 'Ny / oppdatert ordre mottatt',
  go_to_orders: 'Vis bestillinger',
  open_carts: 'Åpne vogner',
  auto_print_order: 'Skriv ut ikke-trykte bestillinger mot betaling',
  auto_print_order_cart: 'Skriv ut umerkede bestillinger på vogn / bordendring',
  external: 'Annen',
  no_printer_found_tcp: 'Ingen skriver funnet \nKlikk her for å angi skriverinfo manuelt',
  downloading_carts: 'Laster ned handlekurver ...',
  pay_later_hybrid: 'Betale senere',
  error_retrieve_carts: 'Feil under henting av vogner',
  no_ip_address: 'Ingen adresse',
  building_number: 'Husnummer',
  confirm_manual_payment: 'Bekreft manuell betaling',
  shipping_address: 'Sendingsadresse',
  birth_date: 'Fødselsdato',
  for_this_store_see_dashboard: 'Bare for denne butikken - se Dashboard for flere rapporter',
  customers: 'Kunder',
  tickets_in: 'Billetter i',
  tickets_out: 'Billetter ute',
  tickets_in_out: 'Billetter inn / ut',
  unsynced_transaction_cant_refund: 'Transaksjonen er ennå ikke synkronisert med serveren - refusjon er ikke mulig',
  show_stock_level: 'Vis gjeldende lagernivå (hvis tilgjengelig)',
  available: 'på lager',
  stock_not_available: 'lager ikke spores',
  order_another_store: 'Bestill fra et annet sted',
  order_products: 'Aktiver bestilling av produkter fra andre steder',
  trial_expired: 'Prøveperioden din er utløpt. POS-funksjonalitet er deaktivert.',
  left_menu: 'Venstre navbar',
  right_menu: 'Høyre navbar',
  direct_pay_if_one_method: 'Start automatisk betaling hvis 1 metode er angitt',
  processing_vouchers: 'Behandler vognlojalitet og bilag ...',
  display_2_rows_of_categories: 'Vis to rader med kategorier',
  unknown_barcodes_as_vouchers: 'Behandle ukjente strekkoder som bilag',
  validate_signature: 'Kundesignatur gyldig?',
  group_items_in_receipt_and_cart: 'Gruppere de samme varene i vogn og kvittering',
  ask_customer_subscribe_email: 'Be kunden oppgi e-postadresse',
  hide_categories: 'Skjul kategorier fliser',
  hide_all_products_category: 'Skjul kategorien Alle produkter',
  provisional_receipt: '*** FORELØPIG ***',
  reprint: '*** REPRINT ***',
  block_reprints: 'Ikke tillat omtrykk av varene',
  reports_at_store_level: 'Vis rapporter for butikk, ikke enhet',
  max_addon_qty_exceeded: 'Maksimalt {{maxQty}} tillegg som er tillatt for denne gruppen',
  min_addon_qty_unmet: 'Minimum av {{minQty}} tillegg kreves fra {groupName}',
  press_button_to_wake_terminal: 'Trykk på den grønne knappen for å vekke PIN-terminalen',
  payments: 'Betalinger',
  average_sale_duration_mins: 'Gjennomsnittlig salgstid (minutter)',
  save_order_move_next: 'Lagre bestillingen, fortsett til neste tomme tabell',
  max_orders_reached: 'Maksimalt antall åpne bestillinger nådd',
  show_open_orders: 'Vis åpne ordrer først i ordrelisten',
  tender_created: 'Transaksjonen startet',
  invalid_request: 'Ugyldig forespørsel',
  already_processed: 'Transaksjonen er allerede behandlet',
  invalid_terminal: 'Ugyldig betalingsterminal',
  network_error: 'Nettverksfeil',
  bad_credentials: 'Ugyldige legitimasjon',
  server_issue: 'Kan ikke kontakte serveren',
  pin_entered: 'PIN-kode angitt',
  initial: 'Initialisering',
  card_inserted: 'Kort satt inn',
  card_swiped: 'Kortet sveipet',
  wait_app_selection: 'Venter på søknadsvalg',
  wait_amount_adjustent: 'Venter på beløpsjustering',
  ask_gratuity: 'Be om drikkepenger',
  gratuity_entered: 'Drikkepenger inn',
  ask_dcc: 'Be om DCC',
  dcc_accepted: 'DCC akseptert',
  dcc_rejected: 'DCC avvist',
  processing_tender: 'Behandlingsanbud',
  wait_pin: 'Venter på PIN',
  provide_card_details: 'Gi kortinformasjon',
  card_details_provided: 'Kortinformasjon gitt',
  receipt_printed: 'Kvittering skrevet ut',
  referral: 'Henvisning',
  referral_checked: 'Henvisning sjekket',
  check_signature: 'Sjekk signaturen',
  signature_checked: 'Signatur sjekket',
  additional_data_available: 'Ytterligere data tilgjengelig',
  transaction_acknowledged: 'Transaksjon anerkjent',
  device_already_registered: 'Enheten er allerede registrert',
  add_in_progress: 'Legger allerede til enhet',
  app_not_registered: 'Appen er ikke registrert',
  unknown_error: 'Ukjent feil',
  timeout: 'Tidsavbrudd',
  verify_failed: 'Kunne ikke bekrefte enheten',
  identify_failed: 'Kunne ikke identifisere enheten',
  registration_error: 'Registreringsfeil',
  sync_issue: 'Kunne ikke synkronisere enheten',
  show_select_customer: 'Vis kundevelger',
  store_name_mandatory: 'Butikkens navn er obligatorisk',
  choose_store_dashboard_for_more: 'Velg butikk - mer kan legges til via back-office dashbordet',
  recovering_incomplete_payment: 'Gjenopprette ufullstendig betaling - dette kan ta opptil 30 sekunder',
  allow_negative_sale: 'Tillat negativt salgsbeløp',
  move_current_table_to: 'Flytt gjeldende tabell ({{table_name}}) TIL',
  store: 'butikk',
  print_specific_categories: 'Skriv ut spesifikke kategorier',
  customer_display_attached: 'Kundedisplay vedlagt',
  sale: 'Salg',
  reps_and_waste_button_in_cart: 'Vis representanter og avfallsknapp på handlekurven',
  small_product_tiles: 'Reduser størrelsen på produktfliser',
  large_product_tiles: 'Øk størrelsen på produktfliser',
  show_product_price_on_tile: 'Produktpris på fliser',
  printer_roles: 'Skriverroller',
  paper_size: 'Papir størrelse',
  customer_display: 'Kundedisplay',
  enable_realtime_updates: 'Aktiver sanntidsoppdateringer fra serveren',
  show_open_orders_first: 'Vis åpne ordrer først i ordrelisten',
  blank_lines_order_ticket: 'Tomme linjer øverst på bestillingsbilletten',
  blank_lines_bottom_order_ticket: 'Tomme linjer nederst på bestillingsbilletten',
  scan_card: 'Skann kort',
  scan_nfc_or_barcode: 'Skann NFC-kort eller kode',
  show_product_keypad: 'Vis tastaturet på handlekurven',
  extra_payment_methods: 'Ekstra betalingsmåter',
  failed_recovery: 'Kunne ikke gjenopprette betalingen',
  blank_line_between_order_items: 'Tom linje mellom bestillingsbilletter',
  look_up_customers_using_barcodes: 'Legg kunde i handlekurven ved hjelp av skanneren',
  error_loading_configuration: 'Feil ved innlasting av konfigurasjon',
  error_verifying_payment_terminal: 'Feil ved bekreftelse av PIN-terminal',
  error_identifying_payment_terminal: 'Feil ved identifisering av PIN-terminal',
  error_saving_configuration: 'Feil ved lagring av konfigurasjon',
  conflicting_server_modes: 'Pin terminal og server har motstridende moduser (TEST vs PRODUCTION)',
  nothing_to_print: 'Ingen elementer å skrive ut',
  large_item_name: 'Øk størrelsen på det trykte elementet',
  print_ean_sku: 'Skriv ut EAN og SKU ved mottak',
  select_category_on_startup: 'Velg kategori en gang ved oppstart',
  split_tickets_item: 'Delt trykk av ordrer etter vare',
  split_tickets_amount: 'Delt trykk av ordrer etter antall',
  split_print: 'Delt trykk',
  refreshing_customers: 'Forfriskende kunder ...',
  downloading_customers: 'Laster ned kunder ...',
  error_retrieve_customers: 'Feil under henting av kunder. \nKontroller internettforbindelsen og prøv igjen',
  server_error_please_retry: 'Serverfeil. Prøv på nytt',
  vibrate_on_click: 'Vibrasjonsfeedback på vareklikk',
  keep_screen_on: 'Ikke la skjermen sove',
  refund_same_method: 'Tving refusjoner til å være med den originale betalingsmåten',
  cannot_refund_more_than_paid: 'Kan bare refundere maksimalt {{max_amount}} via {payment_method}',
  tablet_date_incorrect: 'Enhetsdato er feil innstilt - mulig sikkerhetsrisiko',
  extra_non_revenue_info: 'Be om ekstra informasjon for ikke-inntekter',
  enter_extra_info: 'Skriv inn ekstra vareinformasjon',
  giftcard_or_reference_no: 'Gavekort eller referansenummer',
  inserted_code_not_valid: 'Koden er feil',
  validate_user: 'Brukervalidering ({{user_address}})',
  enter_validation_code: 'Vennligst skriv inn valideringskoden du mottok via SMS eller e-post',
  tax_rate: 'Skattesats',
  pretax: 'Nett',
  deposit: 'Innskudd',
  can_not_refund_with_different_provider: 'Kan ikke tilbakebetale {{txn_payment_method}} transaksjoner ved hjelp av {selected_payment_method}',
  external_scanner_connected: 'Ekstern skanner tilkoblet',
  cancelling: 'Avbryter',
  sumup: 'SumUp',
  ccv: 'CCV',
  payplaza: 'PayPlaza',
  worldline: 'Worldline',
  adyen: 'Adyen',
  rabosmartpin: 'Rabo SmartPin',
  exchange: 'Utveksling',
  ask_exchange: 'Tillat produktutveksling',
  exchange_error: 'Utvekslingsfeil',
  exchange_items: 'Bytt varer',
  exchangeable: 'Utskiftbar',
  to_be_exchanged: 'Skiftes ut',
  exchange_all: 'Bytt alt',
  negative_sale_amount: 'Beløpet du skal betale er mindre enn 0',
  download_rabosmartpin_app: 'Last ned Rabo SmartPin-appen',
  transaction_disabled: 'Tredjepartsbetalinger er ikke tillatt på denne kontrakten',
  transaction_in_progress: 'En annen betaling pågår allerede',
  refund_offline: 'Refusjoner er ikke mulig i begrenset modus',
  refund_not_allowed: 'Refusjoner er ikke tillatt for den påloggede brukeren',
  cash_forbidden: 'Kontantbetalinger er ikke tillatt på denne kontrakten',
  notify_new_online_order: 'Vis varsel når ny online bestilling mottas',
  print_new_online_order: 'Skriv ut nye online bestillinger automatisk når de mottas',
  delivery_information: 'Leveringsinformasjon',
  voucher_already_processed: 'Kupongen er allerede behandlet',
  no_applicable_items_in_cart: 'Ingen aktuelle gjenstander funnet i handlekurven',
  discount_applied: 'Rabatt brukt',
  invalid_voucher: 'Ugyldig kupong',
  allow_voucher_scan: 'Se etter kuponger ved hjelp av strekkodeleser',
  enable_drop_shipping: 'Aktiver drop-shipping',
  ask_no_covers_or_takeaway: 'Spør alltid bordkonfigurasjon',
  table_configuration: 'Tabelltype',
  num_covers: 'Antall gjester',
  takeaway: 'Ta bort',
  guests: 'Gjestene',
  show_floorplan_after_sale: 'Vis plantegning etter hvert salg',
  lock_after_x_minutes: 'Lås etter x minutter med inaktivitet',
  show_deposit_items: 'Vis innskuddsartikler',
  pickup: 'Plukke opp',
  delivery: 'Leveranse',
  transaction_fee: 'Transaksjonsgebyr',
  print_description: 'Skriv ut varebeskrivelse ved mottak',
  only_cash_for_negative_amounts: 'Bare kontant eller manuell kortbetaling kan velges når beløpet er mindre enn 0',
  phone_number_example: 'Telefon - f.eks. 31611111111',
  whatsapp_not_available_on_this_device: 'WhatsApp er ikke installert på denne enheten',
  receipt_not_yet_synchronised: 'Transaksjonssynkronisering med server - prøv igjen om noen øyeblikk',
  notify_online_orders_with_sound: 'Spill lyd når online bestillinger mottas',
  restrict_online_orders_by_floor: 'Godta bare online bestillinger for visse etasjer',
  remove_product: 'Fjern produktet',
  admin_access_needed: 'Denne funksjonen er bare tilgjengelig for administratorbrukere',
  customer_or_employee_name: 'Kunde- eller ansattnavn',
  show_cart_on_left: 'Vis vogn på venstre side',
  groupOrderPrintByCategory: 'Gruppetrykk av ordrer etter kategori',
  handpoint_shared_secret: 'SharedSecret',
  create_bundle: 'Lag pakke',
  select_items_to_bundle: 'Velg elementer du vil pakke sammen',
  bundle_price: 'Pakkepris',
  create_bundle_items: 'Lag et produktpakke på handlekurven',
  bundle: 'Bunt',
  loss: 'Loss',
  stolen: 'Stolen',
  own_use: 'Own Use',
  giveaway: 'Giveaway',
  breakage: 'Breakage',
  deviceid_mandatory: 'Enhetsnavnet er obligatorisk',
  send_order: 'Send bestilling',
  no_description: 'Dette produktet har ingen beskrivelse. Du kan legge til en ved å trykke på "Endre" -knappen eller besøke dashbordet ditt.',
  change_description: 'Vennligst skriv inn produktbeskrivelsen.',
  main_cart_is_empty: 'Hovedvognen er tom',
  apply_to_all_carts: 'Påfør alle vogner',
  per_value_payment: '(Betaling per verdi)',
  select_a_seat: 'Velg et sete',
  save_table: 'Lagre',
  save_cart: 'Lagre',
  select_items: 'Velg elementer',
  partial_payment_not_allowed_by_value: 'Delbetaling er ikke tillatt',
  store_insights: 'Butikkinnsikt',
  allow_ticket_scan: 'Se etter billetter ved hjelp av strekkodeleser',
  allow_ticket_scan_desc: 'Bruk strekkodeskanneren for å søke etter billetter.',
  admin_only_custom_item: 'Aktiver tilpasset element bare for admin',
  admin_only_custom_item_desc: 'Bare brukere med administratorrettigheter kan opprette et tilpasset element. Du må også aktivere ansatt-PIN.',
  mandatory_employee_pin: 'Obligatorisk aktivering av ansatt PIN.',
  admin_only_refunds: 'Bare administratorer kan utføre refusjoner',
  admin_only_refunds_desc: 'Bare administratorer kan utføre refusjoner. Du må også aktivere ansatt-PIN.',
  round_cash_transactions_desc: 'Avrund automatisk kontantbetalinger til nærmeste 0,05',
  unlimited_usage: 'Ubegrenset bruk',
  infinite: 'Ubegrenset',
  disable_custom_discounts: 'Deaktiver tilpassede rabatter',
  disable_custom_discounts_desc: 'Deaktiver egendefinerte rabatter, men tillat forhåndsdefinerte.',
  guest: 'Gjest',
  select_method: 'Velg metode',
  manual_insert: 'Manuell innsats',
  enter_code: 'Skriv inn kode',
  existing_by_value_payment: '* Du kan ikke blande lønn etter verdi og betal etter sete og/eller vare',
  order_sent: 'Bestilling Sendt',
  select_multiple_seats: 'Velg flere seter',
  change_seat_or_guest_num: 'Bytt sete eller gjestenummer',
  change_guest_num: 'Endre gjestenummer',
  giftcard_low_value: '* Gavekortsaldoen er lavere enn verdien av valgt vare(r). Prøv å betale etter verdi i stedet.',
  order_by_seat_desc: 'Aktiver funksjonen for bestilling etter sete.',
  received_amount: 'Mottatt beløp',
  order_products_action: 'Bestill produkter',
  ordered_products: 'Bestilte produkter',
  select_table: 'Velg bord',
  ordered: 'Bestilt',
  scan_or_type: 'Skann eller skriv',
  type_code: 'Skriv inn kode',
  number_extension: 'Nummertillegg',
  move_processing_ticket_first: 'Flytt behandlingsbilletter først',
  disable_adblocker: 'Vennligst deaktiver AdBlocker og oppdater siden',
  remove_partial_payment_by_item: 'Delbetaling per vare oppdaget! Vennligst fjern den for å fortsette med denne handlingen!',
  add_nutrients: 'Legg til næringsstoffer',
  giftcard_warning_msg: 'Standard gyldighet for et gavekort er 2 år. Er du sikker på at du vil redusere dette?',
  edit_devicess_main_details: 'Rediger enhetens hoveddetaljer.',
  create_custom_payment_methods_for_your_device: 'Lag egendefinerte betalingsmåter for enheten din.',
  show_open_carts_from_device: 'Vis åpne bestillinger fra enheten',
  create_discounts_for_your_stores: 'Opprett rabatter for stedet ditt.',
  create_reps_waste_for_your_stores: 'Opprett representanter og avfall for din plassering.',
  show_cat_bar: 'Vis kategorilinje',
  bulk_update: 'Masseoppdatering av varer',
  show_category_product_info: 'Vis kategoriinfo i produkt',
  ready_items: 'Klare varer',
  disabled: 'Funksjonshemmet',
  ready_orders: 'Klare bestillinger',
  changes_list: 'Endringsliste',
  action_type: 'Handlingstype',
  previous_change: 'Tidligere endring',
  new_changes: 'Nye endringer',
  removed_fields: 'Fjernet felt',
  updated_fields: 'Oppdaterte felter',
  full_history: 'Full historie',
  changes_history: 'Endringshistorikk',
  product_history: 'Produkthistorie',
  category_history: 'Kategorihistorie',
  device_history: 'Enhetshistorikk',
  enable_mandatory_customer_address: 'Obligatorisk kundeadresse',
  please_fill_all_fields: 'Fyll ut alle obligatoriske felt: bygningsnummer, gate, by, stat/provins, postnummer, land, for- og etternavn, e-postadresse eller telefonnummer',
  select_file: 'Velg Fil',
  drop_file: 'Slipp fil',
  create_media: 'Lag medier for',
  upload_image: 'Last opp bilde',
  upload_file: 'Last opp fil',
  add_custom_img: 'Legg til ditt egendefinerte bilde',
  select_media: 'Velg medietype',
  external_url_iframe: 'Ekstern URL-iframe',
  select_ticket_print: 'Velg type for utskrift',
  external_service_provider: 'Ekstern tjenesteleverandør',
  barcode_input: 'Strekkodeinntasting',
  stock_load_msg: 'Sjekk alle produkter for lager. Dette kan ta litt tid. Vennligst vent!',
  no_available_stores: 'Ingen butikker tilgjengelig.',
  buy_combination_get_deal_new_price: 'Kombiner varer for en kontraktspris',
  discount_specific_products_categories: 'Rabatt på spesifikke produkter eller kategorier',
  discount_all_prodcucts: 'Rabatt på alle produkter',
  buy_x_items_get_cheapest_discount: 'Kjøp x varer, få den billigste med rabatt',
  spend_certain_amount_get_discount: 'Bruk et visst beløp, få rabatt',
  customer_group_item_discount: 'Rabatt på spesifikke kundegrupper',
  item_surcharge: 'Tillegg på spesifikke produkter, kategorier eller kundegrupper',
  cart_surcharge: 'Tillegg på hele bestillingen',
  import: 'Import',
  export: 'Eksport',
  simple: 'Enkel',
  advanced: 'Avansert',
  delete_import: 'Slett import av produkter',
  upload_csv: 'Last opp CSV-filen din',
  open_file: 'Åpne filen med',
  save_file: 'Lagre filen som .csv',
  value_remaining: 'Gjenstående verdi:',
  shared_shift: 'Del skift',
  shared_shift_desc: 'Å bytte ansatt vil ikke kreve å åpne registeret på nytt',
  sample_check_msg_top: 'Vennligst vent. Det bør være en sjekk.',
  sample_check_msg_bottom: 'En ansatt kommer så fort som mulig.',
  full_basket_check: 'Full kurvsjekk',
  missing_items_amount: 'Beløp for manglende varer',
  extra_items_scanned: 'Ekstra varer beløp',
  finish_check: 'Fullfør sjekk',
  original_items: 'Originale gjenstander',
  checked_items: 'Sjekkede varer',
  merging: 'Sammenslåing pågår',
  select_table_from_list: 'Velg en tabell fra listen for å slå sammen alle andre valgte tabeller',
  cant_merge_same: 'Ga e kgone go kopanya tafola ye e swanago, hle kgetha tafola ye nngwe yeo o tlago e kopanya',
  remove_from_table: 'fjerne fra bordet',
  eat_in: 'Spis i',
  cancel_order_pin: 'Kanseller bestillingsnål'
}

export default noLang
