import React from 'react'
import Order from './Order'
import { STATUS } from '../../utils/CONSTS'
import './../../styles/ReadyOrderAlert.scss'

const ALERT_ANIMATION = {
  INITIAL: { scale: 0, y: -500, opacity: 0 },
  ANIMATE: { scale: 1, y: 0, opacity: 1 },
  TRANSITION: { duration: 0.5 }
}

const ReadyOrderAlert = React.memo(({ order, closeAlert }) => {
  return (
    <div className="ready-alert">
      <div className="backdrop" onClick={closeAlert}>
        <div className="alert">
          {!!order && !!order._id && (
            <Order order={order} status={STATUS.READY} animation={ALERT_ANIMATION} />
          )}
        </div>
      </div>
    </div>
  )
})

export default ReadyOrderAlert
