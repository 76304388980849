import { Countr } from './../CountrSDK'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { LOCAL_STORAGE } from './CONSTS'

export default class DeviceUtils {
  static async generateUUID() {
    const localUUID = localStorage.getItem(LOCAL_STORAGE.UUID)
    if (!!localUUID) {
      return localUUID
    }

    const fpPromise = FingerprintJS.load({
      apiKey: 'qWrdj7r6z8RlqD9KlAwy',
      endpoint: [
        'https://fp.countrhq.com',
        FingerprintJS.defaultEndpoint // The default endpoint as fallback
      ]
    })
    const fp = await fpPromise
    const result = await fp.get({ extendedResult: true })
    const uuid = 'order-' + result.visitorId

    localStorage.setItem(LOCAL_STORAGE.UUID, uuid)
    return uuid
  }

  static getDevice = async uuid => {
    const countr = await Countr.getCountrSdk()
    return countr.devices.readOne.detailed(uuid).then(
      device => {
        return Promise.resolve(device)
      },
      error => {
        return Promise.reject(error)
      }
    )
  }

  static updateDevice = async dev => {
    const device = { ...dev }
    device.store = device.store._id ? device.store._id : device.store
    device.employees = device.employees.map(employee => (!!employee._id ? employee._id : employee))
    const countr = await Countr.getCountrSdk()
    return countr.devices.update(device._id, device).then(
      updatedDevice => {
        return Promise.resolve(updatedDevice)
      },
      error => {
        console.log('TCL: DeviceUtils -> error', error)
        return Promise.reject(error)
      }
    )
  }

  static registerDevice = async (uuid, storeId, name) => {
    const countr = await Countr.getCountrSdk()

    if (!storeId) {
      const stores = await countr.stores.read()

      if (stores && stores.length) {
        storeId = stores[0]._id
      } else {
        return Promise.reject({ message: 'No store found for this account.' })
      }
    }

    const deviceParams = {
      store: storeId,
      uuid: uuid,
      name: name,
      info: {},
      settings: {
        language: 'en'
      }
    }

    return countr.devices.create(deviceParams).then(
      createdDevice => {
        return countr.devices.readOne.detailed(createdDevice._id)
      },
      error => {
        return Promise.reject(error)
      }
    )
  }
}
