import { STATUS, TRACKING_STATUS } from './CONSTS'
import { translate } from 'react-internationalization'

export default class OrdersUtils {
  static getName = (order) => {
    return order.extras?.delivery?.channelName
      ? `${order.extras?.delivery?.channelName} ${order.extras?.delivery?.channelOrderDisplayId}`
      : order.__t &&
        order.__t === 'Transaction' &&
        order.receipt_id.indexOf('ORDER') < 0 &&
        ['kiosk'].indexOf(order.order_source) < 0 &&
        order.receipt_id.length > 3
      ? ['external_order'].indexOf(order.order_source) >= 0
        ? order.extras.orderProvider.toUpperCase() + ' ' + order.receipt_id
        : order.receipt_id
      : order.extras && order.extras.deviceCartName
      ? order.extras.deviceCartName.trim().replace('KASSABON: ', '')
      : order.receipt_id
  }

  static getDate = (date) => {
    const now = new Date(date)
    return now.toLocaleDateString('en-GB')
  }

  static getTime = (date) => {
    const now = new Date(date)
    return now.toLocaleTimeString('en-GB')
  }

  static getStatusCount = (orders, status) => {
    if (orders) {
      return orders.reduce((acc, order) => {
        return acc + (order.status && order.status === status ? 1 : 0)
      }, 0)
    }
  }

  static getOrderStatus = (order) => {
    // if (!!order.status) {
    //   return order.status
    // }

    switch (order.status) {
      case STATUS.NEW:
        return STATUS.NEW
      case STATUS.PENDING:
      case STATUS.PRINTED:
        return STATUS.PRINTED
      case STATUS.READY:
        return STATUS.READY
      case STATUS.COMPLETED:
        return STATUS.COMPLETED
      case STATUS.CANCELLED:
        return STATUS.CANCELLED
      default:
        return STATUS.PREPARING
    }

    // let countReady = 0
    // // eslint-disable-next-line no-unused-vars
    // let countPreparing = 0
    // let countPrinted = 0
    // let countNew = 0
    // let countCompleted = 0
    // let totalPerStatus = 0

    // order.items.forEach(item => {
    //   item.status.forEach(status => {
    //     if (status.amount > 0) {
    //       switch (status.state) {
    //         case STATUS.READY:
    //           countReady += status.amount
    //           break
    //         case STATUS.PREPARING:
    //           countPreparing += status.amount
    //           break
    //         case STATUS.PRINTED:
    //           countPrinted += status.amount
    //           break
    //         case STATUS.PENDING:
    //           countPrinted += status.amount
    //           break
    //         case STATUS.NEW:
    //           countNew += status.amount
    //           break
    //         case STATUS.COMPLETED:
    //           countCompleted += status.amount
    //           break
    //         default:
    //           break
    //       }

    //       totalPerStatus += status.amount
    //     }
    //   })
    // })

    // // if order is completed, it goes out of the app
    // if (countCompleted === totalPerStatus) {
    //   return STATUS.COMPLETED
    // } else if (countNew === totalPerStatus) {
    //   // if all items has new status, it does not show here
    //   return STATUS.NEW
    // } else if (countReady === totalPerStatus) {
    //   return STATUS.READY
    // } else if (countPrinted === totalPerStatus) {
    //   return STATUS.PRINTED
    // } else {
    //   return STATUS.PREPARING
    // }
  }

  static getOrderStatusFromRedux = (orders, orderId, payload) => {
    if (!!payload?.status) {
      switch (payload.status) {
        case STATUS.NEW:
          return STATUS.NEW
        case STATUS.PENDING:
        case STATUS.PRINTED:
          return STATUS.PRINTED
        case STATUS.READY:
          return STATUS.READY
        case STATUS.COMPLETED:
          return STATUS.COMPLETED
        case STATUS.CANCELLED:
          return STATUS.CANCELLED
        default:
          return STATUS.PREPARING
      }
    }

    let st = TRACKING_STATUS.PRINTED
    Object.keys(TRACKING_STATUS).forEach((stateKey) => {
      const index = orders[TRACKING_STATUS[stateKey]].findIndex((o) => o._id === orderId)
      if (index >= 0) {
        st = this.getOrderStatus(orders[TRACKING_STATUS[stateKey]][index])
      }
    })

    return st
  }

  static getOrdersFromStatus = (orders, st) => {
    return [...orders].filter((order) => {
      return this.getOrderStatus(order) === st
    })
  }

  static renderStatus = (status) => {
    if (!!status && !!status.length) {
      let finalStatus
      const localStatus =
        status === STATUS.PENDING || status === STATUS.PRINTED ? STATUS.NEW : status
      switch (localStatus) {
        case STATUS.NEW:
        case STATUS.PENDING:
          finalStatus = translate('new')
          break
        case STATUS.PRINTED:
          finalStatus = translate('new')
          break
        case STATUS.PREPARING:
          finalStatus = translate('preparing')
          break
        case STATUS.READY:
          finalStatus = translate('ready')
          break
        case STATUS.COMPLETED:
          finalStatus = translate('ready')
          break
        case STATUS.CANCELLED:
          finalStatus = translate('cancelled')
          break
        default:
          finalStatus = localStatus
      }
      return finalStatus
    } else {
      return STATUS.NEW
    }
  }
}
