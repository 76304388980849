import { SET_ORDERS, ADD_ORDER, UPDATE_ORDER, REPLACE_ORDER } from './actionTypes'

export const setOrders = orders => ({
  type: SET_ORDERS,
  payload: orders
})

export const addOrder = order => ({
  type: ADD_ORDER,
  payload: order
})

export const updateOrder = order => ({
  type: UPDATE_ORDER,
  payload: order
})

// Replace cart from transaction in ordersReducer
export const replaceOrder = order => ({
  type: REPLACE_ORDER,
  payload: order
})
