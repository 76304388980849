/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
const arLang = {
  cart: 'عربة التسوق',
  item: 'عنصر',
  items: 'عناصر',
  subtotal: 'المجموع الكلي',
  discount: 'خصم',
  total: 'الإجمالي',
  pay: 'يدفع',
  card: 'بطاقة',
  cash: 'نقدي',
  invoice: 'فاتورة',
  giftcard: 'بطاقة هدايا',
  countr_giftcard: 'عداد بطاقة هدايا',
  seqr: 'اس أي كيو ار',
  bitpay: 'بيت باي',
  payment_method: 'طريقة الدفع',
  cash_received: 'المبلغ المدفوع',
  or_enter_amount: 'أو أدخل المبلغ',
  payment_succeeded: 'عملية دفع مقبولة',
  change_due: 'المبلغ المتبقي المستحق',
  total_paid: 'إجمالي المدفوع',
  send_receipt: 'أرسل الإيصال',
  send_invoice: 'أرسل الفاتورة',
  send: 'أرسل',
  delete: 'إمسح',
  new_sale: 'عملية بيع جديدة',
  custom_price: 'السعر المخصص',
  item_description: '(إختياري) خصم علي العنصر',
  products: 'المنتجات',
  transactions: 'عمليات',
  reports: 'تقارير',
  report: 'تقرير',
  settings: 'إعدادات',
  sign_out: 'تسجيل خروج',
  receipt: 'إيصال',
  refund: 'مرتجع',
  home: 'الصفحة الرئيسية',
  clear: 'صافي',
  add: 'يضيف',
  sign_up: 'تسجيل حساب جديد',
  sign_up_email: 'تسجيل حساب بالبريد الإلكتروني',
  sign_in: 'تسجيل دخول',
  company_name: 'إسم تجاري',
  merchant_name: 'جهة الإتصال',
  email_address: 'البريد الإلكتروني',
  phone_number: 'رقم الهاتف',
  password: 'كلمة السر',
  confirm_password: 'تأكيد كلمة السر',
  forgot_password_question: 'نسيت كلمة السر ',
  next: 'التالي',
  demo: 'عرض تجريبي',
  skip: 'تخطي',
  skip_registration: 'تخطي التسجيل',
  take_a_tour: 'أخذ جولة',
  items_returned: 'عنصر مسترد',
  wrong_billing: 'فاتورة خطأ',
  order_cancelled: 'تم إلغاء الطلب',
  other: 'أخري',
  enter_valid_amount: 'أدخل مبلغ صحيح',
  invalid_merchant_name: 'خطأ جهة إتصال ',
  invalid_business_name: 'إسم تجاري خطأ',
  invalid_email_address: 'خطأ في البريد الكتروني',
  invalid_phone_number: 'خطأ في رقم الهاتف',
  invalid_password: 'كلمة سر خطأ - يجب ألا تقل عن 8 أحرف وأرقام ',
  password_mismatch: 'كلمة سر غير مطابقة ',
  coming_soon: 'متاحة قريبا',
  to_use_signup: 'يجب التسجيل للإستخدام',
  login_error: 'خطأ في  تسجيل الدخول',
  signup_error: 'خطأ في تسجيل الإشتراك',
  transaction_history_empty: 'العمليات السابقة غير موجودة',
  categories: 'الأقسام',
  all_products: 'جميع المنتجات',
  search_product: 'البحث عن منتج',
  reissue_receipt: 'إعادة إصدار إيصال',
  print_receipt: 'طباعة إيصال',
  or_send_receipt: 'أو أرسل إيصال',
  save: 'حفظ',
  saved: 'تم الحفظ',
  search: 'بحث',
  custom_item: 'عنصر محدد',
  submit: 'يسلم',
  submitted: 'تم التسليم',
  note: 'ملحوظة',
  message: 'رسالة',
  amount: 'مبلغ',
  number: 'رقم',
  taxes: 'ضرائب',
  tax: 'ضريبة',
  enter_valid_number: 'أدخل رقم صحيح',
  enter_valid_text: 'أدخل نص صحيح',
  enter_valid_phone: 'أدخل رقم هاتف صحيح',
  enter_valid_email: 'أدخل بريد إلكتروني صحيح',
  feature_not_in_demo: 'الخاصية غير متاحة ',
  provide_refund_reason: 'أذكر سبب الإسترجاع',
  provide_valid_refund_amount: 'أذكر مبلغ مرتجع صحيح',
  refund_exceed_refundable: 'المبلغ المرتجع يتجاوز حد الإسترجاع لهذا الإيصال ',
  refund_saved: 'تم حفظ المبلغ المرتجع ',
  or_customize_discount: 'أو خصم مخصص',
  enter_percentage: 'أدخل النسبة',
  manual_card_payment: 'عملية دفع فيزا  يدويا',
  did_card_payment_succeed: 'هل نحجت عملية الدفع بالبطاقة',
  manual_card_refund: 'عملية استرجاع مبلغ مدفوع بالبطاقة يدويا',
  did_card_refund_succeed: 'هل نجحت عملية استرجاع المبلغ المدفوع بالبطاقة',
  giftcard_refund: 'استرجاع بطاقة هدايا',
  did_giftcard_refund_succeed: 'بطاقة هدايا جديدة صادرة للعميل؟',
  yes: 'نعم',
  no: 'لا',
  general: 'عام',
  printer: 'طابعة',
  barcode_scanner: 'ماسح ضوئي ضوئي باركود',
  payment: 'دفع',
  profile: 'ملف شخصي',
  currency: 'عملة',
  printing_receipt: 'طباعة إيصال',
  enter_amount: 'أدخل القيمة',
  expected_change: 'الباقي المتوقع',
  still_remaining: 'لايزال باقيا',
  issue_invoice: 'إصدار فاتورة',
  URL_pricing: 'http://www.countrhq.com/#pricing',
  address: 'عنوان',
  address_continued: 'عنوان (إختياري)',
  city: 'مدينة',
  zip: 'رقم بريدى',
  country: 'بلد',
  country_iso: 'بلد - رقم أيزو 3166-2 مثال (de - nl)',
  invalid_address: 'خطأ في العنوان أو  عنوان فارغ',
  invalid_city: 'خطأ في المدينة أو مدينة  فارغة',
  invalid_country: 'خطأ في البلد أو بلد فارغ',
  invalid_ecommerce: 'لم يتم اختيار منصة للتجارة الإلكترونية',
  signup_error_contact_support: 'خطأ في تسجيل الإشتراك ، أتصل بالدعم upport@countrhq.com',
  open_orders: 'أفتح الطلبات',
  order: 'طلب ',
  enter_weight_g: 'أدخل الوزن',
  search_scanner: 'ابحث عن قابس الماسح ضوئي',
  order_settings: 'إعدادات عربة المشتريات',
  order_amount: 'أرقام عربات المشتريات المتاحة',
  receipts: 'إيصالات',
  print_receipts: 'إطبع الإيصالات أتوماتيكيا',
  open_cash_drawer: 'إفتح درج الخزنة أتوماتيكيا',
  backup: 'نسخ إحتياطي',
  email_backup: 'نسخ إحتياطي للبريد الإلكتروني ',
  server_sync: 'مزامنة سيرفر',
  external_payment: 'أخري',
  search_printer: 'بحث عن الطابعة',
  sms_successfully_sent: 'تم إرسال الرسالة بنجاح',
  error_sending_sms: 'خطأ في إرسال الرسالة ، أعد المحاولة مرة أخري',
  email_successfully_sent: 'تم إرسال البريد إلكتروني بنجاح',
  error_sending_email: 'خطأ في إرسال البريد إلكتروني ',
  transaction: 'عملية ',
  logging_in: 'تسجيل دخول',
  downloading_products: 'تحميل المنتجات',
  add_to_cart: 'أضف إلي عربة المشتريات',
  invalid_price: 'خطأ في السعر',
  invalid_discount: 'خطأ في الخصم',
  invalid_new_price: 'خطأ في السعر الجديد ، يجب أن يقل عن السعر الأصلي',
  invalid_amount: 'خطأ في المبلغ',
  edit_price: 'عدل السعر',
  discount_perc: 'نسبة الخصم (%)',
  quantity: 'كمية',
  remove: 'يحذف',
  sales_info: 'بيانات المبيعات',
  summary: 'ملخص',
  refunded_information: 'بيانات المرتجع',
  total_sale: 'إجمالي المبيعات',
  total_amount_refunded: 'إجمالي المبلغ المرتجع',
  remaining: 'متبقي',
  sold_by: 'تم البيع بواسطة ',
  source_receipt_number: 'مصدر رقم الإيصال',
  issued_date: 'تاريخ الإصدار',
  issued_time: 'وقت الإصدار',
  refunded_by: 'تم عمل الإسترجاع بواسطة',
  reason: 'سبب',
  receipt_number: 'رقم الإيصال',
  refunded_date: 'تاريخ الإسترجاع',
  refunded_time: 'وقت الإسترجاع',
  first_name: 'الإسم الأول',
  last_name: 'الإسم الثاني',
  shop_category: 'إختر قسم',
  choose_country: 'إختر البلد',
  you_will_receive_email: 'مبروك ، تم الإنتهاء من تسجيل الإشتراك',
  wait_email: 'إنتظر بريد إلكتروني بتعليمات أخري عن كيفية ربط الاي كوميرس بنفطة البيع ',
  open_dashboard: 'يمكنك الأن إدارة حسابك وإنشاء المنتجات في لوحة التحكم الخاصة بك ',
  by_signing_up: 'بتسجيل الإشتراك أنا أقبل ال',
  terms_and_conditions: 'الشروط والقواعد',
  and: 'و',
  privacy_policy: 'سياسة الخصوصية',
  refund_items: 'العناصر المرتجعة',
  forgot_password: 'نسيت كلمة السر ؟',
  register_device: 'تسجيل جهاز جديد',
  device_not_registered: 'الجهاز غير مسجل ',
  want_to_add_to_account: 'أريد إضافة إلي الحساب',
  if_ios_previously_registered: 'إذا قمت مؤخرًا بإعادة تثبيت كونتر ، فستحتاج إلى تسجيل جهازك مرة أخرى.',
  new_device_info: 'سيُطلب منك في الشاشة التالية ربط جهازك بأحد متاجرك',
  not_now: 'ليس الأن',
  proceed: 'يستكمل',
  more_info_click: 'اضغط لمعلومات أخري',
  your_detail: 'بياناتك',
  vat_no: 'رقم تسجيل الضريبة المضافة',
  billing_details: 'بيانات سحب الفاتورة ',
  add_store: 'أضف متجر',
  or_add_to_existing_store: 'أو أضف إلي متجر حالي',
  add_new_store: 'أضف متجر جديد',
  store_information: 'بيانات المتجر',
  store_name: 'إسم المتجر',
  go_to_web_dashboard: 'إذهب إلي لوحة تحكم الويب ',
  error_create_account: 'خطأ في إنشاء الحساب',
  error_creation_error: 'خطأ في إنشاء الحساب',
  error_wrong_phone_number: 'خطأ - رقم تليفون غير صحيح',
  error_try_again: 'خطأ في الإتصال- حاول مرة أخري',
  error_from_the_server: 'خطأ في الإتصال بالسيرفر',
  error_update_cc_info: 'خطأ في تحديث بيانات بطاقة المشتريات ',
  error_add_device: 'خطأ في  إضافة جهاز إلي المتجر الحالي',
  error_add_store: 'خطأ في إضافة متجر جديد',
  are_not_valid: 'غير صحيحين',
  is_not_valid: 'غير صحيح',
  are_empty: 'خاليين',
  is_empty: 'خالي',
  are_not_selected: 'لم يتم إختيارهم',
  is_not_selected: 'لم يتم إختياره',
  bill: 'فاتورة',
  URL_dashboard: 'https://backoffice.countrhq.com/',
  URL_support: 'mailto:support@countrhq.com',
  URL_forgot_password: 'https://backoffice.countrhq.com/#/auth/forgot',
  close: 'أغلق',
  no_product_found: 'المنتج غير متاح',
  please_go_to_dashboard: 'لإضافة منتجات برجاء الذهاب إلي https://backoffice.countrhq.com',
  select_interface: 'إختر واجهة المستخدم',
  refund_all: 'عمل إسترجاع للكل',
  purchased_items: 'العناصر التي تم شراؤها',
  refundable: 'مرتجع',
  to_be_refunded: 'لكي يتم إسترجاعها',
  out_of: 'خارج من',
  enter_a_printer_name: 'أدخل إسم الطابعة',
  cart_is_empty: 'عربة المشتريات فارغة',
  searching_printers: 'جاري البحث عن طابعات ',
  no_printers_found: 'لم يتم العثور على طابعات. إذا كنت تبحث عن طابعة بلوتوث ، فيرجى التأكد من تشغيل بلوتوث في إعدادات الجهاز',
  total_due: 'إجمالي المستحق',
  payment_not_successful: 'عملية دفع غير صحيحة',
  error_processing_payment: 'خطأ في عملية الدفع',
  purchase: 'يشتري',
  transaction_aborted: 'تم الغاء العملية',
  authorization_error: 'خطأ في التفويض',
  authorization_ok: 'تفويض صحيح',
  transaction_cancelled: 'تم الغاء العملية',
  clearance_error: 'خطأ في التصريح من المعالج',
  clearance_ok: 'تصريح صحيح',
  transaction_declined: 'تم الغاء العملية',
  transaction_complete: 'تمت العملية',
  initiating: 'إعداد',
  application_selected: 'تم إختيار التطبيق',
  scheduled_for_clearance: 'مجدولة للتخليص خارج الخط بواسطة المعالج',
  terminal_error: 'خطأ في ماكينة الصرف الألي',
  unknown_status: 'وضع مجهول',
  waiting_for_clearance: 'في انتظار التصريح بواسطة المعالج',
  amount_exceeded: 'تم تجاوز المبلغ',
  authorization_timeout: 'انتهاء وقت التفويض',
  batch_processing_failed: 'خطأ في تجهيز الحزمة',
  cancelled: 'تم الإلغاء',
  card_blocked: 'تم حظر البطاقة',
  card_invalid: 'بطاقة غير مقبول',
  card_declined: 'بطاقة مرفوضة',
  transaction_failed: 'فشل في التحويل',
  insufficient_funds: 'نقود غير كافية',
  terminal_not_paired: 'ماكينة الصرف الألي غير مقترنة',
  pin_incorrect_last_retry: 'كلمة سر غير صحيحة - أخر محاولة',
  pin_incorrect_retry: 'كلمة سر غير صحيحة - أعد المحاولة',
  printer_busy: 'الطابعة مشغولة',
  printer_error: 'خطأ في الطابعة',
  printer_not_present: 'طابعة غير موجودة',
  printer_out_of_paper: 'الطابعة خالية من الأوراق ',
  success: 'نجاح',
  system_error: 'خطأ في النظام',
  transaction_not_found: 'عملية غير موجودة',
  unknown_device: 'جهاز مجهول',
  apply_discount: 'طبق الخصم',
  seqr_id: 'هوية تاجر اس أي كيو ار',
  seqr_password: 'كلمة سر تاجر اس أي كيو ار',
  bitpay_api_token: 'بيت باي ايه بي أي توكن',
  registration_successful: 'تم تسجيل إشتراك ماكينة الصرف الألي بنجاح',
  unregistration_successful: 'تم الغاء تسجيل ماكينة الصرف الألي بنجاح',
  payment_init_not_successful: 'الدفع غير مهيأ - تحقق من اتصال الشبكة.',
  always_show_addons: 'أظهر الإضافات دائما',
  show_product_image: 'أظهر صورة المنتج',
  settings_connected_printers: 'إعدادات توصيل طابعة جديدة',
  settings_add_new_printer: 'إعدادات إضافة طابعة جديدة',
  wifi_lan: 'واي فاي أو شبكة داخلية ',
  bluetooth: 'بلوتوث',
  usb: 'يو إس بي',
  paired_barcode_scanners: 'ماسحات الباركود مقترنة',
  account: 'حساب',
  refresh_products: 'تحديث المنتجات',
  server_synchronisation: 'مزامنة السيرفر',
  sales: 'مبيعات',
  today: 'اليوم',
  refunds: 'مرتجعات',
  product: 'منتجات',
  product_by_category: 'منتجات حسب القسم ',
  see_categories_details: 'أنظر تفاصيل الأقسام',
  open_orders_finish_payment_or_empty: 'طلبات مفتوحة - إنهاء العملية أو الإلغاء ',
  name: 'إسم',
  loading_products: 'تحميل المنتجات',
  you_should_select_at_least_one_item: 'يجب إختيار عتصر واحد علي الأقل',
  select_card_provider: 'إختر مزود البطاقة',
  user_cancelled: 'تم إلغاء العميل لعملية الدفع',
  card_payment: 'عملية دفع بالبطاقة',
  manage_products: 'إدارة المنتجات',
  edit_mode: 'مكتب خلفي',
  lock: 'قفل',
  loyalty: 'عميل دائم',
  account_card: 'بطاقة حساب',
  account_lookup: 'قفل حساب',
  Activate: 'تفعيل',
  add_anonymous_customer: 'إضافة عميل مجهول',
  add_customer: 'إضافة عميل',
  add_customer_to_cart: 'إضافة عميل لعربة المشتريات',
  add_new_account: 'إضافة حساب جديد',
  add_new_customer: 'إضافة عميل جديد',
  add_note_hint: 'إضافة ملحوظة تلميح',
  add_tax_to_price: 'إضافة ضريبة إلي السعر',
  add_tip: 'إضافة إكرامية',
  add_to_store: 'إضافة إلي المتجر',
  addons: 'إضافات',
  admin_pin: 'كلمة سر ال',
  admin_pin_saved: 'تم حفظ كلمة سر  المستخدم المسئول',
  admin_pin_should_be: 'كلمة سر المستخدم المسئول يجب أن ',
  aid: 'مساعدة',
  aidCard: 'بطاقة مساعدة',
  allow_cart_discounts: 'السماح بخصم علي عربة المشتريات',
  allow_custom_cart_discounts: 'السماح بخصم علي عربة المشتريات',
  allow_custom_items: 'السماح بعناصر محددة',
  allow_product_discounts: 'السماح بخصم علي المنتجات',
  allow_custom_product_discounts: 'السماح بتخفيضات مخصصة على المنتجات',
  amount_hint: '999',
  app_version: 'إصدار التطبيق',
  applicationLabel: 'علامة التطبيق',
  applicationName: 'إسم التطبيق',
  applicationPreferredName: 'إسم التطبيق المفضل',
  appName: 'إسم التطبيق',
  ask_tips: 'تفعيل الإكراميات',
  authorizationCode: 'كود التفويض',
  available_points: 'النقاط المتاحة',
  available_rewards: 'الجوائز المتاحة',
  back: 'الخلف',
  barcode_scanner_disconnected: 'تم فصل ماسح ضوئي الباركود',
  barcode_scanner_not_found: 'لم يتم العثور علي ماسح ضوئي الباركود',
  barcode_scanner_not_found_desc: 'لم يتم العثور علي ماسح ضوئي الباركود - مفصول',
  bunq: 'بانك',
  bunq_admin_approval: 'سيقوم مسؤول بانك بمراجعة والموافقة على ماكينة تسجيل المدفوعات النقدية الخاصة بك قبل أن يمكن استخدامها.',
  bunq_instructions: '1) افتح تطبيق بانك وأدخل رقم التعريف الشخصي (إذا لزم الأمر) \n2) اختر "مسح رمز الاستجابة السريعة"  من الشاشة الرئيسية \n3) امسح الرمز الذي يظهر على الشاشة ضوئيًا \n4) أضف إكرامية إذا رغبت في ذلك \n5) تأكيد الدفع',
  can_only_refund_entire_amount: 'يسمح فقط بمرتجع كامل المبلغ',
  cancel: 'الغاء',
  cancel_payment: 'الغاء عملية الدفع',
  cannot_pay_later_more_than_remaining_value: 'غير مسموح بالدفع أكثر من المبلغ المتبقي ',
  cannot_refund_transaction_with_partial_postponed_payments: 'غير مسموح بمرتجع لعملىة دفع متبقي منها مبلغ أجل',
  cant_find_products: 'لم يتم العثور علي منتجات',
  card_scheme: 'مخطط البطاقة',
  cardBrand: 'ماركة البطاقة',
  cardExpiryMonth: 'شهر انتهاء صلاحية البطاقة',
  cardExpiryYear: 'عام انتهاء صلاحية البطاقة',
  cardType: 'نوع البطاقة',
  cart_is_frozen_finish_payment: 'تم تجميد عربة المشريات - انهاء عملية الدفع',
  cash_difference: 'فرق النقود',
  cash_drawer: 'درج النقود ',
  cash_in: 'تلقي النقود',
  cash_in_out: 'تلقي - دفع النقود',
  cash_out: 'دفع نقود',
  change: 'تغيير',
  change_currency: 'تغيير عملة',
  change_given: 'المتبقي المدفوع',
  change_open_orders_amount: 'تغيير - فتح قيمة الطلبات',
  check_location_settings: 'فحص إعدادات الموقع',
  check_network: 'فحص الشبكة',
  choose_category: 'إختر القسم',
  choose_mobile_provider: 'إختر مزود الجوال',
  clear_settings: 'إعدادات المسح',
  clear_settings_successful: 'تم مسح الإعدادات بنجاح',
  clear_settings_warning: 'سيؤدي مسح هذه الإعدادات إلى إعادة تعيين معلومات الاتصال الخاصة بك. انقر فوق تأكيد للمتابعة.',
  client_ticket: 'تذكرة العميل',
  close_shift: 'إنهاء الوردية',
  company_address: 'عنوان الشركة',
  company_registration_no: 'رقم تسجيل الشركة',
  completed_payments: 'عمليات مكتملة',
  confirm: 'تأكيد',
  confirm_cash_payment: 'تأكيد عملية الدفع النقدي',
  confirm_new_currency: 'تأكيد عملة جديدة',
  confirm_open_orders_amount_change: 'تأكيد تغيير مبلغ الطلب',
  confirm_payment: 'تأكيد عملية الدفع',
  confirm_with_change: 'تأكيد علي المتبقي',
  confirm_with_tip: 'تأكيد علي الإكرامية',
  connect_barcode: 'وصل الماسح الضوئي الباركود',
  connect_to_webshop: 'الإتصال إلي شبكة المحل',
  connected_printer: 'طابعة متصلة ',
  contact_information: 'معلومات الإتصال',
  contact_us: 'إتصل بنا support@countrhq.com',
  create_product: 'إنشاء منتج',
  currently_trial_mode_transaction_wiped_visit_dashboard: 'نقطة البيع حاليا تحت الإختبار - سوف يتم مسح تحويلاتك كل 4 ساعات',
  currently_trial_mode_visit_dashboard: 'نقطة البيع حاليا تحت الإختبار - يمكنك الإنتقال إلي النظام المباشر عن طريق لوحة التحكم ',
  customer: 'عميل',
  customer_card: 'بطاقة عميل',
  customer_info: 'بيانات العميل',
  customer_instructions: 'تعليمات العميل',
  customer_lookup: 'البحث عن عميل',
  customer_lookup_error: 'خطأ في السيرفر في البحث عن عميل',
  customer_name: 'إسم العميل',
  customer_not_valid: 'خطأ في العميل',
  customer_receipt: 'إيصال العميل',
  customer_since: 'عميل منذ',
  Date: 'تاريخ',
  deregister_terminal: 'إلغاء تسجيل ماكينة الصرف الألي',
  description_mandatory: 'بيان الزامي في عمليات استلام ودفع النقدية',
  device_locked: 'تم قفل الجهاز',
  device_locked_enter_pin: 'تم قفل الجهاز - أدخل كلمة السر',
  did_cheque_refund_succeed: 'هل نجحت عملية مرتجع الشيك إلي العميل ؟',
  did_mobile_payment_succeed: 'هل نجحت عملية الدفع بالجوال ؟',
  did_mobile_refund_succeed: 'هل نجحت عملية المرتجع للجوال ؟',
  direct_connection: 'متصل مباشرة',
  disabled_product: 'منتج غير مفعل',
  discount_currency: 'عملة الخصم',
  do_you_want_remove_payment: 'هل ترغب في مسح عملية البيع',
  download_adyen_app: 'حمل تطبيق أدين',
  download_izettle_app: 'حمل تطبيق إيزتل',
  download_payleven_app: 'حمل تطبيق بايليفين',
  download_sumup_app: 'حمل تطبيق سام اب',
  download_yodo_app: 'حمل تطبيق يودو',
  downloading_categories: 'جاري تحميل الأقسام',
  downloading_taxes: 'جاري تحميل الضرائب',
  downloading_transactions: 'جاري تحميل التحويلات',
  ecommerce_none: 'سوف أقوم بإنشاء المنتجات  بنفسي',
  ecrId: 'ecrId',
  eft_transaction_code: 'EFTTxnID',
  email_invoice: 'إرسال الفاتورة بالبريد إلكتروني',
  employee_name: 'موظف',
  employee_pin: 'رقم سر الموظف',
  empty_cart: 'عربة مشتريات فارغة',
  enable_staff_pin: 'تفعيل تسجيل دخول الموظفين بالرقم السري',
  enter_a_valid_search_value: 'أدخل قيمة صحيحة في خانة البحث',
  enter_api_key: 'أدخل مفتاح التفعيل',
  enter_base_url: 'أدخل اسم الموقع مبدوءا ب ( "http://")',
  enter_merchant_auth_code_payconiq: 'أدخل عنوان تفويض بايكونيك',
  enter_merchant_iban_payconiq: 'أدخل رقم تسجيل الأيبان للبايكونيك',
  enter_merchant_id_payconiq: 'أدخل هوية تاجر البايكونيك',
  enter_order_name: 'أدخل إسم عربة المشتريات',
  enter_reseller_id: 'أدخل إسم البائع',
  enter_user_number: 'أدخل إسم المستخدم',
  enter_user_password: 'أدخل كلمة سر المستخدم',
  error_adding_device: 'حدث خطأ أثناء تسجيل إشتراك جهازك',
  error_refreshing_merchant: 'خطأ أثناء استرداد معلومات الحساب.',
  error_retrieve_categories: 'حدث خطأ أثناء استرداد الفئات. الرجاء التحقق من اتصالك بالإنترنت والمحاولة مرة أخرى',
  error_retrieve_products: 'حدث خطأ أثناء استرداد المنتجات.  الرجاء التحقق من اتصالك بالإنترنت والمحاولة مرة أخرى',
  error_retrieve_taxes: 'حدث خطأ أثناء استرداد الضرائب.  الرجاء التحقق من اتصالك بالإنترنت والمحاولة مرة أخرى',
  error_while_retrieving_results: 'خطأ في إسترداد النتائج',
  errorCode: 'كود الخطأ',
  errorMessage: 'رسالة الخطأ',
  ersReference: 'بيانات المرجع الخارجي',
  excess_amount: 'المبلغ زائد',
  expected_amount: 'المبلغ المتوقع',
  expected_change_gratuity: 'البقشيش أو الإكرامية المتوقعة',
  external_url: 'موقع الإنترنت الخارجي',
  failed_cancel: 'خطأ في إلغاء التحويل',
  feature_only_android: 'هذه الخاصية متاحة فقط للأندرويد',
  find_terminal: 'البحث عن ماكينات الصرف الألي',
  for_security_enter_password: 'لأسباب أمنية قم بإدخال كلمة السر مرة أخري',
  for_this_device: 'لهذا الجهاز فقط',
  for_this_store: 'لهذا المتجر فقط',
  four_inch: '4 إنش',
  generate_keys: 'أداة إنشاء المفاتيح',
  generating_keypair: 'جاري إنشاء مقارنة المفتاح - سوف يستغرق عدة دقائق',
  generating_reports: 'إنشاء التقرير',
  generic_usb: 'يو إس بي جينريك',
  get_your_free_pos: 'إحصل علي نقطة الصرف الخاصة بك من خلال www.countrhq.com',
  gift_code: 'كود الهدية',
  gratuity_amount: 'مبلغ الإكرامية',
  gratuity_percentage: 'نسبة الإكرامية',
  group_items_in_cart: 'قسم العناصر إلي مجموعات في عربة المشتريات',
  shared_secret: 'سر مشترك ',
  impossible_refresh_you_have_transactions: 'لديك  معاملة (معاملات) لم تتم مزامنتها مع الخادم.  ستتم الآن محاولة مزامنة هذه المعاملات في الخلفية ...',
  impossible_signout_you_have_transactions: 'من المستحيل تسجيل الخروج. لديك معاملة (معاملات) لم تتم مزامنتها مع الخادم. ستتم الآن محاولة مزامنة هذه المعاملات في الخلفية ...',
  insert_printer_name: 'رجاء قم بإدخال 3 حروف أو أكثر من إسم الطابعة',
  inserted_login_not_valid: 'تسجيل دخول غير صحيح',
  inserted_value_not_valid: 'القيمة التي قمت بإدخالها غير صحيحة',
  installmentAmount: 'مبلغ القسط',
  invalid_credit_card_number: 'رقم بطاقة المشتريات يجب أن يتكون من 16 رقم',
  invoiceReference: 'مرجع الفاتورة',
  invoiceStatus: 'وضع الفاتورة',
  ip_address: 'العنوان',
  is_business: 'بيانات العمل',
  is_correct_amount_tendered: 'هل (مبلغ) مبلغ صحيح؟',
  isDone: 'لا يوجد المزيد للتحميل',
  isError: 'إضغط للمحاولة مرة أخري',
  isTap: 'إضغط لتحميل المزيد',
  join_thousands_of_merchants: 'الألاف من التجار قاموا بالإنضمام إلي الكاونتر ، اليوم جاء الوقت عليك . سجل الأن مجانا ',
  language: 'لغة',
  last_visit: 'أخر زيارة',
  leave_tour: 'يغادر الجولة',
  leaving_tour: 'مغادرة الجولة',
  loyal_customer: 'عميل وفي',
  mac_address: 'عنوان التحكم بالوسائط',
  mailing_list_permission: 'أضف إلى القائمة البريدية',
  manual_mobile_payment: 'عملية دفع بالجوال',
  manual_mobile_refund: 'عملية مرتجع بالجوال',
  max_amount_exceeded: 'تجاوز المبلغ الحد الأقصى المسموح به لطريقة الدفع هذه',
  menu_item_12char: 'إسم قائمة العنصر لا يجب ألا تزيد عن 12 حرف ',
  menu_item_name: 'إسم قائمة العنصر ( لا تزيد عن 12 حرف) ',
  merchant_receipt: 'إيصال التاجر',
  merchantDisplayName: 'إسم شاشة التاجر',
  merchantId: 'هوية التاجر',
  mid: 'ام أي دي',
  mobile_number: 'رقم الجوال',
  mobile_payment: 'جوال',
  mobile_payments: 'عمليات دفع بالجوال',
  new_price: 'سعر جديد (عملة)',
  no_available_addons_for_product: 'لا توجد إضافات متاحة للمنتج الذي قمت بإختياره',
  no_connected_printer: 'لا يوجد طابعات متصلة ',
  no_connected_terminal: 'لا يوجد ماكينة صرف ألي متصلة',
  no_customer_found: 'لم يتم العثور علي عميل',
  no_customer_selected: 'لم يتم إختيار عميل',
  no_printer_configured: 'لم يتم عمل الإعدادات للطابعة',
  no_printer_found: 'لم يتم العثور علي طابعة ، إضغط هنا إذا قمت بتوصيل الطابعة عن طريق يو إس بي',
  no_product_in_cart: 'لا يوجد منتجات في عربة المشتريات ',
  no_terminals_found: 'لم يتم العثور علي ماكينة صرف ألي',
  no_url_specified: 'خطأ ، لم يتم تحديد الموقع الإلكتروني',
  none: 'لا شيء',
  note_mandatory_cash_difference: 'ملاحظة إلزامية إذا كان هناك فرق في المبالغ المتوقعة والفعلية',
  note_optional: 'ملحوظة (إختياري)',
  numberOfInstallments: 'عدد الأقساط',
  numpad: 'لوحة رقمية',
  offline: 'غير متصل',
  ok: 'موافق',
  one_or_more_carts_are_frozen_finish_payment: 'تم تجميد عربة واحدة أو أكثر. قم بإنهاء السداد قبل القيام بأي إجراء.',
  online: 'متصل',
  open_market: 'هل ترغب في فتح السوق؟',
  open_register_before_transaction: 'يجب أن تفتح السجل قبل عمل أي تحويلات',
  open_shift: 'إفتح السجل',
  order_printer: 'طابعة عربة المشتريات',
  orderId: 'طلب الهوية',
  orderRef: 'طلب المرجع',
  other_text: 'نص أخر',
  paid: 'مدفوع',
  paid_so_far: 'مدفوع حتي الأن',
  paid_with_method: 'تم الدفع بواسطة',
  pan: 'إخفاء',
  panLastDigits: 'أخر أرقام بان',
  panMasked: 'إخفاء رقم البطاقة',
  PANSeq: 'إخفاء مسلسل البطاقة',
  partial_payment: 'عملية دفع تجزيئية',
  partial_payment_not_allowed_for: 'المدفوعات الجزئية غير مسموح بها',
  partial_payment_paid_successfully: 'تم دفع الدفعة الجزئية بنجاح',
  pay_later: 'إدفع لاحقا',
  pay_later_allows_only_refund_all: 'مع الدفع لاحقًا ، يمكنك فقط استرداد الكل. بالنسبة للمبالغ المستردة الجزئية ، يرجى إنهاء الدفع.',
  pay_later_refund: 'إلغاء الدفع لاحقا',
  pay_on_account: 'إدفع علي الحساب',
  payconiq: 'بايكونيك',
  paying_now: 'الدفع الأن',
  payment_cancelled: 'تم إلغاء عملية الدفع',
  payment_error: 'خطأ في عملية الدفع',
  payment_info: 'بيانات عملية الدفع',
  payment_insert_card: 'أدخل البطاقة',
  payment_method_not_configured: 'طريقة الدفع ',
  payment_number: 'رقم عملية الدفع',
  payment_processor_options: 'إختيارات معالج عملية الدفع',
  payment_provider: 'مزود عملية الدفع',
  payment_success: 'عملية الدفع تمت بنجاح',
  payment_terms_in_net_days: 'شروط عملية الدفع ( صافي الأيام)',
  personal_information: 'معلومات شخصية',
  pickup_rewards: 'إختر الجوائز',
  pin: 'رقم السر',
  please_insert_a_valid_amount: 'من فضلك أدخل مبلغ صحيح',
  posEntryMode: 'وضع إدخال نقطة البيع',
  prefName: 'الإسم المفضل',
  print: 'إطبع',
  print_also_product_details: 'هل تريد أيضا طباعة بيانات المنتج؟',
  print_error: 'خطأ في الطباعة',
  print_product_details: 'إطبع بيانات المنتج',
  print_successful: 'تمت الطباعة بنجاح',
  printer_connect_desc: 'واي فاي الطابعة يجب توصيله علي نفس الشبكة مثل هذا الجهاز',
  printer_removed: 'تم حذف الطابعة',
  printer_test: 'إختبار طابعة',
  printing_report: 'أنشاء وطباعة تقرير',
  proceed_with_next_payment: 'إستكمال عملية الدفع التالية',
  processorName: 'إسم المعالج',
  product_is_disable_continue_anyway: 'المنتج الذي حددته غير متوفر (ربما نفد من المخزون). هل تريد إضافته إلى عربة التسوق على أي حال؟',
  product_sync: 'مزامنة المنتج',
  profile_average: 'معدل الشراء',
  protected_sections: 'الأقسام المحمية',
  receipt_printer: 'إيصال طابعة',
  redeem_reward: 'إستبدال الجوائز',
  referenceNumber: 'رقم المرجع',
  refresh_customers: 'أعد تنشيط قائمة العميل',
  refresh_merchant: 'أعد تنشيط معلومات الحساب',
  refresh_transactions: 'أعد تنشيط التحويلات',
  refreshing_merchant: 'إعادة تنشيط معلومات الحساب',
  refund_number: 'رقم المرتجع',
  refunds_not_available_for_provider: 'المرتجع غير متاح للمزود (مزود عملية الدفع)',
  register_terminal: 'تسجيل ماكينة صرف ألي',
  reissue: 'أعد إصدار',
  remaining_payment: 'عمليات الدفع المتبقية',
  remove_customer_from_cart: 'حذف من عربة المشتريات',
  remove_payment: 'حذف عملية الدفع',
  remove_printer: 'حذف الطابعة',
  removing_products: 'حذف المنتجات القديمة ',
  reopen_shift: 'إعادة فتح السجل',
  replace_customer: 'إستبدال عميل',
  report_by_shift: 'تمكين الفتح / الغلق اليومي ',
  report_printer: 'أبلغ عن الطابعة ',
  requested_amount: 'المبلغ المطلوب',
  reward: 'جوائز',
  round_cash_transactions: 'تقريب عمليات دفع نقدي إلي أقرب 0.05',
  save_printer: 'حفظ الطابعة',
  scan: 'مسح',
  scan_barcode_instruction_one: 'امسح الباركود لوضع قابس البلوتوث في وضع المزامنة',
  scan_barcode_instruction_two: 'في إعدادات الجهاز تأكد من أن البلوتوث في وضع التشغيل ثم قم بعمل إقتران لقابس الماسح ضوئي ',
  scan_code_not_exist: 'لم يتم العثور علي كود الماسح ضوئي',
  scan_qr_code: 'قم بعمل مسح لكود الإستجابة السريعة عن طريق تطبيق الدفع',
  scanner: 'ماسح ضوئي',
  scanner_connected: 'تم توصيل ماسح ضوئي الباركود',
  scanner_disconnected: 'تم فصل ماسح ضوئي الباركود',
  scanner_pair_completed: 'تم إنهاء الإقتران لماسح ضوئي الباركود',
  search_for_customer: 'ابحث عن عملاء',
  search_report: 'ابحث عن تقارير',
  search_transaction: 'ابحث عن تحويلات',
  searching_terminals: 'ابحث عن ماكينات صرف ألي',
  security_and_permissions: 'الأمان والتراخيص',
  select_one_payment_method: 'يجب تفعيل وسيلة دفع واحدة علي الأقل',
  select_payment_method: 'من فضلك إختر بطاقة المساعدة من القائمة الموجودة في الأسفل',
  select_terminal: 'إختر ماكينة صرف ألي',
  send_email: 'أرسل بريد إلكتروني',
  send_to_another_customer: 'أرسل إلي عميل أخر',
  send_to_current_customer: 'أرسل إلي عميل حالي',
  send_to_customer: 'أرسل إلي عميل',
  sending_receipt: 'جاري إرسال الإيصال',
  server_error: 'خطأ في السيرفر',
  session: 'جلسة',
  set_new_price: 'سعر جديد',
  setting_saved: 'تم حفظ الإعدادات',
  settings_connected_terminals: 'ماكينة صرف ألي متصلة',
  settings_printer_available_printers: 'طابعات متاحة',
  settlementDate: 'تاريخ التسوية',
  setup_admin_pin_in_settings: 'قم بضبط رقم سر المستخدم المسئول في الإعدات تحت خانة الحساب ',
  shift_closed: 'تم غلق السجل',
  shift_opened: 'تم فتح السجل',
  shifts: 'ورديات',
  shopper_reference: 'مرجع المتسوق',
  show_customer: 'وضح بيانات العميل في الإيصال',
  show_employee: 'وضح إسم الموظف في الإيصال',
  show_customer_payconiq: 'من فضلك إختر عميل مسجل دخول أو  أمسح الباركود الخاص به',
  show_new_product_tile: 'أظهر قائمة (إنشاء منتج) ',
  show_password: 'أظهر كلمة السر',
  sign_out_header: 'هل أنت متأكد؟',
  sign_out_warning: 'قد يؤدي تسجيل الخروج إلى إعادة تعيين إعدادات التطبيق ، بما في ذلك موفرو الدفع والطابعات. يرجى استخدام وظيفة القفل (مع المسؤول أو رقم التعريف الشخصي للموظف) إذا كنت لا ترغب في مسح الإعدادات الخاصة بك.',
  sign_up_to_print: 'قم بالتسجيل للحصول على حساب تجريبي مجاني لطباعة إيصال. لا حاجة لبطاقة ائتمان ، قم بالإلغاء في أي وقت.',
  sign_up_to_send_email: 'قم بالتسجيل للحصول على حساب تجريبي مجاني لإرسال إيصال بالبريد الإلكتروني. لا حاجة لبطاقة ائتمان ، قم بالإلغاء في أي وقت.',
  signing_out: 'تسجيل خروج',
  signup_for_mailing_list: 'إسأل العملاء أن يقوموا بالإشتراك في القائمة البريدية',
  sorry_report_not_available: 'تقرير غير متاح',
  start_using_pos: 'إبدأ البيع بالكونتر',
  state: 'مدينة / محافظة',
  status: 'وضع ',
  store_powered_by: 'هذا المتجر يتم تشغيله بواسطة',
  street: 'شارع',
  suggested_amount: 'المبلغ المقترح',
  suggested_tip: 'الإكرامية المقترحة',
  support: 'دعم',
  telephone: 'هاتف',
  terminal_port: 'تم إعداد المنفذ لماكينة الصرف الألي',
  terminal_selected: 'تم حفظ ماكينة الصرف الألي',
  terminalId: 'هوية ماكينة الصرف الألي',
  test: 'هذا إختبار',
  three_inch: '3 إنش',
  tid: 'تي أي دي',
  Time: 'وقت',
  tip: 'إكرامية',
  tip_added: 'تم إضافة الإكرامية',
  to_pay: 'يدفع',
  total_amount: 'إجمالي المبلغ',
  transaction_code: 'كود العملية',
  transaction_date: 'تاريخ العملية',
  transaction_finalized: 'تم الإنتهاء من العملية',
  transaction_pending: 'العملية قيد الإنتظار',
  transaction_started: 'تم البدء في العملية',
  transaction_succeeded: 'تمت العملية بنجاح',
  transaction_time: 'وقت العملية',
  transaction_timeout: 'لقد نفذ وقت العملية',
  transactionSeq: 'مسلسل العملية',
  transactionType: 'نوع العملية',
  try_it_now: 'جرب الأن',
  tsi: 'تي اس أي',
  tvr: 'تي في ار',
  two_inch: '2 إنش',
  uncategorized: 'لا يوجد قسم',
  valid_phone_number: 'رقم هاتف صحيح',
  validating_fields: 'جاري توثيق الحقول',
  validation_in_progress: 'التوثيق مستمر',
  verification_code: 'كود التوثيق',
  verification_method: 'وسيلة التوثيق',
  waiting_for_customer: 'جاري إنتظار عميل',
  want_to_sync_products: 'هل ترغب في مزامنة منتجاتك؟',
  yodopay: 'يودا باي',
  you_must_select_a_store: 'يجب عليك إختيار متجر',
  add_name_device: 'أضف اسمًا إلى جهازك اللوحي / هاتفك للتعرف عليه بسهولة لاحقًا (إلزامي)',
  card_number: 'رقم البطاقة',
  change_language: 'تغيير اللغة',
  choose_currency: 'إختر لغة أولية',
  clear_cart_after_finish: 'إعادة تهيئة البطاقة',
  confirm_new_language: 'تأكيد إختيار لغة جديدة ؟ ، سوف تحتاج إلي غلق التطبيق وتشغيله مرة أخري لتفعيل التغييرات الجديدة',
  customer_signature: 'توقيع العميل',
  day: 'يوم',
  device_name_mandatory: 'إسم الجهاز إلزامي',
  downloading_refunds: 'تحميل المرتجعات',
  email_report: 'تقرير البريد الإلكتروني',
  email_successfully_sent_test: 'إختبار إرسال بريد إلكتروني إلي حسابك',
  enter_table_name: 'أدخل إسم الجدول',
  error: 'خطأ',
  error_retrieve_refunds: 'خطأ أثناء إسترداد المرتجعات',
  error_retrieve_transactions: 'خطأ أثناء إسترداد المعاملات',
  for_this_device_see_dashboard: 'لهذا الجهاز فقط ، إذهب إلي لوحة التحكم لتقارير أخري  ',
  hide_new_sale: 'أخفي \"بيع جديد\" شاشة بعد عملية الدفع',
  last_transaction_result: 'أحدث نتيجة معاملة ',
  merge_warning: 'هذا سوف يؤدي إلي دمج محتوي هذع العربات / الجداول',
  month: 'شهر',
  post_code: 'رقم بريدي',
  print_order: 'أظهر إرسال إلي طلب الطابعة',
  products_added_removed_refresh: 'تمت إضافة أو حذف المنتجات - أعد تنشيط القائمة؟',
  quarter: 'ربع ',
  refresh: 'أعد تنشيط',
  refreshing_products: 'إعادة تنشيط المنتجات',
  removing_categories: 'حذف الأقسام القديمة ',
  removing_taxes: 'حذف الضرائب القديمة',
  select_interface_mandatory: 'إختيار واجهة مستخدم إلزامي',
  sms_successfully_sent_test: 'تم إرسال رسالة نصية إلي حسابك',
  splitOrderPrintByCategory: 'فصل طباعة الطلب حسب القسم',
  stored_digitally: 'تم التخزين رقميا',
  stripe_id: 'شريط الهوية',
  sync_support_info: 'مزامنة معلومات الدعم',
  syncing_support_info: 'مزامنة معلومات الدعم',
  table: 'جدول',
  table_is_empty: 'الجدول فارغ',
  tables_or_carts: 'فعل ميزات الضيافة ',
  uncertain_card_payment_success: 'نجاح عملية الدفع بالبطاقة غير مؤكد',
  unknown_category: 'قسم غير معروف',
  unsynced_transactions_report_incorrect: 'رقم العملية أو المعاملات غير متزامن ، التقارير لم يتم تحديثها ',
  update_terminal_firmware: 'تحديث برنامج ماكينة الصرف الألي',
  week: 'أسبوع',
  year: 'سنة',
  email_queued: 'البريد الإلكتروني قيد الإنتظار',
  order_received: 'جديد / تم إستلام تحديث الطلب',
  go_to_orders: 'أعرض الطلبات',
  open_carts: 'إفتح عربات الشراء',
  auto_print_order: 'إطبع الطلبات قيد الدفع الغير مطبوعة',
  auto_print_order_cart: 'إطبع الطلبات الغير مطبوعة في عربة الشراء / تغيير الجدول',
  external: 'أخري',
  no_printer_found_tcp: 'لم يتم العثور علي طابعة ، إضغط هنا لإدخال معلومات الطابعة يدويا',
  downloading_carts: 'تحميل عربات الشراء',
  pay_later_hybrid: 'إدفع لاحقا',
  error_retrieve_carts: 'خطأ في إسترداد عربات الشراء',
  no_ip_address: 'لم يوجد عنوان',
  building_number: 'رقم العقار',
  confirm_manual_payment: 'تأكيد عملية الدفع اليدوي',
  shipping_address: 'عنوان الشحن',
  birth_date: 'تاريخ الميلاد',
  for_this_store_see_dashboard: 'أنظر لوحة التحكم الخاصة بهذا المتجر فقط لمزيد من التقارير',
  customers: 'عملاء',
  tickets_in: 'تذاكر دخول',
  tickets_out: 'تذاكر خروج',
  tickets_in_out: 'تذاكر دخول / خروج',
  unsynced_transaction_cant_refund: 'لم يتم مزامنة العملية بعد مع السيرفر  ، لا يمكن عمل مرتجع',
  show_stock_level: 'إعرض مستوي المخزون الحالي (لو أمكن)',
  available: 'في المخزون',
  stock_not_available: 'لم يتم تتبع المخزون',
  order_another_store: 'أطلب من موقع أخر',
  order_products: 'تفعيل طلب المنتجات من مواقع أخري',
  trial_expired: 'تم إنتهاء الفترة التجريبية الخاصة بك ، تم إبطال وظائف نقطة البيع',
  left_menu: 'شريط التنقل الأيسر',
  right_menu: 'شريط التنقل الأيمن',
  direct_pay_if_one_method: 'تشغيل أتوماتيكي لعملية الدفع إذا تم تحديد الوسيلة 1 ',
  processing_vouchers: 'جاري تنفيذ عربة مشتريات وقسائم العميل الدائم',
  display_2_rows_of_categories: 'أعرض 2 صف في الأقسام',
  unknown_barcodes_as_vouchers: 'معاملة أجهزة الباركود الغير معروفة مثل القسائم ',
  validate_signature: 'توقيع العميل صحيح؟',
  group_items_in_receipt_and_cart: 'تجميع نفس العناصر في عربة المشتريات والإيصالات',
  ask_customer_subscribe_email: 'إسأل العميل أن يزود البريد الإلكتروني ',
  hide_categories: 'إخفاء فئات الأقسام',
  hide_all_products_category: 'أخفي فئة قسم جميع المنتجات ',
  provisional_receipt: '***مشروط***',
  reprint: '***أعد طباعة***',
  block_reprints: 'لا تسمح بإعادة طباعة العناصر',
  reports_at_store_level: 'أظهر تقارير المتجر وليس الجهاز',
  max_addon_qty_exceeded: 'أقصي  كمية للإضافات مسموح بها لهذه المجموعة ',
  min_addon_qty_unmet: 'أقل كمية للإضافات مطلوبة من  (إسم الجروب)',
  press_button_to_wake_terminal: 'من فضلك إضغط علي الزر الأخضر  لتنشيط رقم سر ماكينة الصرف الألي',
  payments: 'عمليات دفع ',
  average_sale_duration_mins: 'معدل مدة البيع (دقائق)',
  save_order_move_next: 'إحفظ الطلب ، إستمر إلي الجدول الفارغ التالي',
  max_orders_reached: 'تم الوصول إلي أقصي حد من الطلبات المفتوحة',
  show_open_orders: 'أعرض الطلبات المفتوحة في بداية قائمة الطلبات',
  tender_created: 'تم إبتداء العملية',
  invalid_request: 'خطأ في الطلب',
  already_processed: 'تم تنفيذ العملية بالفعل',
  invalid_terminal: 'خطأ في عملية الدفع من ماكينة الصرف الألي',
  network_error: 'خطأ في الشبكة',
  bad_credentials: 'خطأ في بيانات الإعتماد ',
  server_issue: 'لا يمكن الإتصال بالسيرفر',
  pin_entered: 'تم إدخال رقم السر',
  initial: 'جاري التهيئة',
  card_inserted: 'تم إدخال البطاقة',
  card_swiped: 'تم مسح البطاقة',
  wait_app_selection: 'قيد إنتظار إختيار التطبيق ',
  wait_amount_adjustent: 'قيد إنتظار تعديل المبلغ',
  ask_gratuity: 'إسأل عن الإكرامية',
  gratuity_entered: 'تم إدخال الإكرامية',
  ask_dcc: 'إسأل عن دي سي سي',
  dcc_accepted: 'تم قبول دي سي سي ',
  dcc_rejected: 'تم رفض دي سي سي',
  processing_tender: 'جاري تنفيذ المناقصة',
  wait_pin: 'قيد إنتظار كلمة السر',
  provide_card_details: 'تقديم بيانات البطاقة',
  card_details_provided: 'تم تقديم بيانات البطاقة',
  receipt_printed: 'تم طباعة الإيصال',
  referral: 'إحالة',
  referral_checked: 'تمت مراجعة الإحالة',
  check_signature: 'مراجعة التوقيع',
  signature_checked: 'تمت مراجعة التوقيع',
  additional_data_available: 'متاح بيانات إضافية',
  transaction_acknowledged: 'تم تأكيد العملية',
  device_already_registered: 'الجهاز مسجل بالفعل',
  add_in_progress: 'تمت إضافة الجهاز بالفعل',
  app_not_registered: 'التطبيق غير مسجل',
  unknown_error: 'خطأ مجهول',
  timeout: 'لقد نفذ الوقت',
  verify_failed: 'لم يتم التحقق من الجهاز',
  identify_failed: 'لم يتم تحديد هوية الجهاز',
  registration_error: 'خطأ في التسجيل',
  sync_issue: 'لم يتم عمل مزامنة للجهاز',
  show_select_customer: 'أظهر محدد العميل',
  store_name_mandatory: 'إسم المتجر إلزامي',
  choose_store_dashboard_for_more: 'إختر متجر ،يمكن إضافة المزيد من خلال لوحة تحكم المكتب الخلفية',
  recovering_incomplete_payment: 'إسترداد المعملات الغير مكتملة - يستغرق هذا 30 ثانية',
  allow_negative_sale: 'السماح بمبلغ بيع سلبي',
  move_current_table_to: 'حرك الجدول الحالي (إسم الجدول) إلي',
  store: 'متجر',
  print_specific_categories: 'إطبع أقسام محددة',
  customer_display_attached: 'مرفق شاشة العميل',
  sale: 'بيع',
  reps_and_waste_button_in_cart: 'أظهر أزرار الحذف والإضافة في عربة المشتريات',
  small_product_tiles: 'تقليل حجم قوائم المنتج',
  large_product_tiles: 'زيادة حجم قوائم المنتج',
  show_product_price_on_tile: 'سعر المنتج علي القائمة',
  printer_roles: 'قواعد الطباعة',
  paper_size: 'حجم الورقة',
  customer_display: 'شاشة العميل',
  enable_realtime_updates: 'تفعيل تحديثات الوقت الحالي من السيرفر',
  show_open_orders_first: 'أظهر الطلبات المفتوحة في بداية قائمة الأوطلب',
  blank_lines_order_ticket: 'سطور فارغة في أعلي تذكرة الطلب',
  blank_lines_bottom_order_ticket: 'سطور فارغة في أسفل تذكرة الطلب',
  scan_card: 'إمسح البطاقة',
  scan_nfc_or_barcode: 'إمسح البطاقة مستخدما الكود أو خاصية الإتصال قريب المدي',
  show_product_keypad: 'إظهر لوحة المفاتيح في عربة المشتريات',
  extra_payment_methods: 'وسائل دفع إضافية',
  failed_recovery: 'فشل في إسترداد عملية الدفع',
  blank_line_between_order_items: 'سطر فارغ بين عناصر تذكرة الطلب',
  look_up_customers_using_barcodes: 'أضف عميل إلي عربة المشتريات بواسطة الماسح ضوئي',
  error_loading_configuration: 'خطأ في تحميل الإعدادات',
  error_verifying_payment_terminal: 'خطأ في التأكد من رقم سر  ماكينة الصرف الألي',
  error_identifying_payment_terminal: 'خطأ في التعرف علي رقم سر ماكينة الصرف الألي',
  error_saving_configuration: 'خطأ في حفظ الإعدادات',
  conflicting_server_modes: ' ماكينة الصرف والسيرفر  لديهم أوضاع مختلفة لكلمة السر ( إختبار  ضد إنتاج)',
  nothing_to_print: 'لا يوجد عناصر متاحة للطباعة',
  large_item_name: 'زيادة حجم العنصر المطبوع',
  print_ean_sku: 'إطبع رمز الترقيم الأوروبي ووحدة إدارة المخزون في الإيصال',
  select_category_on_startup: 'تقسيم طباعة الطلبات حسب العنصر',
  split_tickets_item: 'إختر القسم مرة واحدة في قائمة البداية',
  split_tickets_amount: 'تقسيم طباعة الطلبات حسب الكمية',
  split_print: 'تقسيم الطباعة',
  refreshing_customers: 'إعادة تنشيط العملاء',
  downloading_customers: 'تحميل العملاء',
  error_retrieve_customers: 'خطأ في إسترداد العملاء ، من فضلك تأكد من إتصالك بالإنترنت ثم أعد المحاولة ',
  server_error_please_retry: 'خطأ في السيرفر ، من فضلك أعد المحاولة',
  vibrate_on_click: 'إضغط علي تقييم العنصر',
  keep_screen_on: 'لا تسمح بقفل الشاشة ',
  refund_same_method: 'فرض المبالغ المستردة بطريقة الدفع الأصلية',
  cannot_refund_more_than_paid: 'يمكن فقط استرداد مبلغ {{أقصي مبلغ) كحد أقصى عبر (وسيلة الدفع)',
  tablet_date_incorrect: 'تم تعيين تاريخ الجهاز بشكل غير صحيح - مخاطر أمنية محتملة',
  extra_non_revenue_info: 'إسأل علي معلومات إضافية عن العناصر الغير مرتجعة ',
  enter_extra_info: 'إدخال معلومات إضافية عن العنصر',
  giftcard_or_reference_no: 'بطاقة هدايا أو رقم مرجع',
  inserted_code_not_valid: 'كود غير صحيح',
  validate_user: 'التأكد من المستخدم (عنوان المستخدم)',
  enter_validation_code: 'من فضلك أدخل كود التأكيد الذي تم إستلامه عن طريق رسالة نصية أو بالبريد إلكتروني',
  tax_rate: 'معيار الضريبة',
  pretax: 'صافي',
  deposit: 'إيداع',
  can_not_refund_with_different_provider: 'لا يمكن عمل مرتجع (وسيلة الدفع ) إستخدام معاملات (إختر وسيلة دفع) غير ممكن',
  external_scanner_connected: 'تم توصيل ماسح ضوئي خارجي',
  cancelling: 'إلغاء',
  sumup: 'SumUp',
  ccv: 'رمز التحقق من البطاقة',
  payplaza: 'باي بلازا',
  worldline: 'وورلد لاين',
  adyen: 'أدين',
  rabosmartpin: 'رابو سمارت بن',
  exchange: 'إستبدال',
  ask_exchange: 'السماح بإستبدال المنتج',
  exchange_error: 'خطأ في الإستبدال',
  exchange_items: 'إستبدال العناصر',
  exchangeable: 'قابل للإستبدال',
  to_be_exchanged: 'لكي يتم إستبداله',
  exchange_all: 'إستبدل الكل',
  negative_sale_amount: 'المبلغ الذي سوف يتم دفعه أقل من 0',
  download_rabosmartpin_app: 'حمل تطبيق رابو سمارت بن',
  transaction_disabled: 'غير مسموح بعمليات دفع من الغير في هذا العقد',
  transaction_in_progress: 'عملية دفع أخري مستمرة بالفعل',
  refund_offline: 'الإسترجاع غير ممكن في وضع تقييد المحتوي',
  refund_not_allowed: 'الإسترجاع غير ممكن للمستخدم الذي سجل دخول',
  cash_forbidden: 'عمليات البيع النقدي غير ممكنة في هذا العقد',
  notify_new_online_order: 'أعرض الإشعارات عند إستلام طلب أونلاين جديد',
  print_new_online_order: 'إطبع الطلبات الأونلاين الجديدة أتوماتيكيا بعد إستلامها ',
  delivery_information: 'توصيل المعلومات',
  voucher_already_processed: 'تم تنفيذ القسيمة',
  no_applicable_items_in_cart: 'لا يوجد عناصر قابلة للإستخدام في عربة المشتريات',
  discount_applied: 'تم تطبيق الخصم',
  invalid_voucher: 'خطأ في القسيمة',
  allow_voucher_scan: 'إبحث عن قسائم مستخدما ماسح ضوئي الباركود',
  enable_drop_shipping: 'تفعيل الدروب شيبينج',
  ask_no_covers_or_takeaway: 'إسأل دائما عن إعدادات الجدول',
  table_configuration: 'نوع الجدول',
  num_covers: 'عدد العملاء',
  takeaway: 'تيك أواي',
  guests: 'عملاء',
  show_floorplan_after_sale: 'أظهر مخطط الطابق بعد كل عملية بيع ',
  lock_after_x_minutes: 'أقفل بعد (عدد) دقائق من عدم النشاط',
  show_deposit_items: 'أظهر العناصر التي تم إيداعها',
  pickup: 'يلتقط',
  delivery: 'توصيل المعلومات',
  transaction_fee: 'رسوم العملية',
  print_description: 'إطبع بيان العنصر في الإيصال',
  only_cash_for_negative_amounts: 'يمكن اختيار عمليات الدفع النقدي أو بالبطاقة اليدوي عندما تكون القيمة أقل من 0  ',
  phone_number_example: 'هاتف : مثال 31611111111',
  whatsapp_not_available_on_this_device: 'الواتس اب غير مثبت علي هذا الجهاز',
  receipt_not_yet_synchronised: 'جاري مزامنة المعاملات علي السيرفر ، أعد المحاولة بعد عدة دقائق',
  notify_online_orders_with_sound: 'تشغيل الصوت عند إستلام الطلبات الأونلاين',
  restrict_online_orders_by_floor: 'قبول الطلبات الأونلاين من طوابق محددة',
  remove_product: 'حذف المنتجات ',
  admin_access_needed: 'هذه الخاصية متاحة فقط للمستخدمين الرئيسيين',
  customer_or_employee_name: 'إسم العميل أو الموظف',
  show_cart_on_left: 'أظهر عربة المشتريات من جهة اليسار',
  groupOrderPrintByCategory: 'طباعة مجموعة الأوامر حسب القسم',
  handpoint_shared_secret: 'سر مشترك ',
  fiscal_offline: 'لأسباب تتعلق بالامتثال المالي ، يجب أن تكون نقاط البيع متصلة بالإنترنت لمعالجة المعاملات',
  unsynced_transaction_cant_reissue: 'لم تتم مزامنة العملية مع الخادم حتى الآن - إعادة الإصدار غير ممكنة',
  fiscal_info: 'معلومات مالية',
  create_bundle: 'إنشاء باقة',
  select_items_to_bundle: 'إختيار عناصر للباقة',
  bundle_price: 'سعر الباقة',
  create_bundle_items: 'إنشاء باقة من المنتجات في عربة المشتريات',
  bundle: 'باقة',
  giftcard_or_reference_no_mandatory: 'بطاقة الهدايا- أو الرقم المرجعي إلزامي',
  show_camera: 'استخدم كاميرا الجهاز (إن وجدت) كماسح ضوئي ضوئي',
  switch_camera: 'تبديل الكاميرا',
  camera: 'كاميرا',
  print_qr_on_receipts: 'طباعة رمز الاستجابة السريعة على الإيصالات',
  no_floorplans_available: 'لا توجد مخططات أرضية متاحة',
  receive_online_orders_for_multiple_stores: 'إستلام الطلبات عبر الإنترنت لمتاجر متعددة',
  inventory: 'مخزن',
  description: 'بيان',
  brand: 'ماركة',
  allergens: 'مسببات الحساسية',
  hide_total_num_items_on_order: 'إخفاء العدد الإجمالي للعناصر على تذكرة الطلب',
  loss: 'مفقود',
  stolen: 'مسروق',
  own_use: 'إستخدام خاص',
  giveaway: 'يتخلص من',
  breakage: 'إهلاك ',
  overview: 'نظرة عامة',
  average: 'معدل',
  busiest_hours: 'أكثر الساعات ازدحامًا',
  rotation_time_in_secs: 'وقت دوران الصورة بالثواني',
  show: 'تبين',
  uploaded: 'تم الرفع',
  videos: 'فيديو',
  photos: 'الصور',
  display_video_or_picture: 'عرض الفيديو أو الصور أو ملفات pdf',
  store_logo: 'شعار المتجر',
  add_selected_addon: 'إضافة / إزالة الإضافات',
  add_selected_to_stores: 'إضافة / إزالة إلى المتاجر',
  add_selected_categories: 'إضافة / إزالة الفئات المحددة',
  select_categories: 'حدد الفئات',
  add_selected_addon_group: 'إضافة / إزالة مجموعة أدوات إضافية',
  company: 'الشركة',
  assortment: 'التشكيلة',
  sales_history: 'تاريخ المبيعات',
  employees: 'الموظفون',
  extensions: 'الملحقات',
  desktop: 'سطح المكتب',
  helpdesk: 'مكتب المساعدة',
  specific: 'محدد',
  no_data_for_this_period: 'لا توجد بيانات لهذه الفترة',
  gross_revenue: 'إجمالي الإيرادات',
  avg_per_transaction: 'متوسط العمليات التجارية',
  from: 'من',
  select_period: 'حدد الفترة',
  date_start: 'تاريخ البداية',
  date_end: 'تاريخ النهاية',
  select_store: 'اختيار المحل',
  select_stores: 'اختيار المحلات',
  all_stores: 'كافة المحلات',
  all_employees: 'كل الموظفين',
  all_devices: 'جميع الاجهزة',
  no_transactions_in_this_period: 'لا توجد عمليات تجارية لهذه الفترة',
  transactions_in_this_period: 'العمليات التجارية في هذه الفترة',
  report_graph: 'الرسم البياني للتقرير',
  no_reports_found_for_this_period: 'لا توجد تقارير لهذه الفترة',
  top_sales: 'أعلى المبيعات',
  top_sellers: 'أعلى البائعين',
  bottom_sellers: 'أقل البائعين',
  no_top_sales_for_this_period: 'لا توجد مبيعات عليا لهذه الفترة',
  best_selling_products_for_this_period: 'المنتجات الأكثر مبيعاً لهذه الفترة',
  save_email: 'تذكر البريد الإلكتروني',
  new_password: 'كلمة السر الجديدة',
  verify_password: 'أكد كلمة السر',
  save_password: 'تذكر كلمة السر',
  contact: 'جهة الاتصال',
  name_first: 'الاسم الأول',
  name_last: 'اسم العائلة',
  zip_code: 'الرمز البريدي',
  region: 'الإقليم',
  billing: 'الفوترة',
  optional: 'اختياري',
  business_name: 'اسم الشركة',
  vat: 'الضريبة المضافة',
  btw: 'الضريبة المضافة',
  save_profile_info: 'حفظ معلومات الملف الشخصي',
  integrations: 'المدمجات',
  connect_to_your_existing: 'ربط الحساب',
  invoices: 'الفواتير',
  code: 'الرمز',
  isPaid: 'تم دفعه',
  invoice_paid: 'الفاتورة مدفوعة',
  actions: 'الإجراءات',
  stores: 'المحلات',
  updated: 'تم التحديث',
  updated_at: 'تم التحديث في',
  create: 'إنشاء',
  info: 'معلومات',
  currencies: 'العملات',
  save_edit_details: 'حفظ وتعديل البيانات',
  assigned_employees_of_this_stores: 'الموظفون المعينون في هذا المحل:',
  not_assigned_employees: 'لا موظفين معينين',
  add_payment: 'إضافة دفع',
  payment_name: 'اسم الدفع',
  printer_name: 'اسم الطابعة',
  edit_storess_main_details: 'تعديل البيانات الأساسية للمحل',
  edition: 'النسخة',
  stores_address_details: 'بيانات عنوان المحل',
  stores_contact_details: 'بيانات الاتصال بالمحل',
  assign_employees_for_your_stores: 'تعيين موظفين في محلك',
  create_custom_payment_methods_for_your_store: 'إنشاء وسائل دفع مخصصة لمحلك',
  devices: 'الأجهزة',
  device: 'الجهاز',
  hardware_info: 'بيانات العتاد',
  uuid: 'UUID المعرف الفريد العالمي',
  manufacturer: 'المصنْع',
  os_name: 'اسم نظام التشغيل',
  os_version: 'نسخة نظام التشغيل',
  pos_version: 'نسخة نقطة البيع',
  receipt_layout: 'نسق الفاتورة',
  table_layout: 'نسق الطاولة',
  default_receipt: 'الفاتورة الاعتيادية',
  email_receipt: 'فاتورة بالبريد الإلكتروني',
  change_image: 'تغيير الصورة',
  Tuesday: 'الثلاثاء',
  june: 'يونيو',
  your_store_address: 'عنوان محلك',
  edit: 'تعديل',
  your_text_here: 'نصك هنا',
  cheap: 'رخيص',
  expensive: 'باهظ',
  client_receipt: 'فاتورة العميل',
  transaction_agreement: 'اتفاقية العملية التجارية',
  your_footer_message_here: 'نصك السفلي هنا',
  image: 'الصورة',
  price: 'السعر',
  edit_products_main_details: 'تعديل البيانات الأساسية للمنتج',
  details: 'التفاصيل',
  visible: 'ظاهر',
  in_pos: 'في نقطة البيع',
  cost: 'الكلفة',
  cost_price: 'سعر الكلفة',
  delivery_price: 'سعر تسليم',
  upload_progress: 'حالة الرفع',
  change_products_image: 'تغيير صورة المنتج',
  variants: 'المتغيرات',
  variant: 'المتغير',
  units: 'الوحدات',
  alert: 'تنبيه',
  additional: 'إضافي',
  ledger: 'دفتر الحسابات',
  subledger: 'دفتر الحسابات الفرعي',
  addon: 'إضافة',
  addon_groups: 'مجاميع الإضافات',
  addon_group: 'مجموعة الإضافات',
  add_delete_and_edit_products_variants: 'إضافة، مسح، وتعديل متغيرات المنتج',
  assing_product_to_store: 'تعيين المنتج لمحل معين (إذا لم يكن هناك محلاً محدداً، سيظهر المنتج في كل محل)',
  products_addtional_fields: 'الحقول الإضافية للمنتج',
  manage_addons_group: 'إضافات المجموعة',
  sold_by_weight: 'يباع بالوزن',
  dynamic_price: 'سعر متغير',
  dynamic: 'سعر متغير',
  min_age: 'العمر الأدنى',
  packaging: 'التغليف',
  contents: 'المحتويات',
  form: 'النموذج',
  products_packaging_and_suppliers_details: 'بيانات التغليف والمورْد للمنتج',
  stock: 'المخزون',
  management: 'إدارة',
  count: 'عدد',
  filter_name: 'اكتب لترشيح عمود الاسم',
  find_products_category: 'إيجاد المنتجات حسب القسم',
  find_products_addons: 'إيجاد المنتجات حسب الإضافة',
  in_stock: 'متوفر',
  order_below: 'اطلب بالأسفل',
  order_quantity: 'كمية الطلب',
  advance: 'سابق',
  view_history: 'عرض التاريخ',
  click_here_to_select_source: 'اضغط هنا لتحديد المصدر',
  record_missing_item: 'سجل منتجاً مفقوداً',
  lost: 'مفقود',
  current_stock: 'المخزون الحالي',
  current_report: 'التقرير الحالي',
  view_stock_history: 'عرض تاريخ المخزون',
  color: 'اللون',
  duplicate_item: 'تكرار المنتج',
  want_duplicate_item: 'هل تريد نسخ هذا المنتج؟',
  delete_item: 'مسح المنتج',
  want_delete_item: 'هل تريد مسح هذا المنتج؟',
  categories_visibility_changed: 'تم تغيير عرض الأقسام',
  change_visibility: 'تغيير العرض',
  category: 'قسم',
  parent: 'الأب',
  select: 'تحديد',
  rate: 'النسبة',
  delete_selected_items: 'مسح المنتجات المحددة',
  aplly_diff_rates: 'هل تريد تطبيق ضرائب مختلفة حسب الدولة؟',
  countries_added: 'تمت إضافة الدول',
  groups: 'المجموعات',
  group: 'المجموعة',
  standard: 'الاعتيادي',
  default_text: 'النص الافتراضي',
  business_rules: 'قواعد العمل',
  business_rule: 'قاعدة العمل',
  title: 'المسمى',
  is_active: 'فعال',
  startdate: 'تاريخ البداية',
  enddate: 'تاريخ النهاية',
  rules: 'القواعد',
  rule: 'القاعدة',
  edit_business_rules_main_details: 'تعديل البيانات الأساسية لقاعدة العمل',
  active: 'فعال',
  time_start: 'وقت البداية',
  time_end: 'وقت النهاية',
  select_specific_days: 'اختيار أيام محددة',
  conditions: 'الشروط',
  condition: 'الشرط',
  change_business_rules_conditions: 'تغيير شروط قاعدة العمل',
  change_business_rules_outcome: 'تغيير نتيجة قاعدة العمل',
  minimum_amount: 'الحد الأدنى',
  minimum: 'الأدنى',
  maximum: 'الأقصى',
  maximum_amount: 'الحد الأقصى',
  ignore_for: 'تجاهل ل',
  outcome: 'النتيجة',
  voucher_outcome: 'نتيجة الكوبون',
  new_price_rule: 'سعر جديد لقاعدة العمل',
  min_pack_value: 'القيمة الدنيا للحزمة',
  max_pack_value: 'القيمة العليا للحزمة',
  enable: 'إتاحة القواعد',
  disable: 'منع القواعد',
  all: 'كل',
  update_time: 'تعديل تاريخ البداية أو النهاية',
  update_date: 'تعديل تاريخ البداية أو النهاية',
  catalog_order: 'ترتيب المغلف',
  drag_and_drop_items_to_reorder: 'سحب وإسقاط المواد لتغيير ترتيبها',
  see_reports: 'بإمكانك رؤية تقاريرك في لوحة القيادة عبر اختيار',
  generate_report: 'إنتاج التقرير',
  or: 'أو',
  send_report: 'بإمكانك إرسال مسودة البيانات للبريد الإلكتروني',
  send_report_button: 'إرسال التقرير',
  send_to: 'إرسال إلى',
  recipient: 'المستلم',
  available_reports: 'التقارير المتوفرة لهذه الفترة',
  select_currency_text: 'فضلاً، اختر عملة أو محلاً معيناً',
  tables: 'الجداول',
  revenue: 'الإيرادات',
  see_revenue: 'عرض إجمالي الإيرادات، التكاليف، الضرائب، والأرباح',
  net_revenue: 'صافي الإيرادات',
  profit: 'الربح',
  see_transaction: 'عرض طريقة العملية التجارية، رقمها، والمبلغ الإجمالي',
  see_payments: 'عرض طريقة الدفع ،الرقم، و المبلغ الإجمالي',
  see_refunds: 'عرض طريقة الاسترداد، الرقم، والمبلغ الإجمالي',
  see_taxes: 'عرض الضرائب، الرقم، والمبلغ الإجمالي',
  brands: 'قنوات الطلب',
  channels: 'القنوات',
  channel: 'القنوات',
  see_brands: 'شاهد منتجاتك حسب العلامة التجارية والرقم والمبلغ الإجمالي',
  see_channels: 'شاهد منتجاتك حسب القناة والرقم والمبلغ الإجمالي',
  see_categories: 'عرض الأقسام الخاصة بك، الأرقام، والكمية الكلية',
  click_details: 'اضغط هنا لعرض التفاصيل',
  report_select_other_period: 'فضلاً، اختر فترة زمنية أخرى لعرض التقارير المتوفرة',
  created: 'تم الإنشاء',
  discounts: 'تخفيض',
  add_discount: 'إضافة الخصم',
  discount_percent: 'خصم في المئة',
  register_opertations: 'تسجيل العمليات',
  operations: 'العمليات',
  first: 'الأول',
  last: 'الأخير',
  phone: 'الهاتف',
  permission_to_contact: 'الإذن بالاتصال',
  identifiers_type: 'نوع المعرفات بالزبون',
  identifiers_value: 'قيمة المعرف',
  shipping: 'الشحن',
  diff_billing: 'إذا كان مختلفاً عن الفوترة',
  rights: 'الحقوق',
  employee: 'موظف',
  token: 'الرمز',
  uninstall_ext: 'إزالة الإضافة',
  active_ext: 'هذه الإضافة فعالة',
  subscribe: 'الاشتراك',
  remove_ext_msg: 'هل أنت متأكد من إزالة الإضافة؟',
  no_thanks: 'لا، شكراً',
  subs_to_ext: 'الاشتراك بالإضافات',
  period: 'فترة',
  payment_details: 'تفاصيل الدفع',
  card_name: 'الاسم على البطاقة:',
  paymnet_method: 'طريقة الدفع:',
  code_country: 'الرمز الدولي:',
  diff_way_pay_msg: 'أو اختر طريقة مختلفة للدفع',
  phone_support: 'المساعدة الهاتفية',
  support_msg: 'الاتصال بخدمة العملاء للمساعدة:',
  email_support: 'المساعدة بالبريد الإلكتروني',
  support_email: 'البريد الإلكتروني للمساعدة:',
  info_email: 'البريد الإلكتروني للمعلومات:',
  sales_email: 'البريد الإلكتروني للمبيعات:',
  page: 'الصفحة',
  dark_theme: 'النمط الداكن',
  report_spec_time: 'إنتاج التقرير لفترة محددة',
  vat_btw_no: 'رقم الضريبة المضافة',
  store_unable_delete: 'من غير الممكن مسح المحل بسبب ارتباط جهازٍ به. فضلاً، امسح الجهاز ومن ثم امسح المحل',
  important: 'هام:',
  store_device_delete_msg: 'فضلاً، تحقق من تسجيل خروجك من الجهاز قبل مسحه',
  email_test_receipt: 'أرسل فاتورة تجربة بالبريد الإلكتروني',
  merchant: 'التاجر',
  per_item: 'لكل منتج',
  img_width_msg: ' العرض الأقصى المسموح للملف ٢٥٠ بكسل',
  err_img_msg: 'خطأ: يجب ألا يتعدى عرض الصورة ٢٥٠ بكسل',
  rules_actions: 'القواعد والإجراءات',
  enable_all: 'تفعيل كل قواعد العمل',
  want_enable_all: 'هل تريد تفعيل كل قواعد العمل؟',
  disable_all: 'تعطيل كل قواعد العمل',
  want_disable_all: 'هل تريد تعطيل كل قواعد العمل؟',
  product_cat_error: 'فضلاً، اختر قسماً',
  field_not_empty: 'لا يمكن ترك الحقل فارغاً',
  drop_files: 'أسقط ملفك هنا لرفع الصورة',
  save_variant_before_stock: 'احفظ المتغير الجديد قبل تعديل المخزون',
  no_employees: 'ليس لديك أي موظفين. بإمكانك إضافة موظفين جدد',
  here: 'هنا',
  no_stock: 'لا توجد منتجات من المخزون في هذا المحل',
  product_exists: 'هذا المنتج موجود فقط في',
  add_units_stock: 'إضافة الوحدات للمخزون',
  remove_units_stock: 'إزالة الوحدات من المخزون',
  saving: 'يحفظ',
  transfer_units: 'أضف عدد الوحدات للتحويل',
  remove_units_store: 'أضف عدد الوحدات لإزالتها من هذا المحل',
  notification: 'إشعار',
  set_new_start_end_time: 'ضبط وقت بداية/نهاية جديد',
  set_new_start_end_date: 'ضبط تاريخ بداية/نهاية جديد',
  by_email: 'عبر البريد الإلكتروني',
  charts_graphs: 'المخططات والرسوم',
  compare_reports: 'مقارنة التقارير',
  new_report: 'تقرير جديد',
  basic: 'أساسي',
  no_acount_to_send_report: 'لا يمكنك إرسال هذا التقرير لأن حسابك',
  upgrade_account_msg: 'عليك تحديث حسابك!',
  gen_report_msg: 'يمكنك إنتاج التقرير وعرض النتائج في لوحة القيادة',
  disabled_report: 'التقارير معطلة طالما لم يتم الدفع',
  please_check: 'فضلاً، انظر في',
  email_wrong_format: 'شكل البريد الإلكتروني ليس صحيحاً',
  bar_chart: 'مخطط عواميد',
  method: 'الطريقة',
  total_taxed_amount: 'المبلغ الإجمالي المفروضة عليه الضرائب',
  total_price_product: 'السعر الكلي للمنتجات',
  enter_value: 'عليك إدخال قيمة',
  not_valid_mail: 'البريد الإلكتروني غير صحيح',
  enter_email: 'أدخل بريدك الإلكتروني',
  unit_price: 'سعر الوحدة',
  extras: 'الزوائد',
  monthly_billed: 'الفوترة الشهرية',
  removing_ext: 'هذه الإضافة ستتم إزالتها في دورة الفوترة المقبلة',
  local_ip: ' المحليIP',
  printer_ip_ad: 'الطابعة IP',
  printer_categories: 'أقسام الطابعة',
  printers: 'الطابعات',
  payment_processor: 'معالج الدفع',
  question: 'هل عندك سؤال؟',
  check_here: 'انظر هنا',
  knowledge_center: 'مركز المعرفة',
  support_articles_msg: 'لدينا عدد من مقالات الدعم التي يمكن أن تساعدك في البدء، إنشاء المنتجات، وربط متجرك الإلكتروني، والمزيد',
  support_ques: 'إذا لم تجد الإجابة بعد، بوسعك',
  in_touch: 'الاتصال بنا',
  select_floorplan: 'تحديد مخطط الدور',
  floors: 'الأدوار',
  add_floorplan: 'إضافة مخطط الدور',
  delete_floorplan: 'إزالة مخطط الدور',
  floorplan: 'مخطط الدور',
  add_tables: 'إضافة طاولات',
  sign_up_msg: 'انضم آلاف التجار لكاونتر، واليوم دورك. انضم مجاناً اليوم',
  user_required: 'اسم المعرف مطلوب',
  name_required: 'الاسم مطلوب',
  company_name_required: 'اسم الشركة مطلوب',
  phone_required: 'رقم الهاتف مطلوب',
  pass_required: 'كلمة السر مطلوبة',
  already_account: 'عندك حساب بالفعل؟',
  accept_sign_up: 'بالانضمام، أنا أقبل',
  terms_conditions: 'الشروط والأحكام',
  policy: 'سياسة الخصوصية',
  licence_agree: 'اتفاقية الترخيص',
  your_name: 'اسمك',
  company_web: 'موقع الشركة (ليس إجبارياً)',
  forgot_pass: 'نسيت كلمة السر؟',
  login: 'تسجيل الدخول',
  no_account_link: 'ليس عندك حساباً؟',
  email_required: 'البريد الإلكتروني مطلوب',
  send_link: 'إرسال الرابط',
  download_file: 'تنزيل الملف',
  account_number: 'رقم الحساب',
  me_rules: 'القاعدة',
  by_volume: 'المنتجات الأكثر مبيعاً (وفق الحجم) لهذه الفترة',
  number_sold: 'عدد المبيعات',
  by_volume_bottom: 'المنتجات الأقل مبيعاً (وفق الحجم) لهذه الفترة',
  create_compare_report: 'إنتاج تقرير جديد للمقارنة',
  last_per: 'الأخير',
  no_per_data: 'لا تقارير متوفرة',
  create_new_rep: 'إنتاج تقرير جديد',
  reset_pass_text: 'أدخل بريدك الإلكتروني لإعادة تعيين كلمة السر',
  enter_bus_info: 'فضلاً، أدخل معلومات شركتك',
  organization: 'اسم المؤسسة',
  mandatory_fields: '!!! كل الحقول مطلوبة',
  invalid_eu: 'رقم الضريبة المضافة غير صحيح لدول الاتحاد الأوروبي',
  trial_expires: 'تنتهي تجربتك في',
  select_package: 'اختيار الباقة',
  excluse_vat: 'غير شامل الضريبة المضافة',
  instead: 'بدلاً من',
  save_payment: 'تذكر طريقة الدفع هذه للعمليات المستقبلية',
  procced_paymet: 'الذهاب للدفع',
  selected_package: 'الباقة المختارة',
  selected_ext: 'الإضافات المختارة',
  old_dashboard: 'العودة إلى لوحة التحكم القديمة',
  subscription_msg: 'مبروك! أنت علي وشك تفعيل محلك! سيتم حفظ عملياتك التجارية للأبد وستختفي كل المنتجات التجربية. قبل أن تباشر، تأكد من اختيار الإضافات التي تريد إبقائها',
  transfer_from: 'وحدات نقل من',
  customer_purchase: 'مشتريات الزبون',
  total_spend: ' مجموع المشتريات',
  last_customers_transactions: 'المعاملات الأخيرة للزبون',
  email_recipient: 'Email المستلم',
  show_revenue: 'إظهار الإيرادات',
  show_sales: 'عرض المبيعات',
  visits: 'مجموع الزيارات',
  select_action: 'اختر فعلا ',
  show_receipt: 'إظهار الإيصال',
  set_values_for: 'حدد القيم ل',
  update: 'تحديث',
  select_addons_group: 'حدد مجموعة الوظائف الإضافية',
  select_addon: 'حدد الملحق',
  all_addon_groups_are_in_use: 'القائمة فارغة ، جميع مجموعات Addon قيد الاستخدام',
  store_hours: 'ساعات العمل',
  opening_hours: 'ساعات العمل',
  no_opening_hours: 'لا توجد ساعات عمل',
  set_opening_hours: 'حدد ساعات العمل',
  delivery_hours: 'ساعات التسليم',
  no_delivery_hours: 'لا توجد ساعات التسليم',
  set_delivery_hours: 'تعيين ساعات التسليم',
  ordering_hours: 'ساعات الطلب',
  no_ordering_hours: 'لا ساعات الطلب',
  set_ordering_hours: 'تعيين ساعات الطلب',
  takeaway_hours: 'ساعات الوجبات الجاهزة',
  no_takeaway_hours: 'لا توجد ساعات للوجبات السريعة',
  set_takeaway_hours: 'تعيين ساعات الوجبات السريعة',
  delivery_zones: 'مناطق التسليم',
  no_delivery_zones: 'لا توجد مناطق تسليم',
  set_delivery_zones: 'ضبط مناطق التسليم',
  Monday: 'الاربعاء',
  Thursday: 'السبت',
  Friday: 'الجمعة',
  Saturday: 'الخميس',
  Sunday: 'الاحد',
  set_opening_store: 'تعيين ساعات عمل المتجر',
  set_delivery_store: 'تعيين ساعات التسليم للمخزن',
  set_ordering_store: 'تعيين ساعات الطلب للمخزن',
  set_takeaway_store: 'تعيين ساعات الوجبات السريعة للمتجر',
  set_zones_store: 'تعيين مناطق التسليم للمخزن',
  no_data_added: 'لم تتم إضافة بيانات ',
  add_hours: 'أضف ساعات',
  add_zones: 'أضف مناطق التسليم',
  order_sub: 'ترتيب الفئات الفرعية',
  new_item: 'جديد',
  tips: 'تلميح',
  see_busiest_hours: 'شاهد أكثر الساعات ازدحامًا بناءً على الفترة التي حددتها',
  see_employee_transactions: 'انظر المعاملات لكل موظف للفترة المحددة',
  see_employee_tips: 'انظر نصيحة لكل موظف للفترة المحددة',
  see_employee_refunds: 'انظر المبالغ المستردة لكل موظف للفترة المحددة',
  see_customer_transactions: 'انظر المعاملات حسب نوع العميل للفترة المحددة',
  see_customer_refunds: 'انظر المبالغ المستردة لكل نوع عميل للفترة المحددة',
  new_customer: 'عميل جديد',
  returning_customer: 'الزبون العائد',
  no_customer: 'لا توجد بيانات العملاء',
  reps_waste: 'Reps and waste',
  see_reps_waste: 'See reps and waste by reason',
  see_employee_reps_waste: 'See reps and waste by employee',
  employee_report_graph: 'انظر مبيعات الموظفين في كل مرة',
  not_sold: 'لا تباع',
  non_selling_products_this_period: 'المنتجات غير المباعة هذه الفترة (المنتجات ذات المبيعات 0)',
  compare_stores: 'قارن المتاجر',
  average_amount_spent: 'متوسط ​​المبلغ المنفق',
  average_amount_refunded: 'متوسط ​​المبلغ المسترد',
  bundle_parts: 'أجزاء الحزمة',
  create_giftcard: 'إنشاء بطاقة هدايا',
  giftcards: 'كرت هدية',
  select_graphs_type: 'حدد نوع الرسم البياني',
  see_discount_percentage: 'انظر المعاملات مع نسبة الخصم',
  see_discount_by_product_percentage: 'انظر الخصم الممنوح للمنتجات (يتم حساب الخصومات الرقمية كنسبة مئوية)',
  discount_percentage: 'خصم (نسبة مئوية)',
  discount_numeric: 'الخصم (رقمي)',
  see_discount_numeric: 'انظر المعاملات ذات الخصم الرقمي',
  number_of_discounts: 'وعدد مرات حدوث هذا الخصم على مستوى المنتج أو سلة التسوق',
  delivery_cost: 'تكلفة التوصيل',
  total_cash_after_tips_and_change: 'إجمالي النقود بعد الإكراميات والتغيير',
  assigned_stores: 'المتاجر المعينة',
  Wednesday: 'الاثنين',
  deviceid_mandatory: 'إسم الجهاز إلزامي',
  no_floorplans: 'لا توجد مخططات أرضية متاحة',
  send_order: 'ارسال الطلب',
  save_table: 'حفظ',
  save_cart: 'حفظ',
  partial_payment_not_allowed_by_value: 'المدفوعات الجزئية غير مسموح بها',
  allow_ticket_scan: 'ابحث عن التذاكر باستخدام ماسح الباركود',
  no_pin_employee: 'موظف بدون رقم تعريف شخصي',
  allow_ticket_scan_desc: 'استخدم ماسح الباركود للبحث عن التذاكر.',
  admin_only_custom_item: 'تمكين العنصر المخصص فقط للمسؤول',
  admin_only_custom_item_desc: 'يمكن فقط للمستخدمين الذين لديهم حقوق المسؤول إنشاء عنصر مخصص. يجب عليك تفعيل PIN للموظف أيضا.',
  mandatory_employee_pin: 'التفعيل الإلزامي لرقم التعريف الشخصي الخاص بالموظف.',
  admin_only_refunds: 'يمكن للمسؤولين فقط إجراء عمليات رد الأموال',
  admin_only_refunds_desc: 'يمكن للمسؤولين فقط إجراء عمليات الاسترداد. يجب عليك تنشيط PIN للموظف أيضًا.',
  round_cash_transactions_desc: 'تقريب المدفوعات النقدية تلقائيًا إلى أقرب 0.05',
  unlimited_usage: 'استخدام غير محدود',
  infinite: 'غير محدود',
  disable_custom_discounts: 'تعطيل الخصومات المخصصة',
  disable_custom_discounts_desc: 'قم بتعطيل الخصومات المخصصة مع السماح بالتخفيضات المحددة مسبقًا.',
  guest: 'عملاء',
  select_method: 'حدد الطريق',
  manual_insert: 'إدراج يدوي',
  enter_code: 'ادخل الرمز',
  existing_by_value_payment: '* لا يمكنك مزج الدفع بالقيمة والدفع بالمقعد و / أو العنصر',
  order_sent: 'تم إرسال الطلب',
  select_multiple_seats: 'حدد مقاعد متعددة',
  change_seat_or_guest_num: 'تغيير المقعد أو رقم الضيف',
  change_guest_num: 'تغيير رقم الضيف',
  giftcard_low_value: '* رصيد بطاقة الهدايا أقل من قيمة العنصر (العناصر) المحددة. حاول الدفع بالقيمة بدلاً من ذلك.',
  order_by_seat_desc: 'تمكين الطلب عن طريق ميزة المقعد.',
  received_amount: 'المبلغ الذي تسلمه',
  order_products_action: 'اطلب المنتجات',
  ordered_products: 'المنتجات المطلوبة',
  select_table: 'حدد الجدول',
  ordered: 'أمر',
  scan_or_type: 'امسح أو اكتب',
  type_code: 'كود نوع',
  number_extension: 'تمديد الرقم',
  move_processing_ticket_first: 'نقل تجهيز التذاكر أولا',
  disable_adblocker: 'يرجى تعطيل AdBlocker الخاص بك وتحديث الصفحة',
  remove_partial_payment_by_item: 'تم الكشف عن دفع جزئي حسب السلعة! يرجى إزالته من أجل متابعة هذا الإجراء!',
  add_nutrients: 'أضف المغذيات',
  giftcard_warning_msg: 'الصلاحية القياسية لبطاقة الهدايا هي سنتان. هل أنت متأكد أنك تريد تقليل هذا؟',
  edit_devicess_main_details: 'تحرير التفاصيل الرئيسية للأجهزة.',
  create_custom_payment_methods_for_your_device: 'إنشاء طرق دفع مخصصة لجهازك.',
  show_open_carts_from_device: 'إظهار الطلبات المفتوحة من الجهاز',
  create_discounts_for_your_stores: 'إنشاء خصومات لموقعك.',
  create_reps_waste_for_your_stores: 'إنشاء ممثلين وإهدار لموقعك.',
  show_cat_bar: 'إظهار شريط الفئات',
  bulk_update: 'عناصر التحديث بالجملة',
  show_category_product_info: 'عرض معلومات الفئة في المنتج',
  ready_items: 'العناصر الجاهزة',
  disabled: 'عاجز',
  ready_orders: 'أوامر جاهزة',
  changes_list: 'قائمة التغييرات',
  action_type: 'نوع الإجراء',
  previous_change: 'التغيير السابق',
  new_changes: 'تغييرات جديدة',
  removed_fields: 'الحقول التي تم إزالتها',
  updated_fields: 'الحقول المحدثة',
  full_history: 'التاريخ الكامل',
  changes_history: 'يغير التاريخ',
  product_history: 'تاريخ المنتج',
  category_history: 'تاريخ الفئة',
  device_history: 'سجل الجهاز',
  enable_mandatory_customer_address: 'عنوان الزبون الإلزامي',
  please_fill_all_fields: 'يرجى ملء جميع الحقول المطلوبة: رقم المبنى ، الشارع ، المدينة ، الولاية / المقاطعة ، الرمز البريدي أو الرمز البريدي ، البلد ، الاسم الأول والأخير ، عنوان البريد الإلكتروني أو رقم الهاتف',
  select_file: 'حدد الملف',
  drop_file: 'إفلات الملف',
  create_media: 'إنشاء وسائط لـ',
  upload_image: 'تحميل الصور',
  upload_file: 'رفع ملف',
  add_custom_img: 'أضف صورتك المخصصة',
  select_media: 'حدد نوع الوسائط',
  external_url_iframe: 'عنوان URL خارجي Iframe',
  select_ticket_print: 'حدد النوع للطباعة',
  external_service_provider: 'مزود خدمة خارجي',
  barcode_input: 'إدخال الباركود',
  stock_load_msg: 'فحص جميع المنتجات للمخزون. هذا قد يستغرق بعض الوقت. أرجو الإنتظار!',
  no_available_stores: 'لا توجد متاجر متاحة.',
  buy_combination_get_deal_new_price: 'الجمع بين العناصر للحصول على سعر العقد',
  discount_specific_products_categories: 'خصم على منتجات أو فئات معينة',
  discount_all_prodcucts: 'خصم على جميع المنتجات',
  buy_x_items_get_cheapest_discount: 'شراء س العناصر ، والحصول على أرخص بسعر مخفض',
  spend_certain_amount_get_discount: 'أنفق مبلغًا معينًا ، احصل على خصم',
  customer_group_item_discount: 'خصم على مجموعات عملاء محددة',
  item_surcharge: 'تكلفة إضافية على منتجات أو فئات أو مجموعات عملاء محددة',
  cart_surcharge: 'تكلفة إضافية على الطلب بأكمله',
  import: 'يستورد',
  export: 'يصدر',
  simple: 'بسيط',
  advanced: 'متقدم',
  delete_import: 'حذف استيراد المنتجات',
  upload_csv: 'قم بتحميل ملف CSV الخاص بك',
  open_file: 'افتح الملف باستخدام',
  save_file: 'احفظ الملف كملف csv',
  value_remaining: 'القيمة المتبقية:',
  shared_shift: 'حصة التحول',
  shared_shift_desc: 'تغيير الموظف لن يتطلب إعادة فتح السجل',
  sample_check_msg_top: 'أرجو الإنتظار. يجب أن يكون هناك فحص طبي.',
  sample_check_msg_bottom: 'سيأتي الموظف في أقرب وقت ممكن.',
  full_basket_check: 'فحص السلة بالكامل',
  missing_items_amount: 'كمية العناصر المفقودة',
  extra_items_scanned: 'كمية العناصر الإضافية',
  finish_check: 'إنهاء الشيك',
  original_items: 'العناصر الأصلية',
  checked_items: 'العناصر المحددة',
  merging: 'الدمج قيد التقدم',
  select_table_from_list: 'حدد جدولاً من القائمة لدمج كافة الجداول الأخرى المحددة',
  cant_merge_same: 'لا يمكن دمج نفس الجدول ، الرجاء تحديد جدول آخر لدمجها',
  remove_from_table: 'إزالة من الجدول',
  eat_in: 'أكل في'
}

export default arLang
