import React, { useEffect, useCallback } from 'react'
import Logo from './Logo'
import { LOCAL_STORAGE, THEME } from '../../utils/CONSTS'
import './../../styles/App.scss'

const Header = React.memo(({selectedStore}) => {
  const clock = useCallback(() => {
    const el = document.getElementById('clock')

    if (!!el) {
      const now = new Date()
      const hour = now.getHours()
      const min = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes()
      const sec = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds()
      el.innerHTML = `${hour}:${min}:${sec}`
    }
    setTimeout(clock, 1000)
  }, [])

  useEffect(() => {
    clock()
    const theme = localStorage.getItem(LOCAL_STORAGE.THEME)
    if (theme === THEME.LIGHT) {
      changeTheme()
    }
  }, [clock])

  const changeTheme = () => {
    document.body.classList.toggle(THEME.LIGHT)
    document.querySelector(THEME.APP_HEADER).classList.toggle(THEME.LIGHT)
    document.querySelector(THEME.LOGO).classList.toggle(THEME.LIGHT)
    document.querySelector(THEME.CLOCK).classList.toggle(THEME.LIGHT)
    // Apply also change theme to orders style
    document.querySelectorAll(THEME.PRINTED).forEach(el => {
      el.classList.toggle(THEME.LIGHT)
    })
    document.querySelectorAll(THEME.PREPARING).forEach(el => {
      el.classList.toggle(THEME.LIGHT)
    })
    document.querySelectorAll(THEME.READY).forEach(el => {
      el.classList.toggle(THEME.LIGHT)
    })

    localStorage.setItem(
      LOCAL_STORAGE.THEME,
      document.body.classList.contains(THEME.LIGHT) ? THEME.LIGHT : THEME.DARK
    )
  }

  return (
    <header id='app-header' className="app-header">
      <div className="filter-icons-header">
        <Logo selectedStore={selectedStore}/>
        <div className="clock">
          <span id="clock"></span>
        </div>
        {/* <div>
          <span onClick={changeTheme} className="circle"></span>
        </div> */}
      </div>
    </header>
  )
})

export default Header
