/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
const enLang = {
  cart: 'Order',
  item: 'Item',
  items: 'Items',
  subtotal: 'Subtotal',
  discount: 'Discount',
  total: 'Total',
  pay: 'Pay',
  card: 'Card',
  cash: 'Cash',
  invoice: 'Invoice',
  giftcard: 'Giftcard',
  countr_giftcard: 'Countr Giftcard',
  seqr: 'SEQR',
  bitpay: 'Bitpay',
  payment_method: 'Payment method',
  cash_received: 'Amount received',
  or_enter_amount: 'Or enter amount',
  payment_succeeded: 'Payment succeeded',
  change_due: 'Change due',
  total_paid: 'Total paid',
  send_receipt: 'Send receipt',
  send_invoice: 'Send invoice',
  send: 'Send',
  delete: 'Delete',
  new_sale: 'New sale',
  custom_price: 'Custom price',
  item_description: '(Optional) item description / name',
  products: 'Products',
  transactions: 'Transactions',
  reports: 'Reports',
  report: 'Report',
  settings: 'Settings',
  sign_out: 'Sign out',
  receipt: 'Receipt',
  refund: 'Refund',
  home: 'Home',
  clear: 'Clear',
  add: 'Add',
  sign_up: 'Sign up',
  sign_up_email: 'Sign up with email',
  sign_in: 'Sign in',
  company_name: 'Business name',
  merchant_name: 'Contact person',
  email_address: 'Email address',
  phone_number: 'Phone number',
  password: 'Password',
  confirm_password: 'Confirm password',
  forgot_password_question: 'Forgotten password?',
  next: 'Next',
  demo: 'Demo',
  skip: 'Skip',
  skip_registration: 'Skip registration',
  take_a_tour: 'Take a tour',
  items_returned: 'Items returned',
  wrong_billing: 'Wrong billing',
  order_cancelled: 'Order cancelled',
  other: 'Other',
  enter_valid_amount: 'Enter a valid amount',
  invalid_merchant_name: 'Invalid contact person',
  invalid_business_name: 'Invalid business name',
  invalid_email_address: 'Invalid email address',
  invalid_phone_number: 'Invalid phone number',
  invalid_password: 'Invalid password - must be at least 8 characters in length',
  password_mismatch: 'Password mismatch',
  coming_soon: 'Coming soon...',
  to_use_signup: 'To use this feature, please sign up',
  login_error: 'Login error - check login details and internet connection',
  signup_error: 'Sign up error',
  transaction_history_empty: 'Transaction history is empty',
  categories: 'Categories',
  all_products: 'All products',
  search_product: 'Search product',
  reissue_receipt: 'Reissue receipt',
  print_receipt: 'Print receipt',
  or_send_receipt: 'Or send receipt',
  save: 'Save',
  saved: 'Saved',
  search: 'Search',
  custom_item: 'Custom item',
  submit: 'Submit',
  submitted: 'Submitted',
  note: 'Note',
  message: 'Message',
  amount: 'Amount',
  number: 'Number',
  taxes: 'Taxes',
  tax: 'Tax',
  enter_valid_number: 'Please enter a valid number',
  enter_valid_text: 'Please enter valid text',
  enter_valid_phone: 'Please enter a valid phone number',
  enter_valid_email: 'Please enter a valid email address',
  feature_not_in_demo: 'This functionality is not available in demo mode',
  provide_refund_reason: 'You must select a refund reason',
  provide_valid_refund_amount: 'You must provide a valid refund amount',
  refund_exceed_refundable: 'Refund amount exceeds the maximum refundable for this receipt',
  refund_saved: 'Refund saved',
  or_customize_discount: 'Or customise discount',
  enter_percentage: 'Enter percentage',
  manual_card_payment: 'Manual card payment',
  did_card_payment_succeed: 'Did card payment succeed?',
  manual_card_refund: 'Manual card refund',
  did_card_refund_succeed: 'Did card refund succeed?',
  giftcard_refund: 'Giftcard refund',
  did_giftcard_refund_succeed: 'New giftcard issued to customer?',
  yes: 'Yes',
  no: 'No',
  general: 'General',
  printer: 'Printer',
  barcode_scanner: 'Barcodescanner',
  payment: 'Payment',
  profile: 'Profile',
  currency: 'Currency',
  printing_receipt: 'Printing receipt...',
  enter_amount: 'Enter amount',
  expected_change: 'Expected change',
  still_remaining: 'Still remaining',
  issue_invoice: 'Issue Invoice',
  URL_pricing: 'http://www.countrhq.com/#pricing',
  address: 'Address',
  address_continued: 'Address [Optional]',
  city: 'City',
  zip: 'Zip or Postal Code',
  country: 'Country',
  country_iso: 'Country in ISO 3166-2 format (e.g. "nl", "de", "gb")',
  invalid_address: 'Invalid or empty address',
  invalid_city: 'Invalid or empty city',
  invalid_country: 'Invalid or empty country',
  invalid_ecommerce: 'No e-commerce platform chosen',
  signup_error_contact_support: 'Signup error, please contact support@countrhq.com',
  open_orders: 'Open orders',
  order: 'Order',
  enter_weight_g: 'Enter weight (g)',
  search_scanner: 'Search for Socket CHS scanner',
  order_settings: 'Order settings',
  order_amount: 'Number of open orders available',
  receipts: 'Receipts',
  print_receipts: 'Automatically print receipts',
  open_cash_drawer: 'Automatically Open Drawer for cash payments',
  backup: 'Backup',
  email_backup: 'Email backup',
  server_sync: 'Server sync',
  external_payment: 'Other',
  search_printer: 'Search for printer',
  sms_successfully_sent: 'SMS successfully sent',
  error_sending_sms: 'Error sending SMS, try again later',
  email_successfully_sent: 'Email successfully sent',
  error_sending_email: 'Error sending email, try again later',
  transaction: 'Transaction',
  logging_in: 'Logging in...',
  downloading_products: 'Downloading products...',
  add_to_cart: 'Add to cart',
  invalid_price: 'Invalid price',
  invalid_discount: 'Invalid discount',
  invalid_new_price: 'Invalid new price - must be less than original price',
  invalid_amount: 'Invalid amount',
  edit_price: 'Edit price',
  discount_perc: 'Discount (%)',
  quantity: 'Quantity',
  remove: 'Remove',
  sales_info: 'Sales info',
  summary: 'Summary',
  refunded_information: 'Refund information',
  total_sale: 'Total sale',
  total_amount_refunded: 'Total refunded',
  remaining: 'Remaining',
  sold_by: 'Sold by',
  source_receipt_number: 'Source receipt number',
  issued_date: 'Issued date',
  issued_time: 'Issued time',
  refunded_by: 'Refunded by',
  reason: 'Reason',
  receipt_number: 'Receipt number',
  refunded_date: 'Refunded date',
  refunded_time: 'Refunded time',
  first_name: 'First name',
  last_name: 'Last name',
  shop_category: 'Shop category',
  choose_country: 'Choose a country...',
  you_will_receive_email: 'Congratulations, your signup is complete.',
  wait_email: 'You will now receive an email with further instructions on how to connect your e-commerce platform to Countr POS.',
  open_dashboard: 'You can now manage your account and create products in your personal Countr dashboard.',
  by_signing_up: 'By signing up I accept the',
  terms_and_conditions: 'Terms and Conditions',
  and: 'and',
  privacy_policy: 'Privacy Policy',
  refund_items: 'Refund items',
  forgot_password: 'Forgot password?',
  register_device: 'Register device',
  device_not_registered: 'This device is not registered',
  want_to_add_to_account: 'Register it now?',
  if_ios_previously_registered: 'If you have recently re-installed Countr, you need to register your device again.',
  new_device_info: 'You will be prompted on the next screen to link your device to one of your locations',
  not_now: 'Not now',
  proceed: 'Proceed',
  more_info_click: 'More Info? Click here',
  your_detail: 'Your details',
  vat_no: 'VAT number',
  billing_details: 'Billing details',
  add_store: 'Add location',
  or_add_to_existing_store: 'Or add to existing location',
  add_new_store: 'Add new location',
  store_information: 'location information',
  store_name: 'location name',
  go_to_web_dashboard: 'Go to the web dashboard',
  error_create_account: 'Error creating account',
  error_creation_error: 'An error occurred during signup.',
  error_wrong_phone_number: 'Please enter a valid phone number.',
  error_try_again: 'Connection problem. Please try again',
  error_from_the_server: 'Unable to establish connection with the server',
  error_update_cc_info: 'Cannot update credit card information',
  error_add_device: 'Cannot add device to the current location',
  error_add_store: 'Cannot add new location',
  are_not_valid: 'are not valid',
  is_not_valid: 'is not valid',
  are_empty: 'are empty',
  is_empty: 'is empty',
  are_not_selected: 'are not selected',
  is_not_selected: 'is not selected',
  bill: 'Bill',
  URL_dashboard: 'https://backoffice.countrhq.com/',
  URL_support: 'mailto:support@countrhq.com',
  URL_forgot_password: 'https://backoffice.countrhq.com/#/auth/forgot',
  close: 'Close',
  no_product_found: 'No products found',
  please_go_to_dashboard: 'To add products, visit https://backoffice.countrhq.com',
  select_interface: 'Select interface',
  refund_all: 'Refund all',
  purchased_items: 'Purchased items',
  refundable: 'Refundable',
  to_be_refunded: 'To be refunded',
  out_of: 'Out of',
  enter_a_printer_name: 'Enter a printer name',
  cart_is_empty: 'Order is empty',
  searching_printers: 'Searching for printers...',
  no_printers_found: 'No printers found. If searching for a Bluetooth printer, please ensure your Bluetooth is switched on in device settings',
  total_due: 'Total due',
  payment_not_successful: 'Payment not successful.',
  error_processing_payment: 'Error processing payment.',
  purchase: 'Purchase',
  transaction_aborted: 'Transaction aborted',
  authorization_error: 'Authorisation error',
  authorization_ok: 'Authorisation OK',
  transaction_cancelled: 'Transaction cancelled',
  clearance_error: 'Clearance error by processor',
  clearance_ok: 'Clearance confirmed OK by processor',
  transaction_declined: 'Transaction declined',
  transaction_complete: 'Transaction complete',
  initiating: 'Initiating',
  application_selected: 'Application selected',
  scheduled_for_clearance: 'Scheduled for off-line clearance by processor',
  terminal_error: 'Terminal error',
  unknown_status: 'Unknown status',
  waiting_for_clearance: 'Waiting for off-line clearance confirmation by processor',
  amount_exceeded: 'Maximum amount exceeded',
  authorization_timeout: 'Authorisation timeout',
  batch_processing_failed: 'Batch processing failed',
  cancelled: 'Cancelled',
  card_blocked: 'Card blocked',
  card_invalid: 'Card invalid',
  card_declined: 'Card declined',
  transaction_failed: 'Transaction failed',
  insufficient_funds: 'Insufficient funds',
  terminal_not_paired: 'Terminal not paired - try restarting terminal',
  pin_incorrect_last_retry: 'PIN incorrect - final attempt',
  pin_incorrect_retry: 'PIN incorrect - retry',
  printer_busy: 'Printer busy',
  printer_error: 'Printer error',
  printer_not_present: 'Printer not present',
  printer_out_of_paper: 'Printer out of paper',
  success: 'Success',
  system_error: 'System error',
  transaction_not_found: 'Transaction not found',
  unknown_device: 'Unknown device',
  apply_discount: 'Apply discount',
  seqr_id: 'SEQR Merchant ID',
  seqr_password: 'SEQR Merchant Password',
  bitpay_api_token: 'Bitpay API Token',
  registration_successful: 'Successfully registered terminal',
  unregistration_successful: 'Successfully de-registered terminal',
  payment_init_not_successful: 'Payment not initialised - check network connection.',
  always_show_addons: 'Always show addons',
  show_product_image: 'Show product image',
  hide_product_img: 'Hide product image',
  settings_connected_printers: 'Connected printers',
  settings_add_new_printer: 'Add new printer',
  wifi_lan: 'WiFi or LAN',
  bluetooth: 'Bluetooth',
  usb: 'USB',
  paired_barcode_scanners: 'Paired barcodescanners',
  account: 'Account',
  refresh_products: 'Refresh products',
  server_synchronisation: 'Server synchronisation',
  sales: 'Sales',
  today: 'Today',
  refunds: 'Refunds',
  product: 'Product',
  product_by_category: 'Products by category',
  see_categories_details: 'See category details',
  open_orders_finish_payment_or_empty: 'There are open orders. Finish the payment or empty the order(s).',
  name: 'Name',
  loading_products: 'Loading products',
  you_should_select_at_least_one_item: 'You must select at least one item',
  select_card_provider: 'Select one card provider',
  user_cancelled: 'Payment cancelled by customer',
  card_payment: 'Payment by card',
  manage_products: 'Manage products',
  edit_mode: 'Back-office',
  lock: 'lock',
  loyalty: 'Loyalty',
  account_card: 'Account card',
  account_lookup: 'Account lookup',
  Activate: 'Activate',
  add_anonymous_customer: 'Add anonymous customer',
  add_customer: 'Add customer',
  add_customer_to_cart: 'Select customer',
  add_new_account: 'Add new account',
  add_new_customer: 'Add new customer',
  add_note_hint: 'Add note',
  add_tax_to_price: 'Prices exclude tax',
  add_tip: 'Add gratuity',
  add_to_store: 'Add location',
  addons: 'Addons',
  admin_pin: 'Admin PIN',
  admin_pin_saved: 'New admin PIN saved',
  admin_pin_should_be: 'Admin PIN should be a number between 4 and 6 digits.',
  aid: 'AID',
  aidCard: 'aidCard',
  allow_cart_discounts: 'Allow order-level discounts',
  allow_custom_cart_discounts: 'Allow custom order-level discounts',
  allow_custom_items: 'Allow custom items',
  allow_product_discounts: 'Allow product-level discounts',
  allow_custom_product_discounts: 'Allow custom product-level discounts',
  amount_hint: '999',
  app_version: 'Version',
  applicationLabel: 'applicationLabel',
  applicationName: 'applicationName',
  applicationPreferredName: 'applicationPreferredName',
  appName: 'appName',
  ask_tips: 'Enable gratuities',
  authorizationCode: 'authorizationCode',
  available_points: 'Available points',
  available_rewards: 'Available rewards',
  back: 'Back',
  barcode_scanner_disconnected: 'Your barcode is connected but it might be turned off. Try to turn it on.',
  barcode_scanner_not_found: 'No barcode scanner found',
  barcode_scanner_not_found_desc: 'Connect a new barcode scanner - turn bluetooth on in your device settings and search for a scanner below',
  bunq: 'bunq',
  bunq_admin_approval: 'A bunq administrator will review and approve your cash register before it can be used.',
  bunq_instructions: '1) Open bunq app and enter PIN (if required)\n2) Choose \"Scan QR code\" or \"QR\" from main screen\n3) Scan the on-screen code\n4) Add a gratuity if desired\n5) Confirm payment',
  can_only_refund_entire_amount: 'Can only refund entire receipt amount via {{payment_method}}',
  cancel: 'Cancel',
  cancel_payment: 'Cancel payment',
  cannot_pay_later_more_than_remaining_value: 'You cannot pay more than the remaining value ({{amount}}) with method pay later.',
  cannot_refund_transaction_with_partial_postponed_payments: 'Sorry, this transaction contains one or more partial postponed payments, pay the transaction first or refund it all',
  cant_find_products: 'Cannot find all your Products in the POS? Use the Search box above or a barcodescanner to find them.',
  card_scheme: 'Card scheme',
  cardBrand: 'cardBrand',
  cardExpiryMonth: 'Card Expiry Month MM',
  cardExpiryYear: 'Card Expiry Year YY',
  cardType: 'cardType',
  cart_is_frozen_finish_payment: 'The order is frozen. Finish the payment before doing any action.',
  cash_difference: 'Difference',
  cash_drawer: 'Cash drawer',
  cash_in: 'Cash in',
  cash_in_out: 'Cash in/out',
  cash_out: 'Cash out',
  change: 'Change',
  change_currency: 'Change currency',
  change_given: 'change given',
  change_open_orders_amount: 'Change open order amount',
  check_location_settings: 'Check location settings',
  check_network: 'Check network connection',
  choose_category: 'Choose one business category...',
  choose_mobile_provider: 'Multiple providers enabled - please choose one',
  clear_settings: 'Clear settings',
  clear_settings_successful: 'Successfully cleared settings',
  clear_settings_warning: 'Clearing these settings will reset your connection information. Click confirm to proceed.',
  client_ticket: 'clientTicket',
  close_shift: 'Close register',
  company_address: 'Business address',
  company_registration_no: 'Company registration number',
  completed_payments: 'Completed payments',
  confirm: 'Confirm',
  confirm_cash_payment: 'Confirm payment amount',
  confirm_new_currency: 'Confirm currency change',
  confirm_open_orders_amount_change: 'Confirm change of open orders amount?',
  confirm_payment: 'Confirm payment',
  confirm_with_change: 'Confirm with change',
  confirm_with_tip: 'Confirm with gratuity',
  connect_barcode: 'Connect barcodescanner (Socket CHS)',
  connect_to_webshop: 'Connect your webshop?',
  connected_printer: 'Connected printers',
  contact_information: 'Contact information',
  contact_us: 'support@countrhq.com',
  create_product: 'Create product',
  currently_trial_mode_transaction_wiped_visit_dashboard: 'The POS is currently in test mode. Your transactions will be wiped every four hours.',
  currently_trial_mode_visit_dashboard: 'The POS is currently in test mode. You can switch to live mode by visiting your online dashboard.',
  customer: 'Customer',
  customer_card: 'Customer card',
  customer_info: 'Customer information',
  customer_instructions: 'Customer instructions',
  customer_lookup: 'Customer lookup',
  customer_lookup_error: 'Server error on customer lookup',
  customer_name: 'Customer',
  customer_not_valid: 'Customer not valid',
  customer_receipt: 'Customer receipt',
  customer_since: 'Customer since',
  Date: 'Date',
  deregister_terminal: 'De-Register Device',
  description_mandatory: 'A description is mandatory for cash-in and cash-out transactions',
  device_locked: 'Device locked',
  device_locked_enter_pin: 'This device is locked.\nPlease enter your admin PIN to unlock it',
  did_cheque_refund_succeed: 'Cheque returned to customer?',
  did_mobile_payment_succeed: 'Did mobile payment succeed?',
  did_mobile_refund_succeed: 'Did mobile refund succeed?',
  direct_connection: 'Directly connected',
  disabled_product: 'Disabled product',
  discount_currency: 'Discount ({{currency}})',
  do_you_want_remove_payment: 'Do you want to remove the payment?',
  download_adyen_app: 'Download Adyen App',
  download_izettle_app: 'Download iZettle App',
  download_payleven_app: 'Download Payleven App',
  download_sumup_app: 'Download Sumup App',
  download_yodo_app: 'Download Yodo Rocket app',
  downloading_categories: 'Downloading categories...',
  downloading_taxes: 'Downloading taxes...',
  downloading_transactions: 'Downloading transactions...',
  ecommerce_none: 'I\"ll create products myself',
  ecrId: 'ecrId',
  eft_transaction_code: 'EFTTxnID',
  email_invoice: 'Email Invoice',
  employee_name: 'Employee',
  employee_pin: 'Employee PIN',
  empty_cart: 'Empty Order',
  enable_staff_pin: 'Enable staff PIN login',
  enter_a_valid_search_value: 'Enter a valid search value',
  enter_api_key: 'Enter your Activation Key',
  enter_base_url: 'Enter URL (including "http://")',
  enter_merchant_auth_code_payconiq: 'Enter your Payconiq Authorization header',
  enter_merchant_iban_payconiq: 'Enter your Payconiq-registered IBAN',
  enter_merchant_id_payconiq: 'Enter your Payconiq Merchant ID',
  enter_order_name: 'Enter order name',
  enter_reseller_id: 'Enter your Reseller ID',
  enter_user_number: 'Enter your User Number',
  enter_user_password: 'Enter your User Password',
  error_adding_device: 'An error occurred during your device registration',
  error_refreshing_merchant: 'Error while retrieving account information.',
  error_retrieve_categories: 'Error while retrieving categories.\nPlease check your internet connection and try again',
  error_retrieve_products: 'Error while retrieving products.\nPlease check your internet connection and try again',
  error_retrieve_taxes: 'Error while retrieving taxes.\nPlease check your internet connection and try again',
  error_while_retrieving_results: 'Error while retrieving results',
  errorCode: 'errorCode',
  errorMessage: 'errorMessage',
  ersReference: 'ersReference',
  excess_amount: 'Excess amount',
  expected_amount: 'Expected amount',
  expected_change_gratuity: 'Expected change or gratuity',
  external_url: 'External URL',
  failed_cancel: 'Failed to cancel transaction',
  feature_only_android: 'This feature is available only for Android',
  find_terminal: 'Search for terminals',
  for_security_enter_password: 'For security reasons, please re-enter your password',
  for_this_device: 'For this device only',
  for_this_store: 'For this location only',
  four_inch: '4 inch',
  generate_keys: 'Generate keys',
  generating_keypair: 'Generating keypair...\nThis can take several minutes',
  generating_reports: 'Generating report...',
  generic_usb: 'Generic USB',
  get_your_free_pos: 'Get your POS at www.countrhq.com',
  gift_code: 'Gift code',
  gratuity_amount: 'Gratuity amount',
  gratuity_percentage: 'Gratuity percentage',
  group_items_in_cart: 'Group same items together in cart',
  shared_secret: 'SharedSecret',
  impossible_refresh_you_have_transactions: 'You have {{transaction_number}} transaction(s) not synced with the server.\nSync of these transactions will now be attempted in the background...',
  impossible_signout_you_have_transactions: 'Impossible to signout.\nYou have {{transaction_number}} transaction(s) not synced with the server.\nSync of these transactions will now be attempted in the background...',
  insert_printer_name: 'Please insert a (3 characters or more) name for your printer.',
  inserted_login_not_valid: 'Login incorrect',
  inserted_value_not_valid: 'Inserted value is not valid',
  installmentAmount: 'installmentAmount',
  invalid_credit_card_number: 'Credit card number must be 16-digits',
  invoiceReference: 'invoiceReference',
  invoiceStatus: 'invoiceStatus',
  ip_address: 'Address',
  is_business: 'Business details',
  is_correct_amount_tendered: 'Is {{amount}} the correct amount?',
  isDone: 'No more to load...',
  isError: 'Tap to try again...',
  isTap: 'Tap to load more...',
  join_thousands_of_merchants: 'Thousands of merchants have joined Countr. Today it\"s your turn. Sign up for free',
  language: 'Language',
  last_visit: 'Last visit',
  leave_tour: 'Leave tour',
  leaving_tour: 'Leaving tour...',
  loyal_customer: 'Loyal customer',
  mac_address: 'MAC address',
  mailing_list_permission: 'Add to mailing list',
  manual_mobile_payment: 'Mobile payment',
  manual_mobile_refund: 'Mobile refund',
  max_amount_exceeded: 'Amount exceeds the maximum allowed for this payment method',
  menu_item_12char: 'Menu item name cannot be more than 12 characters',
  menu_item_name: 'Menu item name (max. 12 chars)',
  merchant_receipt: 'Merchant receipt',
  merchantDisplayName: 'merchantDisplayName',
  merchantId: 'merchantID',
  mid: 'MID',
  mobile_number: 'Mobile number',
  mobile_payment: 'Mobile',
  mobile_payments: 'Mobile payments',
  new_price: 'New price ({{currency}})',
  no_available_addons_for_product: 'No available addons for the selected product.',
  no_connected_printer: 'No connected printer(s)',
  no_connected_terminal: 'No connected terminal(s)',
  no_customer_found: 'No customer found',
  no_customer_selected: 'No customer selected',
  no_printer_configured: 'No printer configured',
  no_printer_found: 'No printer found\nClick here if you have a USB-connected Star printer',
  no_product_in_cart: 'No product in the order',
  no_terminals_found: 'No terminals found',
  no_url_specified: 'Error, no URL specified.',
  none: 'None',
  note_mandatory_cash_difference: 'A note is mandatory if there is a difference in expected and actual amounts',
  note_optional: 'Note (optional)',
  numberOfInstallments: 'numberOfInstallments',
  numpad: 'Numpad',
  offline: 'Offline',
  ok: 'Okay',
  one_or_more_carts_are_frozen_finish_payment: 'One or more orders are frozen. Finish the payment before doing any action.',
  online: 'Online',
  open_market: 'Do you want to open the market?',
  open_register_before_transaction: 'You must open the register before you can do any transactions',
  open_shift: 'Open register',
  order_printer: 'Order printer',
  orderId: 'orderID',
  orderRef: 'orderRef',
  other_text: 'Other Text',
  paid: 'Paid',
  paid_so_far: 'Paid so far',
  paid_with_method: 'Paid with {{method}}',
  pan: 'PAN',
  panLastDigits: 'Pan Last Digits',
  panMasked: 'Pan Masked',
  PANSeq: 'PANSeq',
  partial_payment: 'Partial payment',
  partial_payment_not_allowed_for: 'Partial payments are not allowed for',
  partial_payment_paid_successfully: 'Partial payment has been paid successfully',
  pay_later: 'Pay Later',
  pay_later_allows_only_refund_all: 'With pay later, you can only refund all. For partial refunds please finish the payment.',
  pay_later_refund: 'Cancel Pay Later',
  pay_on_account: 'Pay on account',
  payconiq: 'Payconiq',
  paying_now: 'Paying now',
  payment_cancelled: 'Payment cancelled',
  payment_error: 'Payment error',
  payment_info: 'Payment info',
  payment_insert_card: 'Insert card',
  payment_method_not_configured: '{{payment_method}} not correctly configured on this device',
  payment_number: 'Payment Number',
  payment_processor_options: 'Payment processor options',
  payment_provider: 'Payment provider',
  payment_success: 'Payment successful',
  payment_terms_in_net_days: 'Payment terms (net days)',
  personal_information: 'Personal information',
  pickup_rewards: 'Pick up rewards',
  pin: 'PIN',
  please_insert_a_valid_amount: 'Please insert a valid amount',
  posEntryMode: 'posEntryMode',
  prefName: 'prefName',
  print: 'Print',
  print_also_product_details: 'Would you also like to print product details?',
  print_error: 'Print error',
  print_product_details: 'Print product details',
  print_successful: 'Print successful',
  printer_connect_desc: 'WiFi printers need to be connected to the same network as this device. \nBluetooth printers need to be paired via the System settings before being available here',
  printer_removed: 'Printer removed',
  printer_test: 'Printer test',
  printing_report: 'Generating and printing report...',
  proceed_with_next_payment: 'Proceed with the next payment.',
  processorName: 'processorName',
  product_is_disable_continue_anyway: 'The product you selected is not available (possibly out of stock). Do you want to add it to the order anyway?',
  product_sync: 'Product Sync',
  profile_average: 'Average purchase',
  protected_sections: 'Protected sections',
  receipt_printer: 'Receipt printer',
  redeem_reward: 'Redeem reward',
  referenceNumber: 'referenceNumber',
  refresh_customers: 'Refresh customer list',
  refresh_merchant: 'Refresh account information',
  refresh_transactions: 'Refresh transactions',
  refreshing_merchant: 'Refreshing account information...',
  refund_number: 'Refund Number',
  refunds_not_available_for_provider: 'Refunds not available for provider: {{payment_provider}}',
  register_terminal: 'Register Device',
  reissue: 'Reissue',
  remaining_payment: 'Remaining payment',
  remove_customer_from_cart: 'Remove from the order',
  remove_payment: 'Remove payment',
  remove_printer: 'Remove Printer',
  removing_products: 'Removing old products...',
  reopen_shift: 'Re-open register',
  replace_customer: 'Replace customer',
  report_by_shift: 'Enable daily open/close',
  report_printer: 'Report printer',
  requested_amount: 'Requested amount',
  reward: 'Rewards',
  round_cash_transactions: 'Round cash payments to nearest 0.05',
  save_printer: 'Save Printer',
  scan: 'Scan',
  scan_barcode_instruction_one: 'Scan the barcode to put Socket Bluetooth scanner into pairing mode',
  scan_barcode_instruction_two: 'In your device settings, ensure Bluetooth is turned on and then pair the Socket CHS scanner',
  scan_code_not_exist: 'Scanned code {{barcode}} not recognised',
  scan_qr_code: 'Scan QR code with payment app',
  scanner: 'Scanner',
  scanner_connected: 'Barcodescanner connected',
  scanner_disconnected: 'Barcodescanner disconnected',
  scanner_pair_completed: 'Barcodescanner pairing completed',
  search_for_customer: 'Search for customer',
  search_report: 'Search reports',
  search_transaction: 'Search transaction',
  searching_terminals: 'Searching for terminal(s)...',
  security_and_permissions: 'Security and permissions',
  select_one_payment_method: 'At least one payment method must be enabled',
  select_payment_method: 'Please select card AID from the list below',
  select_terminal: 'Select a terminal',
  send_email: 'Send email',
  send_to_another_customer: 'Send to another customer',
  send_to_current_customer: 'Send to current customer',
  send_to_customer: 'Send to customer',
  sending_receipt: 'Sending receipt...',
  server_error: 'Server error',
  session: 'Session',
  set_new_price: 'New price',
  setting_saved: 'Setting saved',
  settings_connected_terminals: 'Connected terminal',
  settings_printer_available_printers: 'Available printers',
  settlementDate: 'settlementDate',
  setup_admin_pin_in_settings: 'Set your admin PIN in Settings, under the Account tab.',
  shift_closed: 'The register has been closed',
  shift_opened: 'The register has been opened',
  shifts: 'Shifts',
  shopper_reference: 'ShopperReference',
  show_customer: 'Show customer info on receipt',
  show_employee: 'Show employee name on receipt',
  show_customer_payconiq: 'Please choose a checked-in customer or scan the customer\"s barcode / NFC token',
  show_new_product_tile: 'Show "create product" tile',
  show_password: 'Show password',
  sign_out_header: 'Are you sure?',
  sign_out_warning: 'Signing out may reset your app settings, including payment providers and printers. Please use the Lock function (with an Admin or Employee PIN) if you do not wish to clear your settings.',
  sign_up_to_print: 'Sign up for a free test account to print a receipt. No credit card required, cancel anytime.',
  sign_up_to_send_email: 'Sign up for a free test account to send an email receipt. No credit card required, cancel anytime.',
  signing_out: 'Signing out…',
  signup_for_mailing_list: 'Ask customers to subscribe to mailing list',
  sorry_report_not_available: 'Report not available.',
  start_using_pos: 'Start selling with Countr',
  state: 'State/Province',
  status: 'Status',
  store_powered_by: 'This location is powered by',
  street: 'Street',
  suggested_amount: 'Suggested amount',
  suggested_tip: 'Suggested gratuity',
  support: 'Support',
  telephone: 'Telephone',
  terminal_port: 'Port configured for terminal',
  terminal_selected: 'Terminal saved',
  terminalId: 'terminalId',
  test: 'This is a test',
  three_inch: '3 inch',
  tid: 'TID',
  Time: 'Time',
  tip: 'Gratuity',
  tip_added: 'Gratuity added',
  to_pay: 'To Pay',
  total_amount: 'Total amount',
  transaction_code: 'transactionCode',
  transaction_date: 'TransactionDate',
  transaction_finalized: 'Transaction finalised',
  transaction_pending: 'Transaction pending',
  transaction_started: 'Transaction started',
  transaction_succeeded: 'Transaction succeeded',
  transaction_time: 'Transaction time',
  transaction_timeout: 'Transaction timeout',
  transactionSeq: 'transactionSeq',
  transactionType: 'transactionType',
  try_it_now: 'Try it now',
  tsi: 'TSI',
  tvr: 'TVR',
  two_inch: '2 inch',
  uncategorized: 'No category',
  valid_phone_number: 'Valid phone number',
  validating_fields: 'Validating fields...',
  validation_in_progress: 'Validation in progress',
  verification_code: 'Verification code',
  verification_method: 'Verification method',
  waiting_for_customer: 'Waiting for customer',
  want_to_sync_products: 'Would you like to sync your products?',
  yodopay: 'YodoPaY',
  you_must_select_a_store: 'You must select a location.',
  add_name_device: 'Add a name to your tablet/phone to easily identify it later (mandatory)',
  card_number: 'CardNumber',
  change_language: 'Change language',
  choose_currency: 'Choose primary currency',
  clear_cart_after_finish: 'Reset order name after payment',
  confirm_new_language: 'Confirm new language?\nYou will need to completely close and restart the app to apply this change',
  customer_signature: 'Customer signature',
  day: 'Day',
  device_name_mandatory: 'Device name is mandatory',
  downloading_refunds: 'Downloading refunds...',
  email_report: 'Email report',
  email_successfully_sent_test: 'Test email sent to your account',
  enter_table_name: 'Enter table name',
  error: 'Error',
  error_retrieve_refunds: 'Error while retrieving refunds',
  error_retrieve_transactions: 'Error while retrieving transactions',
  for_this_device_see_dashboard: 'For this device only - see Dashboard for more reports',
  hide_new_sale: 'Hide \"New Sale\" screen after payment',
  last_transaction_result: 'Latest transaction result',
  merge_warning: 'This will merge the contents of these orders',
  month: 'Month',
  post_code: 'PostCode',
  print_order: 'Show send to order printer',
  products_added_removed_refresh: 'Products were added or removed - refresh list?',
  quarter: 'Quarter',
  refresh: 'Refresh',
  refreshing_products: 'Refreshing products...',
  removing_categories: 'Removing old categories...',
  removing_taxes: 'Removing old taxes...',
  select_interface_mandatory: 'Selecting an interface is mandatory',
  sms_successfully_sent_test: 'Test SMS sent to your account',
  splitOrderPrintByCategory: 'Split print of orders by category',
  stored_digitally: 'Stored digitally',
  stripe_id: 'stripeID',
  sync_support_info: 'Sync support info',
  syncing_support_info: 'Syncing support information...',
  table: 'Table',
  table_is_empty: 'Table is empty',
  tables_or_carts: 'Enable hospitality features',
  uncertain_card_payment_success: 'Uncertain card payment success',
  unknown_category: 'Unknown category',
  unsynced_transactions_report_incorrect: '{{transaction_number}} transaction(s) not synced - reports not up-to-date',
  update_terminal_firmware: 'Update terminal firmware',
  week: 'Week',
  year: 'Year',
  email_queued: 'Email queued',
  order_received: 'New / updated order received',
  go_to_orders: 'View orders',
  open_carts: 'Open orders',
  auto_print_order: 'Print unprinted orders on pay',
  auto_print_order_cart: 'Print unprinted orders on cart/table change',
  external: 'Other',
  no_printer_found_tcp: 'No printer found\nClick here to manually enter printer info',
  downloading_carts: 'Downloading orders...',
  pay_later_hybrid: 'Pay Later',
  error_retrieve_carts: 'Error while retrieving orders',
  no_ip_address: 'No address',
  building_number: 'Building number',
  confirm_manual_payment: 'Confirm manual payment',
  shipping_address: 'Shipping address',
  birth_date: 'Date of Birth',
  for_this_store_see_dashboard: 'For this location only - see Dashboard for more reports',
  customers: 'Customers',
  tickets_in: 'Tickets in',
  tickets_out: 'Tickets out',
  tickets_in_out: 'Tickets in/out',
  unsynced_transaction_cant_refund: 'Transaction not yet synced with server - refund not possible',
  show_stock_level: 'Display current stock level (if available)',
  available: 'in stock',
  stock_not_available: 'Stock not tracked',
  order_another_store: 'Order from another location',
  order_products: 'Enable ordering of products from other locations',
  trial_expired: 'Your trial period has expired. POS functionality has been disabled.',
  left_menu: 'Left navbar',
  right_menu: 'Right navbar',
  direct_pay_if_one_method: 'Auto-start payment if 1 method set',
  processing_vouchers: 'Processing loyalty and vouchers...',
  display_2_rows_of_categories: 'Display 2 rows of categories',
  unknown_barcodes_as_vouchers: 'Treat unrecognised barcodes as vouchers',
  validate_signature: 'Customer signature valid?',
  group_items_in_receipt_and_cart: 'Group same items in order and receipt',
  ask_customer_subscribe_email: 'Ask customer to provide email address',
  hide_categories: 'Hide categories tiles',
  hide_all_products_category: 'Hide All Products category tile',
  provisional_receipt: '*** PROVISIONAL ***',
  reprint: '*** REPRINT ***',
  block_reprints: 'Do not allow reprint of items',
  reports_at_store_level: 'Show reports for location, not device',
  max_addon_qty_exceeded: 'Maximum of {{maxQty}} addons allowed for this group',
  min_addon_qty_unmet: 'Minimum of {{minQty}} addons required from {groupName}',
  press_button_to_wake_terminal: 'Please press the green button to wake the PIN terminal',
  payments: 'Payments',
  average_sale_duration_mins: 'Average sale duration (mins)',
  save_order_move_next: 'Save order, continue to next empty table',
  max_orders_reached: 'Maximum number of open orders reached',
  show_open_orders: 'Show open orders first in order list',
  tender_created: 'Transaction started',
  invalid_request: 'Invalid request',
  already_processed: 'Transaction already processed',
  invalid_terminal: 'Invalid payment terminal',
  network_error: 'Network error',
  bad_credentials: 'Invalid credentials',
  server_issue: 'Cannot contact server',
  pin_entered: 'PIN entered',
  initial: 'Initialising',
  card_inserted: 'Card inserted',
  card_swiped: 'Card swiped',
  wait_app_selection: 'Awaiting application selection',
  wait_amount_adjustent: 'Awaiting amount adjustment',
  ask_gratuity: 'Ask for gratuity',
  gratuity_entered: 'Gratuity entered',
  ask_dcc: 'Ask for DCC',
  dcc_accepted: 'DCC accepted',
  dcc_rejected: 'DCC rejected',
  processing_tender: 'Processing tender',
  wait_pin: 'Awaiting PIN',
  provide_card_details: 'Provide card details',
  card_details_provided: 'Card details provided',
  receipt_printed: 'Receipt printed',
  referral: 'Referral',
  referral_checked: 'Referral checked',
  check_signature: 'Check signature',
  signature_checked: 'Signature checked',
  additional_data_available: 'Additional data available',
  transaction_acknowledged: 'Transaction acknowledged',
  device_already_registered: 'Device already registered',
  add_in_progress: 'Already adding device',
  app_not_registered: 'App not registered',
  unknown_error: 'Unknown error',
  timeout: 'Timed out',
  verify_failed: 'Could not verify device',
  identify_failed: 'Could not identify device',
  registration_error: 'Registration error',
  sync_issue: 'Could not sync device',
  show_select_customer: 'Show customer selector',
  store_name_mandatory: 'Location name is mandatory',
  choose_store_dashboard_for_more: 'Choose location - more can be added via the back-office dashboard',
  recovering_incomplete_payment: 'Recovering incomplete payment - this can take up to 30 seconds',
  allow_negative_sale: 'Allow negative sale amount',
  move_current_table_to: 'Move Current Table ({{table_name}}) TO',
  store: 'Location',
  print_specific_categories: 'Print specific categories',
  customer_display_attached: 'Customer display attached',
  sale: 'Sale',
  reps_and_waste_button_in_cart: 'Show reps and waste button on order',
  small_product_tiles: 'Reduce size of product tiles',
  large_product_tiles: 'Increase size of product tiles',
  show_product_price_on_tile: 'Product price on tile',
  printer_roles: 'Printer Roles',
  paper_size: 'Paper Size',
  customer_display: 'Customer Display',
  enable_realtime_updates: 'Enable real-time updates from server',
  show_open_orders_first: 'Show open orders first in order list',
  blank_lines_order_ticket: 'Blank lines at top of order ticket',
  blank_lines_bottom_order_ticket: 'Blank lines at bottom of order ticket',
  scan_card: 'Scan card',
  scan_nfc_or_barcode: 'Scan NFC card or code',
  show_product_keypad: 'Show keypad on order',
  extra_payment_methods: 'Extra payment methods',
  failed_recovery: 'Failed to recover payment',
  blank_line_between_order_items: 'Blank line between order ticket items',
  look_up_customers_using_barcodes: 'Add customer to order using scanner',
  error_loading_configuration: 'Error loading configuration',
  error_verifying_payment_terminal: 'Error verifying PIN terminal',
  error_identifying_payment_terminal: 'Error identifying PIN terminal',
  error_saving_configuration: 'Error saving configuration',
  conflicting_server_modes: 'Pin terminal and server have conflicting modes (TEST vs PRODUCTION)',
  nothing_to_print: 'No items to print',
  large_item_name: 'Increase size of printed item',
  print_ean_sku: 'Print EAN and SKU on receipt',
  select_category_on_startup: 'Select category once on startup',
  split_tickets_item: 'Split print of orders by item',
  split_tickets_amount: 'Split print of orders by quantity',
  split_print: 'Split print',
  refreshing_customers: 'Refreshing customers...',
  downloading_customers: 'Downloading customers...',
  error_retrieve_customers: 'Error while retrieving customers.\nPlease check your internet connection and try again',
  server_error_please_retry: 'Server error. Please retry',
  vibrate_on_click: 'Vibration feedback on item click',
  keep_screen_on: 'Do not allow screen to sleep',
  refund_same_method: 'Force refunds to be with original payment method',
  cannot_refund_more_than_paid: 'Can only refund a maximum of {{max_amount}} via {payment_method}',
  tablet_date_incorrect: 'Device date incorrectly set - possible security risk',
  extra_non_revenue_info: 'Ask for extra information for non-revenue items',
  enter_extra_info: 'Enter extra item information',
  giftcard_or_reference_no: 'Giftcard or reference number',
  inserted_code_not_valid: 'Code incorrect',
  validate_user: 'User validation ({{user_address}})',
  enter_validation_code: 'Please enter the validation code you received by SMS or email',
  tax_rate: 'Tax Rate',
  pretax: 'Net',
  deposit: 'Deposit',
  can_not_refund_with_different_provider: 'Can not refund {{txn_payment_method}} transactions using {selected_payment_method}',
  external_scanner_connected: 'External scanner connected',
  cancelling: 'Cancelling',
  sumup: 'SumUp',
  ccv: 'CCV',
  payplaza: 'PayPlaza',
  worldline: 'Worldline',
  adyen: 'Adyen',
  rabosmartpin: 'Rabo SmartPin',
  exchange: 'Exchange',
  ask_exchange: 'Allow product exchanges',
  exchange_error: 'Exchange error',
  exchange_items: 'Exchange items',
  exchangeable: 'Exchangeable',
  to_be_exchanged: 'To be exchanged',
  exchange_all: 'Exchange all',
  negative_sale_amount: 'Amount to pay is less than 0',
  download_rabosmartpin_app: 'Download the Rabo SmartPin App',
  transaction_disabled: 'Third party payments not allowed on this contract',
  transaction_in_progress: 'Another payment is already in progress',
  refund_offline: 'Refunds are not possible in restricted mode',
  refund_not_allowed: 'Refunds are not allowed for the logged-in user',
  cash_forbidden: 'Cash payments not allowed on this contract',
  notify_new_online_order: 'Show notification when new online order received',
  print_new_online_order: 'Automatically print new online orders when received',
  delivery_information: 'Delivery Information',
  voucher_already_processed: 'Voucher already processed',
  no_applicable_items_in_cart: 'No applicable items found in order',
  discount_applied: 'Discount applied',
  invalid_voucher: 'Invalid voucher',
  allow_voucher_scan: 'Check for vouchers using barcode scanner',
  enable_drop_shipping: 'Enable drop-shipping',
  ask_no_covers_or_takeaway: 'Always ask table configuration',
  table_configuration: 'Table type',
  num_covers: 'Number of guests',
  takeaway: 'Takeaway',
  guests: 'Guests',
  show_floorplan_after_sale: 'Show floorplan after each sale',
  lock_after_x_minutes: 'Lock after x minutes of inactivity',
  show_deposit_items: 'Show deposit items',
  pickup: 'Pickup',
  delivery: 'Delivery',
  transaction_fee: 'Transaction Fee',
  print_description: 'Print item description on receipt',
  only_cash_for_negative_amounts: 'Only Cash or manual Card Payment may be selected when amount is less than 0',
  phone_number_example: 'Phone - e.g. 31611111111',
  whatsapp_not_available_on_this_device: 'WhatsApp not installed on this device',
  receipt_not_yet_synchronised: 'Transaction syncing with server - please try again in a few moments',
  notify_online_orders_with_sound: 'Play sound when online orders received',
  restrict_online_orders_by_floor: 'Only accept online orders for certain floors',
  remove_product: 'Remove Product',
  admin_access_needed: 'This function is only available for Admin users',
  customer_or_employee_name: 'Customer or employee name',
  show_cart_on_left: 'Show order on left-hand side',
  groupOrderPrintByCategory: 'Group print of orders by category',
  handpoint_shared_secret: 'SharedSecret',
  fiscal_offline: 'For fiscal compliance reasons, POS must be online to process transactions',
  unsynced_transaction_cant_reissue: 'Transaction not yet synced with server - reissue not possible',
  fiscal_info: 'Fiscal information',
  create_bundle: 'Create bundle',
  select_items_to_bundle: 'Select items to bundle',
  bundle_price: 'Bundle price',
  create_bundle_items: 'Create a bundle of products in the order',
  bundle: 'Bundle',
  giftcard_or_reference_no_mandatory: 'Giftcard- or reference-number mandatory',
  show_camera: 'Use device camera (if available) as scanner',
  switch_camera: 'Switch camera',
  camera: 'Camera',
  print_qr_on_receipts: 'Print QR code on receipts',
  no_floorplans_available: 'No floorplans available',
  receive_online_orders_for_multiple_stores: 'Receive online orders for multiple locations',
  inventory: 'Inventory',
  description: 'Description',
  brand: 'Brand',
  allergens: 'Allergens',
  hide_total_num_items_on_order: 'Hide total number of items on order ticket',
  loss: 'Loss',
  stolen: 'Stolen',
  own_use: 'Own Use',
  giveaway: 'Giveaway',
  breakage: 'Breakage',
  overview: 'Overview',
  average: 'Average',
  busiest_hours: 'Busiest Hours',
  rotation_time_in_secs: 'Image rotation time in seconds',
  show: 'Show',
  uploaded: 'uploaded',
  videos: 'Video',
  photos: 'Photos',
  display_video_or_picture: 'Display video, photos or pdf files',
  store_logo: 'Location logo',
  add_selected_addon: 'Add / Remove addons',
  add_selected_to_stores: 'Add to / Remove from locations',
  add_selected_categories: 'Add / Remove categories',
  select_categories: 'Select categories',
  add_selected_addon_group: 'Add / Remove addons group',
  company: 'Company',
  assortment: 'Assortment',
  sales_history: 'Sales History',
  employees: 'Employees',
  extensions: 'Extensions',
  desktop: 'Desktop',
  helpdesk: 'Helpdesk',
  specific: 'Specific',
  no_data_for_this_period: 'No data found for this period.',
  gross_revenue: 'Gross revenue',
  avg_per_transaction: 'Avg. per transaction',
  from: 'from',
  select_period: 'Select period',
  date_start: 'Start date',
  date_end: 'End date',
  select_store: 'Select location',
  select_stores: 'Select locations',
  all_stores: 'All locations',
  all_employees: 'All employees',
  all_devices: 'All devices',
  no_transactions_in_this_period: 'No transactions in this period.',
  transactions_in_this_period: 'Transactions in this period.',
  report_graph: 'Report Graph',
  no_reports_found_for_this_period: 'No Reports Found For This Period',
  top_sales: 'Top Sales',
  top_sellers: 'Top-sellers',
  bottom_sellers: 'Bottom-sellers',
  no_top_sales_for_this_period: 'No Top-Sellers for this period',
  best_selling_products_for_this_period: 'Best-Selling products for the period',
  save_email: 'Save email',
  new_password: 'New password',
  verify_password: 'Verify password',
  save_password: 'Save password',
  contact: 'Contact',
  name_first: 'First name',
  name_last: 'Last name',
  zip_code: 'Zip code',
  region: 'Region',
  billing: 'Billing',
  optional: 'Optional',
  business_name: 'Business name',
  vat: 'Vat',
  btw: 'VAT',
  save_profile_info: 'Save profile info',
  integrations: 'Integrations',
  connect_to_your_existing: 'Connect to your existing',
  invoices: 'Invoices',
  code: 'Code',
  isPaid: 'isPaid',
  invoice_paid: 'Invoice Paid',
  actions: 'Actions',
  stores: 'Locations',
  updated: 'Updated',
  updated_at: 'Updated at',
  create: 'Create',
  info: 'Info',
  currencies: 'Currencies',
  save_edit_details: 'Save and edit details',
  assigned_employees_of_this_stores: 'Assigned employees of this location:',
  not_assigned_employees: 'Not assigned employees:',
  add_payment: 'Add payment',
  payment_name: 'Payment Name',
  printer_name: 'Printer name',
  edit_storess_main_details: 'Edit locations\"s main details.',
  edition: 'Edition',
  stores_address_details: 'location\"s address details.',
  stores_contact_details: 'location\"s contact details.',
  assign_employees_for_your_stores: 'Assign employees to your location.',
  create_custom_payment_methods_for_your_store: 'Create custom payment methods for your location.',
  devices: 'Devices',
  device: 'Device',
  hardware_info: 'Hardware Info',
  uuid: 'UUID',
  manufacturer: 'Manufacturer',
  os_name: 'OS Name',
  os_version: 'OS Version',
  pos_version: 'POS Version',
  receipt_layout: 'Receipt Layout',
  table_layout: 'Table Layout',
  default_receipt: 'Default receipt',
  email_receipt: 'Email receipt',
  change_image: 'Change profile image',
  Tuesday: 'Tuesday',
  june: 'June',
  your_store_address: 'Your location address',
  edit: 'Edit',
  your_text_here: 'Your text here',
  cheap: 'Cheap',
  expensive: 'Expensive',
  client_receipt: 'Client receipt',
  transaction_agreement: 'Transaction agreement',
  your_footer_message_here: 'Your footer message here',
  image: 'Image',
  price: 'Price',
  edit_products_main_details: 'Edit product\"s main details.',
  details: 'Details',
  visible: 'Visible',
  in_pos: 'in POS',
  cost: 'Cost',
  cost_price: 'Cost price',
  delivery_price: 'Delivery price',
  upload_progress: 'Upload progress',
  change_products_image: 'Change product\"s image',
  variants: 'Variants',
  variant: 'Variant',
  units: 'Units',
  alert: 'Alert',
  additional: 'Additional',
  ledger: 'Ledger',
  subledger: 'Subledger',
  addon: 'Addon',
  addon_groups: 'Addon Groups',
  addon_group: 'Addon Group',
  add_delete_and_edit_products_variants: 'Add, Delete and edit product\"s variants.',
  assing_product_to_store: 'Assign product to a specific location (if no location is selected, product will be available in every location).',
  products_addtional_fields: 'Product\"s addtional fields.',
  manage_addons_group: 'Addons Group',
  sold_by_weight: 'Sold by Weight',
  dynamic_price: 'Dynamic Price',
  dynamic: 'Dynamic Price',
  min_age: 'Minimum age',
  packaging: 'Packaging',
  contents: 'Contents',
  form: 'Form',
  products_packaging_and_suppliers_details: 'Product\"s packaging and supplier details.',
  stock: 'Stock',
  management: 'Management',
  count: 'Count',
  filter_name: 'Begin typing to filter by name...',
  find_products_category: 'Find products by category',
  find_products_addons: 'Find products by addons',
  in_stock: 'In stock',
  order_below: 'Order Below',
  order_quantity: 'Order Quantity',
  advance: 'Advance',
  view_history: 'View history',
  click_here_to_select_source: 'Click here to select source',
  record_missing_item: 'Record missing item',
  lost: 'Lost',
  current_stock: 'Current stock',
  current_report: 'Current report',
  view_stock_history: 'View Stock History',
  color: 'Color',
  duplicate_item: 'Duplicate item',
  want_duplicate_item: 'Do you want to duplicate this item?',
  delete_item: 'Delete item',
  want_delete_item: 'Do you want to delete this item?',
  categories_visibility_changed: 'Visibility changed',
  change_visibility: 'Change visibility',
  category: 'Category',
  parent: 'Parent',
  select: 'Select',
  rate: 'Rate',
  delete_selected_items: 'Delete selected items',
  aplly_diff_rates: 'Apply different tax rate for different countries',
  countries_added: 'Countries added',
  groups: 'Groups',
  group: 'Group',
  standard: 'Default',
  default_text: 'Default text',
  business_rules: 'Business Rules',
  business_rule: 'Business Rule',
  title: 'Title',
  is_active: 'isActive',
  startdate: 'StartDate',
  enddate: 'EndDate',
  rules: 'Rules',
  rule: 'Rule',
  edit_business_rules_main_details: 'Edit business rule\"s main details.',
  active: 'Active',
  time_start: 'Start Time',
  time_end: 'End Time',
  select_specific_days: 'Select specific day(s)',
  conditions: 'Conditions',
  condition: 'Condition',
  change_business_rules_conditions: 'Change business rule\"s conditions.',
  change_business_rules_outcome: 'Change business rule\"s outcome.',
  minimum_amount: 'Minimum amount',
  minimum: 'Minimum',
  maximum: 'Maximum',
  maximum_amount: 'Maximum amount',
  ignore_for: 'Ignore for',
  outcome: 'Outcome',
  voucher_outcome: 'voucher outcome',
  new_price_rule: 'New price for business rule',
  min_pack_value: 'Min pack value',
  max_pack_value: 'Max pack value',
  enable: 'Enable rules',
  disable: 'Disable rules',
  all: 'All',
  update_time: 'Update start/end time',
  update_date: 'Update start/end date',
  catalog_order: 'Catalog Order',
  drag_and_drop_items_to_reorder: 'Drag and drop items to reorder',
  see_reports: 'You can see your reports in the dashboard by selecting',
  generate_report: 'Generate Report',
  or: 'OR',
  send_report: 'You can send raw data to your email',
  send_report_button: 'Send Report',
  send_to: 'Send to',
  recipient: 'recipient',
  available_reports: 'Reports available for this time period',
  select_currency_text: 'Please select a currency or a specific location.',
  tables: 'Tables',
  revenue: 'Revenue',
  see_revenue: 'See your Gross Revenue, Cost, Taxes and Profit',
  net_revenue: 'Net revenue',
  profit: 'Profit',
  see_transaction: 'See your Transaction methods, Quantity and Total Value',
  see_payments: 'See your Payment methods, Quantity and Total Value',
  see_refunds: 'See your Refund methods, Quantity and Total Value',
  see_taxes: 'See your Taxes, Quantity and Total Value',
  brands: 'Brands',
  channels: 'Channels',
  channel: 'Channel',
  see_brands: 'See your Products by Brand, Quantity and Total Value',
  see_channels: 'See your Products by Channel, Quantity and Total Value',
  see_categories: 'See your Categories, Quantity and Total Value',
  click_details: 'Click here to see details',
  report_select_other_period: 'Please select another time period to see available report',
  created: 'Created',
  discounts: 'Discounts',
  add_discount: 'Add discount',
  discount_percent: 'Discount percent',
  register_opertations: 'Register Operations',
  operations: 'Operations',
  first: 'First',
  last: 'Last',
  phone: 'Phone',
  permission_to_contact: 'Permission to contact',
  identifiers_type: 'Customer identifier type',
  identifiers_value: 'Identifier value',
  shipping: 'Shipping',
  diff_billing: 'if different to billing',
  rights: 'Rights',
  employee: 'Employee',
  token: 'Token',
  uninstall_ext: 'Uninstall extension',
  active_ext: 'This extension is active',
  subscribe: 'Subscribe',
  remove_ext_msg: 'Are you sure you want to remove this extension ?',
  no_thanks: 'No thanks',
  subs_to_ext: 'Subscribe To Extensions',
  period: 'Period',
  payment_details: 'Payment Details',
  card_name: 'Name on card:',
  paymnet_method: 'Payment Method:',
  code_country: 'Country Code:',
  diff_way_pay_msg: 'Or choose a different way to pay',
  phone_support: 'Phone Support',
  support_msg: 'Get in touch with customer support for assistance:',
  email_support: 'E-mail Support',
  support_email: 'Support e-mail:',
  info_email: 'Info e-mail:',
  sales_email: 'Sales e-mail:',
  page: 'page',
  dark_theme: 'Dark Theme',
  report_spec_time: 'Generate report with a specific time',
  vat_btw_no: 'VAT/BTW No.',
  store_unable_delete: 'Unable to delete the location because it has linked devices. Please reassign any devices before deleting the location.',
  important: 'Important:',
  store_device_delete_msg: 'Please be sure that you are logged out from this device before you delete it.',
  email_test_receipt: 'Email a test receipt',
  merchant: 'Merchant',
  per_item: 'per item',
  img_width_msg: 'Maximun allowed file width is 250px.',
  err_img_msg: 'Error: The image width must not exceed 250px',
  rules_actions: 'Rule Actions',
  enable_all: 'Enable all business rules',
  want_enable_all: 'Do you want to enable all business rules?',
  disable_all: 'Disable all business rules',
  want_disable_all: 'Do you want to disable all business rules?',
  product_cat_error: 'Please choose a category',
  field_not_empty: 'Field must not be empty.',
  drop_files: 'Drop your file here to upload the image.',
  save_variant_before_stock: 'Save the new variant before editing stock.',
  no_employees: 'You do not have any employees. Please create employees',
  here: 'here',
  no_stock: 'There are no products in this location with stock tracked',
  product_exists: 'This product exists only in',
  add_units_stock: 'Adding units to stock',
  remove_units_stock: 'Removing units from stock',
  saving: 'Is saving',
  transfer_units: 'Add amount of units to transfer',
  remove_units_store: 'Add amount of units to remove from this location',
  notification: 'notification',
  set_new_start_end_time: 'Set new start/end time',
  set_new_start_end_date: 'Set new start/end date',
  by_email: 'by email',
  charts_graphs: 'Charts/Graphs',
  compare_reports: 'Compare reports',
  new_report: 'New Report',
  basic: 'Basic',
  no_acount_to_send_report: 'You cannot send this report because your account is',
  upgrade_account_msg: 'You need to update your account!',
  gen_report_msg: 'You can generate your report and see the results in the dashboard',
  disabled_report: 'Reports disabled while invoice(s) open',
  please_check: 'Please check your',
  email_wrong_format: 'Email format is not correct.',
  bar_chart: 'Bar Chart',
  method: 'Method',
  total_taxed_amount: 'Total taxed amount',
  total_price_product: 'Total price of products',
  enter_value: 'You must enter a value',
  not_valid_mail: 'Not a valid email',
  enter_email: 'Enter your email',
  unit_price: 'Unit Price',
  extras: 'Extras',
  monthly_billed: 'monthly billed',
  removing_ext: 'This extension will be removed next billing cycle',
  local_ip: 'Local IP',
  printer_ip_ad: 'Printer ip address',
  printer_categories: 'Printer categories',
  printers: 'Printers',
  payment_processor: 'Payment Processor',
  question: 'Do you have a question ?',
  check_here: 'Check here our',
  knowledge_center: 'Knowledge centre.',
  support_articles_msg: 'We have a number of support articles that can help you get started, create products, connect your webshop and more...',
  support_ques: 'If you still have not found the answer you want, you can get',
  in_touch: 'in touch with us',
  select_floorplan: 'Select Floorplan',
  floors: 'Floors',
  add_floorplan: 'Add Floorplan',
  delete_floorplan: 'Delete Floorplan',
  floorplan: 'Floorplan',
  add_tables: 'Add tables',
  sign_up_msg: 'Thousands of merchants have joined Countr. Today it’s your turn. Sign up for free.',
  user_required: 'Username is required.',
  name_required: 'Name is required.',
  company_name_required: 'Company Name is required.',
  phone_required: 'Phone number is required.',
  pass_required: 'Password is required.',
  already_account: 'Already have an account?',
  accept_sign_up: 'By signing up I accept the',
  terms_conditions: 'Terms and Conditions',
  policy: 'Privacy Policy',
  licence_agree: 'License Agreement',
  your_name: 'Your name',
  company_web: 'Company website (not mandatory)',
  forgot_pass: 'Forgot password?',
  login: 'Login',
  no_account_link: 'Don\"t have an account?',
  email_required: 'Email is required.',
  send_link: 'Send link',
  download_file: 'Download File',
  account_number: 'Account number',
  me_rules: 'Rule',
  by_volume: 'Top-selling products (by volume) this period',
  number_sold: 'Number sold',
  by_volume_bottom: 'Bottom-selling products (by volume) this period',
  create_compare_report: 'Create a new report to compare',
  last_per: 'Last',
  no_per_data: 'has no available reports',
  create_new_rep: 'Create new report',
  reset_pass_text: 'Enter your email address to reset your password',
  enter_bus_info: 'Please enter your business information',
  organization: 'Organization name',
  mandatory_fields: 'All fields are mandatory !!!',
  invalid_eu: 'Invalid VAT Number For EU Country',
  trial_expires: 'Your trial expires in',
  select_package: 'Select package',
  excluse_vat: 'exclusive of VAT',
  instead: 'instead of',
  save_payment: 'Save this payment method for future transactions',
  procced_paymet: 'Proceed to payment',
  selected_package: 'Selected Package',
  selected_ext: 'Selected extensions',
  old_dashboard: 'Back to Legacy Dashboard',
  subscription_msg: 'Congratulations, you are about to go live with your location! Your transactions will be saved forever and all test branding will disappear. Before you go live, make sure to choose the extensions you want to continue using.',
  transfer_from: 'Transfer units from',
  customer_purchase: 'Customer Purchase',
  total_spend: 'Total spend',
  last_customers_transactions: 'Cusomer\"s most recent transactions',
  email_recipient: 'Email recipient',
  show_revenue: 'Show revenue',
  show_sales: 'Show sales',
  visits: 'Total visits',
  select_action: 'Select action',
  show_receipt: 'Show receipt',
  set_values_for: 'Set values for',
  update: 'Update',
  select_addons_group: 'Select addons group',
  select_addon: 'Select addon',
  all_addon_groups_are_in_use: 'List is empty, all addon groups are in use',
  store_hours: 'Opening hours',
  opening_hours: 'Opening hours',
  no_opening_hours: 'No opening hours',
  set_opening_hours: 'Set opening hours',
  delivery_hours: 'Delivery hours',
  no_delivery_hours: 'No delivery hours',
  set_delivery_hours: 'Set delivery hours',
  ordering_hours: 'Ordering hours',
  no_ordering_hours: 'No ordering hours',
  set_ordering_hours: 'Set ordering hours',
  takeaway_hours: 'Takeaway hours',
  no_takeaway_hours: 'No takeaway hours',
  set_takeaway_hours: 'Set takeaway hours',
  delivery_zones: 'Delivery zones',
  no_delivery_zones: 'No delivery zones',
  set_delivery_zones: 'Set delivery zones',
  Monday: 'Monday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  set_opening_store: 'Set opening hours for the location',
  set_delivery_store: 'Set delivery hours for the location',
  set_ordering_store: 'Set ordering hours for the location',
  set_takeaway_store: 'Set takeaway hours for the location',
  set_zones_store: 'Set delivery zones for the location',
  no_data_added: 'No data added',
  add_hours: 'Add hours',
  add_zones: 'Add delivery zones',
  order_sub: 'Sort subcategories',
  new_item: 'new',
  tips: 'tips',
  see_busiest_hours: 'See the busiest hours based on the period you selected',
  see_employee_transactions: 'See transactions per employee for the selected period',
  see_employee_tips: 'See tips per employee for the selected period',
  see_employee_refunds: 'See refunds per employee for the selected period',
  see_customer_transactions: 'See transactions per customer type for the selected period',
  see_customer_refunds: 'See refunds per customer type for the selected period',
  new_customer: 'New customer',
  returning_customer: 'Returning customer',
  no_customer: 'No customer data',
  reps_waste: 'Reps and waste',
  see_reps_waste: 'See reps and waste by reason',
  see_employee_reps_waste: 'See reps and waste by employee',
  employee_report_graph: 'See employees sales per time',
  not_sold: 'Not sold',
  non_selling_products_this_period: 'Non-selling products this period ( Products with 0 sales)',
  compare_stores: 'Compare locations',
  average_amount_spent: 'Average amount spent',
  average_amount_refunded: 'Average amount refunded',
  bundle_parts: 'Bundle parts',
  create_giftcard: 'Create giftcard',
  giftcards: 'Giftcards',
  select_graphs_type: 'Select graph type',
  see_discount_percentage: 'See transactions with percentage discount',
  see_discount_by_product_percentage: 'See discount given to products ( numeric discounts are calculated as percentage )',
  discount_percentage: 'Discount (percentage)',
  discount_numeric: 'Discount (numeric)',
  see_discount_numeric: 'See transactions with numeric discount',
  number_of_discounts: 'and the number of times this discount occurred on product or order level',
  delivery_cost: 'Delivery cost',
  total_cash_after_tips_and_change: 'Total cash after tips and change',
  assigned_stores: 'Assigned locations',
  chat_iframe: 'Chat',
  mobile_phone: 'Mobile Phone',
  notifications: 'Notifications',
  annualy_billed: 'annually billed',
  download_stock: 'Download products with stock',
  download_all_stock: 'Download all products',
  no_stock_products: 'Set Products without stock',
  go_products_with_stock: 'Go to products with stock',
  save_all_changes: 'Save all changes',
  need_track_stock: 'You need to track stock to edit.',
  track_stock: 'Track stock',
  set_min_man: 'Set min and max for the specific addon groups',
  duplicate: 'Duplicate',
  clear_unsaved: 'Clear all changes',
  select_allergens: 'Select allergens',
  translate_categories: 'Translate categories',
  translate_groups: 'Translate groups',
  add_cat_translation: 'Add translations to category',
  add_group_translation: 'Add translations to group',
  assigned_groups: 'Assigned groups',
  extension: 'Extension',
  vouchers: 'Vouchers',
  show_open_carts_from_store: 'Show open orders from location',
  ean: 'Barcode (EAN)',
  sku: 'SKU',
  want_delete_all_items: 'Do you want to delete all selected items?',
  want_to_change_visibility: 'Do you want to change visibility of all selected items?',
  import_export: 'Import/Export',
  methods: 'Payment Method',
  stored_payment_info: 'See your payment method information',
  stored_payment: 'stored Payment Methods',
  report_spec_zone: 'Select a different timezone',
  no_stock_stores: 'You don\"t have any locations. Please create a location and assign products to the inventory.',
  create_custom_payment: 'Create custom payment methods for your location.',
  min_chars: 'at least 3 characters',
  enable_deposit: 'Enable deposit',
  enable_daily_report: 'Enable daily report',
  reorder_email: 'Stock Reorder Email',
  enable_non_revenue: 'Set as non-revenue',
  total_visits: 'Total visits',
  how_it_works: 'How it works',
  info_text_catalog_order_one: '•  You can drag and drop your products by placing the cursor above an item and holding down the left mouse button.',
  info_text_catalog_order_two: '•  To place a product on a different page, place the cursor above the product, right click and select the page you wish to send it to.',
  verify_dialog: 'A verification code has be sent to your e-mail address.',
  verify_proceed_dialog: 'Please select an industry type of your business and then proceed to verify your account.',
  export_customer: 'Export customer info',
  disabled_floor: 'Floor disabled',
  filter_by_category: 'Filter by category',
  current_timezone: 'Local timezone:',
  important_note: 'Important note:',
  transaction_note: 'You will not be charged, but we need your payment details so that we can validate your request to go live.',
  translate_name: 'Set translation for the selected languages',
  translate_tool: 'Give translation to name',
  payment_method_note: 'You can only delete the payment method and not add or edit. You can add a new payment method when you pay',
  add_new_payment: 'or adding a new',
  no_payment_method: 'There is no stored payment method',
  daily_report: 'Daily report hour (24-hour clock)',
  it_seems_store: 'It seems location',
  no_stock_items: 'does not have enough items in stock. Are you sure you want to continue?',
  export_file_finished: 'Your Export file finished, here is your download link:',
  import_file_finished: 'Your Import file finished successfully. Products total:',
  stock_import_file_finished: 'Your Stock Importer file finished successfully.',
  error_stock_finished: 'An error occured, please check your file format and content',
  import_file_init: 'We are processing your file. You will receive a notification when it is ready.',
  export_file_init: 'We are processing your file. You will receive a notification when it is ready.',
  create_voucher: 'Create voucher',
  total_uses: 'Usable amount',
  scope: 'Scope',
  numeric_discount: 'Numeric discount',
  start_date: 'Start date',
  expiry_date: 'Expiry date',
  products_categories: 'Products or Categories',
  search_products: 'Search products',
  selected_products: 'Selected products',
  search_categories: 'Search categories',
  selected_categories: 'Selected categories',
  selected_items: 'Selected items',
  local_prices: 'Local prices',
  translate: 'Translate',
  value: 'Value',
  code_type: 'Code type',
  non_revenue: 'Non revenue',
  giftcard_product_alert: 'Gift card product should always be a non revenue product.',
  multi_countries_tax_rules: 'Multi-country tax rates',
  countries_rates: 'Country rates',
  edit_store_themes: 'Edit location\"s themes (Only available for Countr Kiosk)',
  themes: 'Themes',
  font: 'Font',
  current_theme: 'Current theme',
  add_theme: 'Add theme',
  delete_theme: 'Delete theme',
  body_background: 'Background color',
  body_text: 'Text color',
  primary_text: 'Primary text color',
  secondary_text: 'Secondary text color',
  header: 'Header background color',
  header_text: 'Header text color',
  toolbar: 'Toolbar background color',
  toolbar_text: 'Toolbar text color',
  button_primary_text: 'Primary button text color',
  button_primary_background: 'Primary button background color',
  button_secondary_text: 'Secondary button text color',
  button_secondary_background: 'Secondary button background color',
  button_neutro_text: 'Neutro button text color',
  button_neutro_background: 'Neutro button background color',
  isActive: 'isActive',
  Wednesday: 'Wednesday',
  answered: 'answered',
  common: {"welcome":"Hello {{name}}, you have {{count}} new {{messages}}!"},
  username: 'Username',
  show_price: 'Show Price',
  companyid: 'Business name',
  invalid_merchantid: 'Invalid contact person',
  invalid_businessid: 'Invalid business name',
  all_categories: 'All categories',
  close_search_product: 'Close search and remove search filter',
  firstid: 'First name',
  lastid: 'Last name',
  storeid: 'location name',
  enter_a_printerid: 'Enter a printer name',
  enter_orderid: 'Enter order name',
  enter_tableid: 'Enter table name',
  customerid: 'Customer',
  insert_printerid: 'Please insert a (3 characters or more) name for your printer.',
  not_paid: 'Not paid',
  menu_itemid: 'Menu item name (max. 12 chars)',
  employeeid: 'Employee',
  deviceid: 'Device',
  addid_device: 'Name your device',
  deviceid_mandatory: 'Device name is mandatory',
  no_floorplans: 'No floorplans available',
  table_layout_advice: 'Please create a floorplan in the back-office in order to use this feature',
  merge_tables: 'Merge tables',
  custom_item_not_valid: 'Custom item not valid',
  no_connection_sync: 'To sync all transactions, please make sure connection is re-established before exiting the application.',
  new_device_name: 'New device name',
  change_device_name: 'Change device name',
  change_store: 'Change location',
  alert_change_store: 'You will need to completely close and restart the app to apply this change',
  customer_total_spend: 'Customer total spend',
  skip_payment_success_info: 'Do not show payment success info',
  cart_always_open: 'Always show order at the right side of the screen',
  show_calculator: 'Show calculator',
  selected: 'Selected',
  categories_tiles_position: 'Category tiles position',
  kiosk_mode: 'Kiosk mode',
  kiosk_mode_disabled: 'Kiosk mode disabled',
  change_table: 'Change table name',
  change_cart: 'Change order name',
  change_cart_tip: 'It should have at least 2 letters',
  a4_paper: 'Use A4 paper to print',
  auto_print: 'Print receipt automatically after payment success',
  select_report: 'Select report type',
  report_not_found: 'Report not found',
  new_cart: 'New order',
  new_table: 'New table',
  delete_cart: 'Delete order',
  delete_cart_confirmation: 'Are you sure you want to delete this order?',
  product_tile_size: 'Increase product tile size',
  load_more_transactions: 'Load more transactions',
  select_variant: 'Select variant',
  remember_password: 'Remember password',
  go_dashboard: 'Go to my back office',
  enable_cart_item_remove: 'Allow removal of items from order',
  remove_item: 'Remove item',
  show_current_cart: 'Show current order',
  show_carts: 'Show order list',
  show_tables: 'Show floorplan',
  open_receipt: 'Open receipt',
  kds: 'Kitchen display screen',
  archive: 'Archive',
  send_archive: 'Send to archive',
  awaiting_payment: 'awaiting payment',
  all_items: 'All items',
  printed: 'New',
  pending: 'New',
  preparing: 'Preparing',
  ready: 'Ready',
  completed: 'Completed',
  send_new: 'Revert to new',
  send_progress: 'Set as in progress',
  send_ready: 'Set as ready',
  send_completed: 'Set as completed',
  active_orders: 'Active orders',
  all_orders: 'All orders',
  select_employee: 'Select Employee',
  select_status: 'Select Status',
  send_all_to_status: 'Set all products to status',
  no_open_orders: 'No orders available',
  click_refresh: 'Click here to refresh',
  deliveredBy: 'Delivered by',
  by: 'Delivered by',
  deliveryTime: 'Delivery time',
  pickupTime: 'Pickup time',
  customerPhone: 'Customer phone',
  customerName: 'Customer name',
  orderNote: 'Order note',
  serviceCharge: 'Service fee',
  orderType: 'Delivery Type',
  deliveryCost: 'Delivery Cost',
  discountTotal: 'Discount',
  channelOrderDisplayId: 'Order id',
  orderIsAlreadyPaid: 'Is Paid',
  paymentMethod: 'Payment method',
  deliveryFee: 'Delivery Fee',
  deliveryAddress: 'Address',
  deliveryPostcode: 'Postcode',
  deliveryCity: 'City',
  extraAddressInfo: 'Extra Info',
  postalCode: 'Postcode',
  streetNumber: 'Street number',
  postponed: 'Postponed',
  warning_delay: 'Warning delay orders (in minutes)',
  load_orders_past: 'Load orders from past days',
  hotkeys: 'HotKeys',
  show_categories_separator: 'Show category separator on each order\"s item',
  auto_refresh: 'Automatically refresh orders list every X minutes',
  auto_complete_order: 'Automatically complete orders after X minutes',
  hide_completed_items: 'Hide completed items from order',
  remove_grid_gaps: 'Remove gaps between grid\"s orders',
  off: 'Off',
  languages: {"restart_msg":"Please, save your settings and refresh your browser to start using the new translations.","en": "English", "nl": "Nederlands", "de": "Deutsch", "pt": "Português", "es": "Español", "fr": "Français", "is": "Íslenska", "it": "Italiano", "no": "Norsk"},
  hotkeys_obj: {"error":"Cannot repeat keys!","select_order":"Select Orders from 1 to 9","change_status_info":"Change status of selected order with:","change_status":"Change order status"},
  last_update: 'Last update',
  customer_information: {"first_name":"First name","last_name":"Last name","phone":"Phone"},
  in_progress: 'In Progress',
  deliveryBy: 'Delivered by',
  init_all_services: 'Initializing all services...',
  email: 'E-mail',
  signin: 'Sign In',
  signin_error: 'Something went wrong during sign in',
  device_name: 'Device name',
  close_details: 'Close details',
  no_orders_available: 'No orders available',
  order_has_been_completed: 'Order {{receipt_id}} has been completed',
  no_products: 'No Products',
  print_device: 'Print Device',
  send_order: 'Send order',
  add_products: 'Add products',
  no_categories: 'No categories found',
  empty_table: 'Empty table',
  order_not_sent: 'Order not sent to kitchen',
  product_list_view: 'Product List View',
  no_description: 'This product has no description. You can add one pressing the "Change" button or by visiting your dashboard.',
  change_description: 'Please enter product\"s description.',
  main_cart_is_empty: 'Main order is empty',
  apply_to_all_carts: 'Apply to all orders',
  per_value_payment: '(Per value payment)',
  select_a_seat: 'Select a seat',
  sub_total: 'Subtotal',
  desktop_settings: 'Desktop',
  carts: 'Orders',
  signing_in: 'Signing in...',
  total_taxes: 'Total taxes',
  provide_refund_item: 'You must select an item to be refunded',
  manual_cheque_refund: 'Manual cheque refund',
  downloading_employees: 'Downloading employees...',
  show_product_image_list: 'Show products image in Product list',
  refresh_delta: 'Refresh Products/Categories',
  admin_pin_incorrect: 'Admin PIN incorrect! Please, try again.',
  customer_not_valid_vat: 'Customer VAT not valid or empty',
  allow_partial_payment: 'Allow partial payment',
  allow_qrcode_scanner: 'Allow QR code scanner to find transaction',
  qrcode_not_found: 'QR Code not found',
  empty_pin: 'PIN must have at least 1 character',
  employee_select_error: 'Wrong employee PIN',
  employee_select_success: 'Employee {{employee}} selected.',
  employee_lock: 'Always lock employee after transaction',
  no_employee_store: 'There is no employee assigned to this location',
  cash_in_out_failed: 'Failed to save Cash in/out operation',
  cash_in_out_success: 'Cash in/out operation success',
  categories_alphabetic_order: 'Display categories in alphabetic order',
  products_alphabetic_order: 'Display products in alphabetic order',
  weight_not_valid: 'Filled weight not valid',
  kiosk_mode_requirement: 'You cannot set Kiosk mode without Admin PIN',
  canceled: 'Canceled',
  partial_payment_value: 'Per value',
  partial_payment_item: 'Per item',
  continue_payment: 'Continue payment',
  unit: 'Unit',
  cheque: 'Cheque',
  need_store_register_device: 'You first need to create a location to login. Please visit your dashboard to create your first location.',
  search_transaction_hint: 'At least 3 characters and press ENTER',
  select_pay_method: 'Select a payment method',
  delete_all_empty_cart: 'Delete all empty orders',
  delete_all_empty_table: 'Delete all empty tables',
  delete_all_empty_cart_confirmation: 'Are you sure you want to delete all empty orders?',
  all_empty_carts_deleted: 'Deleted ({{cartsNumber}} orders)',
  reps: 'Reps',
  show_reps_waste: 'Show reps and waste button on order',
  giveaway_reason: 'Giveaway or waste reason',
  countr_desktop_not_found: 'Countr Desktop not found',
  clear_search: 'Clear search',
  recovery_transaction_complete: 'Transaction ({{ref}}) completed.',
  activate_simple_tiles: 'Activate simple product list',
  not_print_receipt_cash: 'Don\"t automatic print receipt for cash and giftcard',
  allow_negative_payments: 'Allow negative sale amount',
  negative_payments_warning: 'Please enable negative payments in settings (transactions tab) before doing this.',
  enable_method: 'Enable method',
  error_addon_groups: 'Something is wrong with this product\"s Addon Group, please check it in the Back Office.',
  billed_to: 'Billed to',
  payment_due: 'Payment due',
  amount_due: 'Amount due',
  total_price: 'Total price',
  show_invoice: 'Show invoice',
  hide_invoice: 'Hide invoice',
  show_ean_sku_receipt: 'Show EAN in receipt',
  show_simple_receipt: 'Show simple receipt',
  not_tracked: 'Not tracked',
  kitchen_print: 'Kitchen print',
  kitchen_delivery_print: 'Kitchen Delivery print',
  syncing_transactions: 'Syncing transactions...',
  syncing_refunds: 'Syncing refunds...',
  barcode_customer: 'Use barcode scanner to search customer',
  customer_add_cart: 'Customer added to order',
  transaction_not_sync_yet: 'Transaction was not synced to the server yet.',
  intercom_error: 'Cannot initiate Intercom plugin, please take a look if you have any ad block extension or try to use it in Dashboard.',
  pay_before_open_error: 'You need to open your shift before doing transactions',
  cash_in_not_open_day: 'You need to open your shift before recording Cash in/out',
  cash_in_greater_start: 'Invalid amount, if you have more cash than declared on Start Shift please do a Cash In action first.',
  open_close_shift: 'Open & Close Shift',
  opening_shift: 'Opening shift...',
  closing_shift: 'Closing shift...',
  not_valid_input: 'The amount is not valid',
  previous_start_amount: 'Expected amount',
  reason_mandatory_helper: '* There is a difference, please enter a reason',
  printer_settings: 'Printer settings',
  printer_ip: 'Printer IP',
  test_printers: 'Test printers',
  printers_settings_saved: 'Printers settings saved',
  error_shift_start_day: 'Shift start day not found',
  no_connection_payment: 'No internet connection, please make sure to reestablish the connection before paying with card.',
  show_order_bill: 'Show order/bill buttons in header',
  split_orders_items: 'Split print orders per items',
  split_orders_amount: 'Split print orders per amount',
  partial_payment_print: 'Print receipt for each partial payment',
  minimum_age: 'Minumum age verification',
  minimum_age_alert: 'Customer: {{customer}}. Please ensure customer is over {{age}} years of age',
  customer_required_title: 'Customer required',
  customer_required_text: 'Customer is required for this payment method',
  show_pretax_receipt: 'Show over amount of taxes',
  show_full_receipt_after_payment: 'Show full receipt after payment',
  new_version_title: 'New version available',
  new_version_text: 'New content is available, please refresh.',
  new_version_text_hint: 'You can check the release notes in the left menu.',
  reload: 'Reload',
  release_note: 'Release note',
  play_sound_success_scan: 'Play sound on barcode successfully scanned',
  manual_sync_transactions: 'Manually sync transactions',
  manual_sync_transactions_question: 'Do you want to manually sync those transactions?',
  improvement: 'IMPROVEMENT',
  fixed: 'FIXED',
  recover_carts: 'Recover orders',
  recover_carts_alert: 'Are you sure you want to transfer the order to this device? Once you transfer the order, it will be removed from the other device.',
  recover_carts_complete: 'Recover orders complete. Now you can select it in the order selection popover.',
  select_all_carts: 'Select all orders',
  payment_started: 'Payment started',
  outstanding_inovices: 'You have outstanding invoices - please visit your dashboard to reconcile these',
  quota_warning_title: 'Storage quota is reaching maximum capacity',
  quota_warning_text: 'You are using {{percent}}% of capacity.',
  quota_warning_text_hint: 'Please try to free space on your device.',
  welcome_to: 'Welcome to',
  kassa_open: 'Available cash register',
  kassa_closed: 'Closed cash register',
  kassa_closed_hint: 'Go to another register',
  enable_customer_screen: 'Enable customer screen',
  cds_settings: 'Customer display settings',
  cds_port: 'Customer display port',
  web_pos: 'Web POS',
  desktop_pos: 'Desktop POS',
  pos: 'App POS',
  no_internet_connection: 'You cannot perform this action. No internet connection.',
  storage: 'Storage',
  storage_data: 'Storage used: {{quota}}%',
  extra_info_title: 'Enter extra item information',
  extra_info_sub_title: 'Giftcard or reference number',
  net: 'Net',
  delivery_details: 'Delivery details',
  delivery_time: 'Delivery time',
  pickup_time: 'Pickup time',
  order_type: 'Order type',
  order_courier: 'Delivered by',
  delivery_order_warning: 'This is a delivery order from {{source}}, cannot add new product to it.',
  delivery_toast: 'Delivery order received: {{by}} - {{receipt_id}}',
  delivery_requested: 'Delivery requested: {{message}}',
  tables_or_carts_desc: 'Show the order names as tables (e.g Order 1 becomes Table 1).',
  cart_always_open_desc: 'Keep the order (items, total, taxes, ...) open at the right side of screen.',
  show_product_image_desc: 'Show product\"s image inside the order items list. ',
  allow_cart_discounts_desc: 'Allow discounts on the entire order.',
  allow_product_discounts_desc: 'Allow discounts on a single product.',
  enable_cart_item_remove_desc: 'Enable button to remove item from order in the order\"s items list.',
  show_calculator_desc: 'Show numpad to select amount of the products.',
  show_reps_waste_desc: 'Show reps and waste button in the order\"s header.',
  barcode_customer_desc: 'Allow barcode scanner to scan customer identifier and add the customer to the order.',
  show_stock_level_desc: 'Show product\"s stock units in the product tile.',
  show_price_desc: 'Show product\"s price in the product tile.',
  barcode_scanner_desc: 'Enable barcode scanner to scan a product and add it to the order.',
  play_sound_success_scan_desc: 'Play sound when successfully scan a product and add it to the order.',
  show_product_image_list_desc: 'Show product\"s image in products list.',
  activate_simple_tiles_desc: 'Remove product tiles animations and effects (recommended for old computers).',
  product_tile_size_desc: 'Double product\"s tile size',
  hide_categories_desc: 'Hide categories list and add a modal to select it.',
  categories_alphabetic_order_desc: 'Show categories list in alphabetic order.',
  products_alphabetic_order_desc: 'Show products list in alphabetic order.',
  skip_payment_success_info_desc: 'Don\"t show payment success dialog (transaction resume) after a successful payment.',
  allow_partial_payment_desc: 'Allow partial payment (customer can pay the total with different payments methods).',
  allow_negative_payments_desc: 'Allow payments with negative value.',
  allow_qrcode_scanner_desc: 'Show qrcode reader in the order\"s header.',
  show_pretax_receipt_desc: 'Show pre tax amount in the receipt.',
  show_full_receipt_after_payment_desc: 'Show full receipt in the tranasction summary after successful payment.',
  refund_same_method_desc: 'Ensure refunds can only be made using the same method as the original transaction.',
  enable_customer_screen_desc: 'Enable customer screen details (need to have customer display hardware).',
  kiosk_mode_desc: 'Enable Kiosk mode.',
  admin_pin_desc: 'Enable Admin PIN (to lock/unlock kiosk mode).',
  employee_pin_desc: 'Enable employee selection.',
  employee_lock_desc: 'If Employee PIN is enabled, lock the POS after each transaction and force employee to re-enter pin.',
  open_close_shift_desc: 'Enable Open/Close shifts.',
  reports_at_store_level_desc: 'Show reports at location level (counting all transactions of all devices).',
  language_desc: 'Change POS language.',
  kitchen_print_desc: 'Print order after transaction success.',
  kitchen_delivery_print_desc: 'Print order after receive a transaction success via delivery system.',
  show_order_bill_desc: 'Show print order and bill in the order\"s header.',
  split_orders_items_desc: 'Split print order into items.',
  split_orders_amount_desc: 'Split print order into amount.',
  partial_payment_print_desc: 'Print partial receipt after each partial payment.',
  show_ean_sku_receipt_desc: 'Show EAN/SKU in the receipt.',
  a4_paper_desc: 'Enable A4 print.',
  show_carts_list_alphabetic: 'Show orders list in alphabetical order',
  show_carts_list_alphabetic_desc: 'Show orders list in alphabetical order',
  show_cart_name_transaction: 'Show order name in receipt',
  show_cart_name_transaction_desc: 'Show the order name in the receipt (transaction list or transaction complete).',
  open_kds: 'KDS',
  open_chat: 'Countr Chat',
  partial_payment_bigger_zero: 'Partial payment value must be bigger than 0',
  allow_product_exchanges: 'Allow product exchanges',
  allow_product_exchanges_desc: 'Add products that customer wants to exchange as a negative amount in the order',
  exchangeable_items: 'Exchangeable items',
  to_be_exchangeable: 'To be exchanged',
  exchange_source: 'Exchange source',
  inventory_not_found: 'Product\"s inventory not found',
  drop_ship: 'Drop-Ship',
  enable_drop_ship: 'Enable drop-shipping',
  enable_drop_ship_desc: 'Enable drop-shipping feature.',
  drop_ship_warning: 'Drop-Ship - No customer selected',
  cart_as_tables_not_available: 'Orders as tables feature has no effect with Floorplans enabled.',
  enable_deposit_button: 'Enable deposit button',
  enable_deposit_button_desc: 'Enable deposit button in order\"s subheader',
  allow_voucher_scan_desc: 'Use barcode scanner to search for discount voucher and apply it.',
  remove_customer: 'Remove customer',
  remove_customer_question: 'Do you want to remove customer from this order?',
  search_product_barcode_server: 'Search products in the server',
  search_product_barcode_server_desc: 'Search products in the server if it is not found locally.',
  show_floorplan_after_sale_desc: 'Show floorplan layout after each sale instead of showing product list',
  ccv_settings: 'CCV Settings',
  ask_no_covers_or_takeaway_desc: 'Always ask table configuration',
  send_order_kds: 'Sending order to KDS',
  mollie: 'Mollie',
  paynl: 'PayNL',
  transactionFee: 'Transaction Fee',
  pay_later_note_partial: '* It is only possible to have 1 pay later payment per transaction.',
  no_print_delivery: 'No delivery info on this order.',
  load_linked_cart: 'Please, click on the table to load linked info.',
  show_item_status: 'Show item status at order entry',
  show_item_status_desc: 'Show a highlight border at the left of order entry with the current status of this item.',
  access_denied: 'Access denied! Please try to login with Master account.',
  current_floor: 'Current floor',
  total_to_pay: 'Total to pay: {{currency}} {{amount}}',
  total_amount_paid: 'Total paid: {{currency}} {{amount}}',
  total_remaining: 'Total remaining: {{currency}} {{amount}}',
  redeem: 'Redeem',
  current_balance: 'Current balance',
  card_activity: 'Card activity',
  scan_fail: 'Scanned code search fail',
  disallow_offline: 'POS cannot operate offline',
  disallow_offline_fiscal: 'POS cannot operate offline because of fiscal law.',
  fiscal_print_error: 'Cannot print receipt without fiscal info',
  enable_giftcard_scan: 'Enable gift card scan',
  enable_giftcard_scan_desc: 'Enable feature to scan gift card and reduce its balance.',
  print_qr: 'Print QR code in the receipt',
  print_qr_desc: 'Print QR code at the bottom of the receipt.',
  theme: 'Theme',
  theme_desc: 'Switch the default theme to one that you like (theme list is provide by location).',
  invalid_addon_groups: 'Something is wrong with product\"s addon groups. Please go to your dashboard and check it before use it.',
  products_not_found: 'Products not found',
  show_suggestion_amounts: 'Show suggestion amount buttons',
  show_suggestion_amounts_desc: 'Show/hide suggestion amount buttons in payment modal.',
  save_table: 'Save',
  save_cart: 'Save',
  enable_groups: 'Group order items',
  highlight_items: 'Highlight items',
  set_ready_print: 'Set as Ready and Print',
  start_preparing: 'Start preparing',
  group_qty: 'Group quantity',
  show_more: 'Show more information',
  notes_per_qty: 'Notes per quantity',
  print_online_order_receipts: 'Automatically print receipts for paid online orders',
  select_items: 'Select items',
  partial_payment_not_allowed_by_value: 'Partial payments are not allowed',
  product_media: 'Media',
  show_product_media: 'Show product media',
  change_seat: 'Change Seat',
  add_seat: 'Add Seat',
  continue_partial_payment: 'Do another partial payment?',
  seat: 'Seat',
  partial_payment_seat: 'Per seat',
  processing: 'Processing',
  groupOrderPrintBySeat: 'Group print of orders by seat',
  fiscal_error: 'Fiscal Module Error',
  switch_employee: 'Switch employee',
  no_order_removed: 'Not ordered products removed',
  connected_device: 'Connected device',
  deleted: 'Deleted',
  ready_pickup: 'ready to pickup',
  employee_pin_changed: 'Employee PIN changed successfully',
  order_kds_no_printer_msg: 'Order sent to KDS but not to printer, set printer device to send the order',
  order_kds_printer_msg: 'Order sent to KDS and to printer',
  add_table: 'added to table',
  product_add_cart: 'Product added to order',
  product_ordered: 'Product ordered',
  product_not_ordered: 'Product not ordered',
  save_guests: 'Save guests',
  guests_num: 'Number of guests',
  add_guest_num: 'Add number of guests',
  no_stores_created: 'No locations created',
  device_print_msg: 'The selected device will be responsible to print the receipt',
  prep_time: 'Preparation Time',
  no_tables_store: 'No Tables for this location',
  go_back: 'Go back!',
  remove_not_order_msg: 'Are you sure you want to remove the \"Not ordered\" items of',
  order_not_sent_back: 'Order have not been sent to KDS! Are you sure you want to go back?',
  no_available_addons_for_products: 'No available addons for the selected product.',
  no_logged_employee: 'No employee is logged in!',
  empty_tables: 'Empty Tables',
  close_search: 'Close Search Bar',
  no_empty_tables: 'There are no empty tables!',
  my_tables: 'My Tables',
  current_device: 'Curent Device',
  employee_info: 'Employee\"s Information',
  show_cart: 'Show Order',
  show_seat_separator: 'Show seat separator',
  set_print_device: 'Set print device',
  table_search: 'Search Tables',
  order_by_seat: 'Order by seat',
  table_list_view: 'Show tables as a list',
  customer_groups: 'Customer Groups',
  sales_insights: 'Sales insights',
  product_insights: 'Product insights',
  store_insights: 'Location insights',
  allow_ticket_scan: 'Scan tickets to open order',
  no_pin_employee: 'Employee without PIN',
  allow_ticket_scan_desc: 'Use the barcode scanner to search for tickets.',
  admin_only_custom_item: 'Enable custom item only for admin',
  admin_only_custom_item_desc: 'Only users with admin rights can create a custom item',
  mandatory_employee_pin: 'Mandatory activation of employee PIN.',
  admin_only_refunds: 'Only admins can perform refunds',
  admin_only_refunds_desc: 'Only admins can perform refunds.You must activate employee PIN also.',
  round_cash_transactions_desc: 'Automatically round cash payments to nearest 0.05',
  unlimited_usage: 'Unlimited usage',
  infinite: 'Infinite',
  disable_custom_discounts: 'Disable custom discounts',
  disable_custom_discounts_desc: 'Disable custom discounts but allow pre-defined ones.',
  guest: 'Guest',
  select_method: 'Select method',
  manual_insert: 'Manual insert',
  enter_code: 'Enter code ',
  existing_by_value_payment: '* You can\"t mix pay by value and pay by seat and / or item',
  order_sent: 'Order Sent',
  select_multiple_seats: 'Select multiple seats',
  change_seat_or_guest_num: 'Change seat or guest number',
  change_guest_num: 'Change guest number',
  giftcard_low_value: '* Giftcard balance is lower than selected item(s) value. Try paying by value instead.',
  order_by_seat_desc: 'Enable ordering by seat feature.',
  legacy_images: 'Print images using deprecated format',
  received_amount: 'Received amount',
  order_products_action: 'Order products',
  ordered_products: 'Ordered products',
  select_table: 'Select table',
  ordered: 'Ordered',
  scan_or_type: 'Scan or type',
  type_code: 'Type code',
  number_extension: 'Number extension',
  move_processing_ticket_first: 'Move processing tickets first',
  disable_adblocker: 'Please disable your AdBlocker and refresh the page',
  remove_partial_payment_by_item: 'Partial payment by item detected! Please remove it in order to proceed with this action !',
  add_nutrients: 'Add nutrients',
  giftcard_warning_msg: 'Standard validity of a giftcard is 2 years. Are you sure you want to decrease this?',
  edit_devicess_main_details: 'Edit devices\"s main details.',
  create_custom_payment_methods_for_your_device: 'Create custom payment methods for your device.',
  show_open_carts_from_device: 'Show open orders from device',
  create_discounts_for_your_stores: 'Create discounts for your location.',
  create_reps_waste_for_your_stores: 'Create reps and waste for your location.',
  show_cat_bar: 'Show category bar',
  bulk_update: 'Bulk update items',
  show_category_product_info: 'Show category info in product',
  cart_will_empty: 'The cart will automatically empty',
  seconds: 'seconds',
  corrupted_addons: 'Product has corrupted addons. Please check in the Backoffice.',
  add_more_items: 'Add more items',
  paying_with: 'Paying with {{method}}',
  screen_saver_delay: 'Screensaver delay (seconds)',
  screen_saver: 'Screensaver',
  enable_screen_saver: 'Enable screensaver',
  no_screen_saver: 'Can\"t detect screensaver',
  screen_saver_info: 'Screensaver info',
  type: 'Type',
  running_payment: 'In Progress',
  print_brand: 'Print brand on receipt',
  show_product_options_long_press: 'Long-press to display product options',
  asap: 'ASAP',
  order_source: 'Order Source',
  ready_items: 'Ready items',
  disabled: 'Disabled',
  ready_orders: 'Ready orders',
  admin_only: 'Only available for administrators',
  ask_buzzer_info: 'Buzzer information required',
  ask_buzzer_info_desc: 'Require entry of coaster / buzzer information for each order - e.g. the buzzer number or ID',
  buzzer: 'Buzzer',
  print_tickets: 'Print ticket barcodes on receipt',
  print_tickets_desc: 'Print barcodes for any tickets found in the receipt on the receipt itself',
  show_employee_on_product: 'Show which employee added each item',
  surcharge: 'Surcharge',
  changes_list: 'Changes list',
  action_type: 'Action type',
  previous_change: 'Previous change',
  new_changes: 'New changes',
  removed_fields: 'Removed fields',
  updated_fields: 'Updated fields',
  full_history: 'Full history',
  changes_history: 'Changes history',
  product_history: 'Product history',
  category_history: 'Category history',
  device_history: 'Device history',
  enable_mandatory_customer_address: 'Mandatory customer\"s address ',
  please_fill_all_fields: 'Please fill all required fields: building number, street, city, state/province, zip or postal code, country, first and last name, email address or phone number',
  show_product_price_cat: 'Show product price and category',
  open_cash_drawer_giftcard: 'Automatically Open Drawer for Countr Giftcard',
  generate_barcode: 'Generate barcode',
  select_file: 'Select file',
  drop_file: 'Drop file',
  create_media: 'Create media for',
  upload_image: 'Upload image',
  upload_file: 'Upload file',
  add_custom_img: 'Add your custom image',
  select_media: 'Select media type',
  external_url_iframe: 'External URL Iframe',
  select_ticket_print: 'Select type to print',
  external_service_provider: 'External service provider',
  barcode_input: 'Barcode input',
  stock_load_msg: 'Check all products for stock. This may take some time. Please wait!',
  no_available_stores: 'No stores available.',
  buy_combination_get_deal_new_price: 'Combine items for a contract price',
  discount_specific_products_categories: 'Discount on specific products or categories',
  discount_all_prodcucts: 'Discount on all products',
  buy_x_items_get_cheapest_discount: 'Buy x items, get the cheapest at a discount',
  spend_certain_amount_get_discount: 'Spend a certain amount, get a discount',
  customer_group_item_discount: 'Discount on specific customer groups',
  item_surcharge: 'Surcharge on specific products, categories or customer groups',
  cart_surcharge: 'Surcharge on the entire order',
  import: 'Import',
  export: 'Export',
  simple: 'Simple',
  advanced: 'Advanced',
  delete_import: 'Delete products import',
  upload_csv: 'Upload your CSV file',
  save_file: 'Save the file as .csv',
  value_remaining: 'Value remaining:',
  qr_codes_for_line_items: 'Print QR code instead of barcode for line items',
  barcode_prefix: 'Barcode Prefix',
  barcode_digits: 'Barcode length / digits',
  external_identifier: 'External Identifier',
  cost_centre: 'Cost Centre',
  shared_shift: 'Share shift',
  shared_shift_desc: 'Changing employee will not require to re open the register',
  empty_cart_voucher_warning: 'Add Products to use the Voucher!',
  ask_for_help: 'Ask for help',
  help_is_coming: 'Please wait for assistance',
  you_paid: 'You have paid.',
  end_msg: 'Thank you and goodbye.',
  scan_products: 'Scan your product to start',
  welcome_valk: 'Welcome to Valk',
  solution_checkout: 'Solutions Checkout!',
  basket_check_complete: 'Basket Check Status',
  start_scanning: 'Please scan your product',
  close_auto: 'Close automatically in',
  open_close_employee: 'Open & Close shift by employee',
  print_table_name: 'Print cart/table name on receipt',
  print_note: 'Print note on receipt',
  reset_state: 'Reset Application State',
  sample_check_msg_top: 'One moment please.',
  sample_check_msg_bottom: 'An employee is coming as soon as possible',
  disallow_close_register_open_tables: 'Don\"t close POS if orders still open',
  opening_amount: 'Opening amount',
  cash_payments: 'Cash payments',
  msg_nix: 'Nix18 product added, an employee will come as soon as possible',
  receipt_type: 'Please choose type of receipt',
  full_receipt: 'Full receipt',
  short_receipt: 'Short receipt',
  start: 'START',
  go_to_cart: 'Go to cart',
  full_basket_check: 'Full basket check',
  missing_items_amount: 'Missing items amount',
  extra_items_scanned: 'Extra items amount',
  finish_check: 'Finish check',
  original_items: 'Original items',
  checked_items: 'Checked items',
  no_receipt: 'No receipt',
  refresh_categories: 'Refresh categories',
  pay_img_text_up: 'Pay with pin, <br /> follow instructions on pin device on the left',
  pay_img_text_down: 'After payment the receipt will follow',
  old: 'Old',
  new: 'New',
  calculating: 'Calculating',
  merging: 'Merging in progress',
  select_table_from_list: 'Select a table from the list to merge all other selected tables',
  cant_merge_same: 'Can not merge the same table, please select another table to merge',
  remove_from_table: 'remove from table',
  login_then_reboot: 'Please log in first and then restart the application',
  eat_in: 'Eat in',
  preparing_ods: 'Preparing',
  ready_ods: 'Ready',
  cancel_order_pin: 'Cancel order pin',
  sms_order_ready: 'You will receive an SMS when your order is ready',
  cancel_order: 'Cancel order'
}

export default enLang
