import { SET_STORE_DEVICE, SET_DEVICE_UUID } from './actionTypes'

export const setStoreDevice = device => ({
  type: SET_STORE_DEVICE,
  payload: device
})

export const setDeviceUUID = uuid => ({
  type: SET_DEVICE_UUID,
  payload: uuid
})
