import { SET_STORE_DEVICE, SET_DEVICE_UUID } from '../actions/actionTypes'

const initialState = {
  store: {},
  storeId: '',
  device: {},
  deviceUUID: ''
}

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORE_DEVICE:
      return {
        ...state,
        store: action.payload.store,
        storeId: action.payload.store._id,
        device: action.payload,
        deviceUUID: action.payload.uuid
      }
    case SET_DEVICE_UUID:
      return {
        ...state,
        deviceUUID: action.payload
      }
    default:
      return state
  }
}

export default companyReducer
