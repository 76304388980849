// Merchant actions
export const SET_MERCHANT = 'SET_MERCHANT'

// Device actions
export const SET_STORE_DEVICE = 'SET_STORE_DEVICE'
export const SET_DEVICE_UUID = 'SET_DEVICE_UUID'

// Orders actions

export const SET_ORDERS = 'SET_ORDERS'
export const ADD_ORDER = 'ADD_ORDER'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const REPLACE_ORDER = 'REPLACE_ORDER'
